import evest from './evest/de';
import en from '@/locale/localeEn';
import { GM_CONTACT_EMAIL } from '@/global/constants';

export default {
  ...en,
  ...evest,

  label_hi: 'Guten Tag',
  label_generalMeeting1: 'Hauptversammlung',
  label_generalMeetingName: 'General meeting name',
  label_issuer: 'Emittent',
  label_identificationNumber: 'Identifikationsnummer',
  label_issuer_name: 'Issuer name',
  label_type: 'Art',
  label_legal_entity: 'Juristische Person ',
  label_natural_person: 'Natürliche Person',
  label_genMeetingDate: 'Datum der Hauptversammlung',
  label_meetingDate: 'Datum der Versammlung',
  label_status: 'Status',
  label_action: 'Action',
  label_advancedSearch: 'Advanced search',
  label_cet: ' CET',
  label_securityClass: 'Art der Wertpapiere',
  label_createGenMeeting: 'Create general meeting',
  label_editGenMeeting: 'Edit general meeting',
  label_requestSubmittedAt: 'Request submitted on',
  label_requestSubmittedBy: 'Request Submitted by',
  label_submittedBy: 'Submitted by',
  label_extendedIntermediaryDeadline: 'Intermediary deadline',
  label_extendedIntermediary: 'Extended Intermediary deadline',
  label_viewExtnReq: 'View extension requests',
  votecollector_list_tooltip:
    'Please click on group name(s) to enter the vote collector display names.',
  label_vc_alias_name_error:
    'Please ensure that display name in English language is entered',
  label_vc_group_success: 'Display name saved successfully for given group',
  label_vote_collector_group: 'Vote collector group',
  label_cancelExtnReq: 'Extension Request has been cancelled successfully',
  label_notAllowedExtnReq: 'Issuer does not allow (new) extension requests',
  label_new_end_date: 'New end date/time',
  label_downloadRegFormTemplate: 'Download registration form template',
  label_extReq: 'Extension request',
  label_approveExtnReq: 'Extension Request has been approved successfully',
  label_forAgainstVotingInstructions: 'For/Against Voting instruction',
  label_abstainedVotingInstructions: 'Abstained Voting instruction',
  label_rejectExtensionReq: 'Extension Request has been rejected successfully',
  label_rejectAllExtnRequests:
    'Extension Requests have been rejected successfully',
  label_approveExtReqError:
    'Please enter a valid date. Maximum 5 hours of extension time is allowed and Extension Date cannot be greater than General Meeting Date',
  label_userMaintenance: 'User maintenance',
  label_auditAndTemplate: 'Audit and template',
  label_liveMeetingOverview: 'Live meetings overview',
  label_shareHolderEmail: 'Shareholder email',
  label_dwtEvents: 'DWT Events',
  label_entity: 'Entity',
  label_publicationDate: 'Publication date',
  label_registrationDeadline: 'Registration deadline',
  label_closingDate: 'Deadline',
  label_intermediaryDeadline: 'Intermediary deadline',
  label_options: 'Optionen',
  label_typeAndLocation: 'Type and location',
  label_schedule: 'Zeitplan',
  label_shareClasses: 'Arten von Aktien',
  label_agenda: 'Tagesordnungspunkte',
  label_agenda_number: 'Agenda number',
  label_features: 'Features',
  label_documents: 'Unterlagen',
  label_inviteSharePlan: 'Invite share plan',
  label_select: '--Wählen Sie--',
  label_meetingType: 'Meeting type',
  label_meetingName: ' Titel der Versammlung',
  label_name: 'Name',
  label_corporateWebsite: 'Website des Unternehmens',
  label_website: 'Website',
  label_location: 'Veranstaltungsort',
  label_locationName: 'Ortsbezeichnung',
  label_locationOnTicket: 'Location on ticket',
  label_street: 'Anschrift',
  label_postalCode: 'Postleitzahl',
  label_country: 'Land',
  label_proxiesList: 'Clearing custodian',
  label_selectClearingIntermediary: '--Select clearing intermediary--',
  label_timeZone: 'Time zone',
  label_ibanNumber: 'IBAN number',
  label_directDebit: 'Direct debit',
  label_convocationDate: 'Einberufungsdatum',
  label_shareholderAccessDate: 'Datum des Zugangs der Aktionäre',
  label_intermediaryAccessDate: 'Datum des Zwischenzugriffs',
  label_recordDate: 'Datum der Anmeldung',
  label_shareholderDeadlineDate: 'Anmeldefrist',
  label_intermediaryDeadlineDate: 'Datum der Zwischenfrist',
  label_regListReleaseDate: 'Veröffentlichungsdatum der Registrierungsliste',
  label_noOfSecurities: 'Anzahl der Aktien',
  label_generalMeetingDate: 'Datum der Versammlung',
  label_error_hoursAndMinutes:
    'Please enter hours from 0 to 23 and minutes from 0 to 59',
  label_prefixNumber: 'Prefix number',
  label_isin: ' ISIN',
  label_shareClassName: 'Share class name',
  label_intermediary: 'Intermediär',
  label_shareholder: 'Shareholder',
  label_shareplanholder: 'Share plan holder',
  label_search: 'Search',
  label_additionalShareClass: 'Additional share class',
  label_additionalDocs: 'Additional documents',
  label_attachDocument: 'Attach document',
  label_browse: 'Weiter',
  label_votable: 'Votable',
  label_agendaItem: 'Agenda item',
  label_agent: 'Agent ',
  label_addNewVoteCollector: 'Add new vote collector',
  label_addVoteCollector: 'Add vote collector',
  label_noDataFound: 'No data Found',
  label_login: 'Anmeldung',
  label_loginHeader: 'anmelden',
  label_forgotPwrd: 'Passwort zurücksetzen',
  label_gmNotice: 'GM notice',
  label_optOutGmNotice:
    'Ich möchte keine Informationen über Hauptversammlungen erhalten ',
  label_webaPage: 'Einsehbar auf relevanten Websites',
  label_attendanceCard: 'Attendance card',
  label_previous: ' Previous',
  label_next: 'Weiter',
  label_prev: 'Zurück',
  label_first: 'Erste',
  label_last: 'Last',
  label_nl: 'DE',
  label_en: 'EN',
  label_fr: 'FR',
  label_pipe: ' | ',
  label_colonStar: ':*',
  label_colon: ':',
  label_hash: '#',
  label_comma: ',',
  label_questionMark: '?',
  label_noDataMessage: 'No data available',
  label_noDataMessageExt: 'There are no extension requests available',
  label_agents: 'Agents',
  label_issuers: 'Issuers',
  label_approve: 'Approve',
  label_reject: 'Reject',
  label_rejectAll: 'Reject all',
  label_accept: 'Accept',
  label_confirmationMessage: 'Bestätigungsmeldung',
  label_areYouSure: 'Are you sure you want to approve?',
  label_areYouSureSend: 'Are you sure you want to send?',
  label_areYouSureCancel: 'Are you sure you want to cancel?',
  label_areYouSureSave: 'Are you sure you want to save?',
  label_yes: 'Ja',
  label_no: 'Nein',
  label_save: 'Speichern',
  label_view: 'View',
  label_viewUsers: 'View users',
  label_edit: 'ändern',
  label_notification: 'Notification',
  label_notification_link: 'GM Notification Sheet',
  label_inviteShareplan: 'Invite share plan',
  label_add: 'Add ',
  label_city: 'Ort',
  label_logo: 'Logo',
  label_addNew: 'Add new',
  label_admin: 'Admin',
  label_phoneNumber: 'Telefonnummer',
  label_personalPhoneNumber: 'Personal phone number',
  label_ok: 'OK',
  label_groupMail: 'Opt-out for emails related to General Meetings',
  label_optOutTaxReclaim: 'Opt-out for emails related to Tax reclaim events',
  label_cancel: 'Abbrechen',
  label_confirm: 'Bestätigen',
  label_file: 'File',
  label_title: 'Anrede',
  label_firstName: 'Vorname',
  label_lastName: 'Nachname',
  label_meetingTypeAGM: 'AGM',
  label_meetingTypeEGM: 'EGM',
  label_goBack: 'zurück',
  label_home: 'Home',
  label_generalMeeting: 'Allgemeine Hauptversammlungen',
  label_DWT: 'DWT',
  label_shareholderID: 'Shareholder ID',
  label_corporateBroking: 'Corporate Broking',
  label_btnPending: 'In Bearbeitung',
  label_btnAccepted: 'Angenommen',
  label_btnApproved: 'Genehmigt',
  label_btnRejected: 'Abgelehnt',
  label_btnCancelled: 'Abgesagt',
  label_requestCreatedAt: 'Anträge eingereicht am',
  label_nameBeneficialOwner: 'Name beneficial owner',
  label_shareholderPosition: 'Shareholder position',
  label_option: 'Option',
  label_delete: 'Delete',
  label_generalDetails: 'Allgemeine Informationen ',
  label_registrations: 'Registrations',
  label_voteCollector: 'Einrichtung, die Weisungen zur Stimmabgabe sammelt',
  label_locationOnCertificate:
    'Name des Veranstaltungsortes auf der Anmeldebescheinigung',
  label_votingResult: 'Voting result',
  label_votingAvailable: 'Voting available',
  label_votingNotAvailable: 'Voting not available',
  label_votingItem: 'Abstimmungspunkt',
  label_defaultVotingOptions: '(dafür, dagegen, Enthaltung)',
  label_intermediaryWorkstation: 'Intermediary workstation',
  label_shareholderWorkstation: 'Shareholder workstation',
  label_SplitVotingRequired: 'Split voting required',
  label_disclosureOfUnderlyingBeneficialOwnersRequired:
    'Disclosure of underlying beneficial owners required',
  label_fillText:
    '* Fill out all fields in this page and click "Save" button to navigate to next section.',
  label_generalMeetingShouldBeVisible: 'General meeting should be visible',
  label_enableNewVotingResolution: 'Enable new voting resolution',
  label_agentLogoOnRegistrationTicket: 'Agent logo on registration ticket',
  label_issuerLogoOnRegistrationTicket: 'Issuer logo on registration ticket',
  label_emailAddress: 'E-Mail-Adresse',
  genDetails_label2: 'Disclosure of underlying beneficial owners required',
  label_shareClass: 'Art der Wertpapiere',
  label_companyName: 'Name des Unternehmens',
  label_company: 'Unternehmen',
  label_voteConfirmation: 'Bestätigung der Weisung zur Stimmabgabe',
  label_votingInstructions: 'Weisungen zur Stimmabgabe',
  label_viewVotingInstructions: 'Weisung zur Stimmabgabe anzeigen',
  label_viewSharePlanHolder: 'View Share plan holder',
  label_viewattendanceRequests: 'View attendance requests',
  label_voteRightsPerShare: 'Gesamtzahl der Dokumente',
  label_entitledVoteRightsShares: 'Dokumente mit Stimmrecht',
  label_shareplan: 'Share plan',
  label_votecollector: 'Einrichtung, die Weisungen zur Stimmabgabe sammelt',
  label_securities: 'securities',
  label_resend_selected: 'Resend Selected',
  label_instruction: 'instruction',
  label_attendanceRegistration: 'Attendance registration',
  label_votingInstruction: 'Voting instruction',
  label_oldPwrd: 'Aktuelles Passwort',
  label_newPwrd: 'Neues Passwort',
  label_reenterPwrd: 'Bestätigen Sie das neue Passwort',
  searchSharePlanHolder_label: 'Securities holder name/Email',
  label_resend: 'Resend',
  label_resendInviteSharePlan: 'Resend invite share plan',
  label_no_sharePlan_holders_available: 'No Share plan holders available',
  label_sharePlan_reinvite:
    'Invite share plan email will be sent to the selected share plan holders',
  label_changePwrd: 'Passwort ändern',
  label_submit: 'Submit',
  label_pwrdRequirements: 'Passwort-Anforderungen',
  label_total: 'Total',
  label_download: 'Download',
  label_registrationListPDF: 'Registration list PDF',
  label_registrationListXLS: 'Registration list XLS',
  label_shareholdersAttendanceRegistration:
    'Shareholders requested for attendance registration',
  label_shareholdersVotingInstructions:
    'Shareholders requested for voting instructions',
  label_reinviteShareplan: 'View/Reinvite share plan holder',
  'label_%ofOutstandingVotingRights': '% of outstanding voting rights',
  label_attendanceRequest: 'Anmelden',
  label_noAction: 'No action',
  label_registrationTicketPreview: 'Registration ticket preview',
  label_downloadRegCertificate: 'Download all registration certificates',
  label_registrationCertificates: 'Registration certificates',
  label_createNewRegistration: 'Create new registration',
  label_downloadAll: 'Download all',
  label_uppercaseLetters: 'mindestens 1 Großbuchstabe',
  label_lowercaseLetters: 'mindestens 1 Kleinbuchstabe',
  label_numbers: 'mindestens 1 Zahl',
  label_specialCharacters:
    "mindestens 1 Sonderzeichen ($ + , : ; = ? @ # | ' . ^ * ) ( % ! - ~ } { ] [ _ / )",
  label_8ormoreCharacters: 'mindestens 8 bis maximal 20 Zeichen',
  label_email: 'E-Mail-Adresse',
  label_addOtherLang: 'Add other language',
  label_important: 'Important',
  label_registrationTicketCreated: 'Registration ticket created',
  label_emailAuditTrail: 'Email Audit Trail',
  label_auditTrail: 'Audit Trail',
  label_templates: 'Templates',
  label_uploadTemplates: 'Upload templates',
  label_sentOutMails: 'Sent out mails',
  label_reset: 'Freigabe',
  label_securitiesHolder: 'Securities holder',
  label_registrationNumber: 'Anmeldungsnummer',
  label_proxyName: 'Name des Bevollmächtigten',
  label_noOfShares: 'Anzahl der Aktien',
  label_fromDate: 'From date',
  label_toDate: 'To date',
  label_intermediaries: 'Intermediaries',
  label_emailType: 'Email type',
  label_receiverEmailAddress: 'Receiver email address',
  label_subject: 'Subject',
  label_emailDetails: 'Email details',
  label_sentTo: 'Sent to',
  label_userName: 'User name',
  label_from: 'From',
  label_to: 'To',
  label_auditTrailDetails: 'Audit Trail Details',
  label_user: 'User',
  label_platform: 'Platform',
  label_Date: 'Date',
  label_templateType: 'Template type',
  label_subbjectOrDescription: 'Subject/Description',
  label_descriptionDetails: 'Description details',
  label_template: 'Template',
  label_language: 'Language',
  label_modified: 'Modified',
  label_sender: 'Sender',
  label_documentType: 'Document type',
  label_dwtBulkUpload: 'DWT bulk upload',
  label_bulkUpload: 'Bulk upload',
  label_bulkUploadVI: 'Bulk upload voting instructions',
  label_users: 'Users',
  label_role: 'Role',
  label_event: 'Event',
  label_eventName: 'Event name',
  label_dwtEvent: 'DWT envent',
  label_votingInvitationWithLinkToMeetings:
    'Voting invitation with link to meetings',
  label_sentDate: 'Sent date',
  label_newIntermediary: 'New Intermediary',
  label_intermediaryName: 'Name des Vermittlers',
  label_intermediaryPhone: 'Telefonnummer des Vermittlers',
  label_intermediaryEmail: 'Intermediary email address',
  label_intermediaryContactName: 'Kontaktperson des Vermittlers',
  label_intermediaryGroupName: 'Intermediary group name',
  label_bic: 'BIC',
  label_registrationForm: 'Anmeldeformular',
  label_processVotingInstructions: 'Process voting instructions',
  label_receiveMeetingNotification: 'Receive meeting notification via Swift',
  label_processRegRequest: 'Process registration request',
  label_visibleInShareholderWorkstation: 'Visible in Shareholder workstation',
  label_CanProcessOnlyAssignedShareclasses:
    'Can process only assigned share classes',
  label_processCustomTemplate:
    'Process custom template for RC email to shareholder',
  label_addIssuer: 'Add Issuer',
  label_permissionsForms: 'Permissions / Registration forms',
  label_validFrom: 'Valid from',
  label_validTo: 'Valid to',
  label_openBracket: '(',
  label_closeBracket: ')',
  label_shareholderType: 'Shareholder type',
  label_selectShareholderType: '--Select Shareholder type--',
  label_initials: 'Initialen',
  label_isAdmin: 'Is Admin',
  label_searchResults: 'Search results for',
  label_resetInstructionsSent: 'Reset instruction sent',
  label_resetPwrd: 'Reset password',
  label_checkEmail: 'Check your email',
  label_canProceedVotingInstructions: 'Can process voting instructons',
  label_addIntermediary: 'Vermittler hinzufügen',
  label_addNewProxy: 'Add new proxy',
  label_thankYou: 'Danke!',
  forgotPassSuccess_label1:
    'Die Anforderung zum Zurücksetzen des Passworts wurde ausgeführt. Wenn der angegebene Benutzername existiert, wird eine E-Mail mit Anweisungen gesendet.',
  label_entityName: 'Entity name',
  label_actionType: 'Action type',
  label_sentDateGMT: 'Sent date and time',
  label_screenName: 'Screen name',
  label_templateName: 'Template name',
  label_workstation: 'Workstation',
  label_templateText: 'Template text',
  label_doNotPublish: 'Do not publish',
  genMeetRegistration_label1: 'Total number of outstanding voting rights',
  genDetails_label1:
    'Include meeting documents along with registration certificate',
  agent_home_welcomeText1: `Welcome to ABN AMRO's Corporate Broking Portal, where you can facilitate your general meetings and DWT processes.`,
  agent_home_welcomeText2: 'Please find below your ',
  label_actionList: 'action list.',
  createGenMeet_fillFormText: 'Fill form in',
  label_euroclear_shares: 'Euroclear shares',
  createGenMeet_draftSaveText: 'Draft automatically saved at',
  createGenMeet_shareClass_label1:
    'Total number of outstanding securities, entitled to vote',
  createGenMeet_shareClass_label10:
    'Total number of outstanding securities for Euroclear Nederland, entitled to vote',
  createGenMeet_shareClass_label2:
    'Vote rights per share(for this share class)',
  createGenMeet_shareClass_label3: 'Total number of voting rights',
  createGenMeet_shareClass_maxARAllowed:
    'Maximale Anzahl zulässiger Anwesenheitsanfragen',
  createGenMeet_vote_by_proxy: 'Abstimmung über Proxy',
  createGenMeet_vote_by_correspondence: 'Abstimmung per Korrespondenz',
  createGenMeet_shareClass_label5: 'Anwesenheitsanfrage',
  createGenMeet_shareClass_label5_1: 'Virtuelle Teilnahme',
  createGenMeet_shareClass_label6: 'Registration list available via',
  createGenMeet_shareClass_label7: 'Vote collector workstation',
  createGenMeet_shareClass_label8: 'Authorized vote collectors',
  createGenMeet_shareClass_label9: 'Emittent',
  votingByCorrespondence: 'Abstimmung per Korrespondenz',
  virtualParticipation: 'Virtuelle Teilnahme',
  createGenMeet_shareClass_agent_itself: 'Agent',
  createGenMeet_agenda_label1: 'Explanation (if applicable)',
  createGenMeet_agenda_label2: 'New venture approval',
  createGenMeet_agenda_label3: 'Add agenda item',
  createGenMeet_agenda_copy: 'Copy agenda items',
  createGenMeet_agenda_copy_tooltip:
    'Copies all agenda items to any other selected language',
  createGenMeet_doc_label1: 'Attach file to',
  createGenMeet_feature_label1: 'Intermediary workstations',
  createGenMeet_feature_label2: 'Split voting allowed',
  createGenMeet_feature_rightToSpeak:
    'Attendance request with right to speak allowed',
  createGenMeet_feature_label3: 'Shareholder workstations',
  createGenMeet_feature_label5: 'Split voting allowed',
  createGenMeet_feature_label6: 'Securities account number disclosure required',
  linkedSC_feature_tooltip:
    "Enabling this feature will alert shareholders to vote/attend for the meeting which has shared share class(ISIN). The message will be displayed in shareholder work station only if the current date is with in the range of other meeting's shareholder access and deadline dates",
  linkedMeeting_attend_label: 'Möchten Sie teilnehmen an der Versammlung der',
  linkedMeeting_vote_label:
    'Möchten Sie Ihre Stimme abgeben für die Versammlung der',
  label_plsClick_Yes_toContinue: 'Klicken Sie dann auf „Ja", um fortzufahren',
  createGenMeet_feature_linkedSC:
    'Display message to vote/attend for the share class linked meeting',
  label_registrationTicket: 'Registration ticket',
  createGenMeet_feature_label8: 'Include barcode on registration ticket',
  createGenMeet_feature_label9: 'Agent logo on attendance card',
  createGenMeet_feature_label10: 'Issuer logo on attendance card',
  createGenMeet_feature_label11:
    'Include blank power of attorney on registration ticket',
  createGenMeet_feature_label12:
    'Include meeting document along with registration certificate',
  virtualParticipation_allowed: 'Virtual participation allowed',
  only_9_12_alphanumeric_error: 'Please enter alphanumeric value of length 9 to 12 characters only',
  virtual_participation_provider_meeting_id: 'Meeting ID',
  virtual_participation_provider_info1:
    '- It should be 9 numerics without any special characters. example: 127909002.',
  virtual_participation_provider_info2:
    '- Please share the meeting id and the issuer details with the virtual participation provider once the meeting is successfully created.',
  createGenMeet_InviteSP_label1: 'From address',
  label_toAddress: 'To address',
  createGenMeet_InviteSP_label2: 'Email subject',
  createGenMeet_InviteSP_label3: 'Email body',
  createGenMeet_InviteSP_label4: ' Include uploaded files',
  createGenMeet_InviteSP_label5: 'No Files uploaded',
  createGenMeet_InviteSP_label6: 'Please confirm your registration',
  createGenMeet_InviteSP_label7: 'Step 1 ',
  createGenMeet_InviteSP_label8: 'Step 2 ',
  createGenMeet_InviteSP_download: 'Download Share Plan template',
  createGenMeet_InviteSP_upload: 'Upload Share Plan data',
  createGenMeet_InviteSP_placeholder1: 'corporate.broking.finance_dummy@nl.com',
  createGenMeet_InviteSP_placeholder2: 'Please confirm your registration',
  createGenMeet_InviteSP_tooltip1:
    'Template needs to be filled in with share plan data by issuer.',
  createGenMeet_InviteSP_tooltip2:
    'Template downloaded earlier needs to be uploaded with share plan data.',
  userMaint_voteCollector_label:
    'Einrichtung, die Weisungen zur Stimmabgabe sammelt',
  userMaint_intermediary_label: 'Vermittler',
  userMaint_proxy_label: 'Proxy Intermediary',
  userMaint_groupDetails_label: 'Group details',
  userMaint_userAccounts_label: 'User accounts',
  userMaint_groupEmailAddress_label: 'E-Mail-Adresse der Gruppe',
  userMaint_bankIdentifierCode_label: 'Bank Identifier Code',
  userMaint_bankAccountNumber_label: 'Bank account number',
  userMaint_usersList_label: 'Users list',
  userMaint_officePhone_label: 'Office phone',
  userMaint_commentsForRejection_label: 'Comments for rejection',
  userMaint_issuer_warning_alreadyvc_label:
    'The provided e-mail is already registered as Vote Collector. Are you sure you want to add it as Issuer?',
  addUser_userAccounts_label: 'User account',
  forgotPass_label1: 'Anforderung eines neuen Passworts gesendet',
  forgotPass_label2:
    'Have you lost your password? You can request a new password. We will e-mail an activation link, and you can then enter a new password.',
  viewAttendance_label1:
    'Share holder name/Registration number/Securities account number',
  viewAttendance_label2: 'Shareholders registered list',
  viewVotingInstructions_label1: 'Securities holders registered list',
  item0: 'Home',
  item1: 'Allgemeine Hauptversammlungen',
  item3: 'DWT',
  item4: 'Shareholder ID',
  item5: 'Corporate Broking',
  item2: 'Meine Angaben',
  button0: 'Draft',
  button1: 'Pending',
  button2: 'To be published',
  button3: 'Live',
  button4: 'Completed',
  button5: 'Abgesagt',
  extnbtn0: 'Pending',
  extnbtn1: 'Accepted',
  extnbtn2: 'Rejected',
  label_votingConfirmation: 'Voting confirmation',
  label_votingInstructionReport: 'Voting instruction reports',
  label_downloadPDF: 'Download PDF',
  label_downloadExcel: 'Download Excel',
  label_summary: 'Summary',
  label_myVotes: 'Meine abgegebenen Stimmen/Anmeldungen',
  label_Details: 'Details',
  label_inFavor: 'In favor',
  label_against: 'Gegen',
  label_Abstained: 'Enthaltung',
  label_addAttachments: 'Add attachments',
  label_addText: 'Add text',
  label_send: 'Senden',
  adminAgent_label_profile: 'Profil',
  adminAgent_label_changePwrd: 'Passwort ändern',
  adminAgent_label_initials: 'Initialen',
  adminAgent_label_middleName: 'Vorname',
  adminAgent_label_phoneNumber: 'Telefonnummer',
  adminAgent_label_pwrdRequirements: 'Passwort-Anforderungen',
  adminAgent_label_confirmation: 'Confirmation message',
  adminAgent_label_wantToSave: 'Are you sure you want to save?',
  gmNotification_label_1: 'Notification overview',
  gmNotification_label_2: 'Attachments to notification',
  gmNotification_label_3: 'Customise message',
  gmNotification_label_4: 'Meeting notice',
  gmNotification_label_5: 'Recipient list',
  label_recipientAddress: 'Recipient address',
  gmNotification_custMsg1: 'Available means of participation',
  gmNotification_custMsg2:
    'Additional information for notification of participation',
  gmNotification_custMsg3: 'Narrative',
  gmNotification_custMsg4:
    'Other requirements for participation (if applicable)',
  voting_result: 'Voting results',
  select_recipient: '--Select recipient--',
  select_shareClass: '--Auswahl der Art der Aktien--',
  label_selectProxyType: '--Typ des Bevollmächtigten auswählen--',
  label_selectCountry: '--Land wählen--',
  label_selectUserType: '--Benutzertyp auswählen--',
  label_selectTitle: '--Anrede auswählen',
  label_selectIntermediary: '--Intermediär auswählen--',
  gmGeneralDetails_label: 'Shareholder',
  label_serialNo: 'SI-Nr.',
  label_error_lastNameReq: 'Last name is required.',
  label_error_lastNameWrong: 'Name ist nicht korrekt',
  label_error_officeNumReq: 'Office phone number is required.',
  label_error_officeNumWrong: 'Telefonnummer des Büros ist falsch',
  label_error_phoneNumReq: 'Phone number is required',
  label_error_persoNumWrong: 'Personal phone number is wrong',
  label_error_phoneNumWrong: 'Telefonnummer zu kurz oder ungültiges Format',
  label_error_emailReq: 'E-Mail Adresse ist obligatorisch',
  label_error_emailWrong:
    'E-Mail Adresse ist unvollständig oder entspricht nicht dem Format',
  label_error_name: 'Name ist obligatorisch',
  label_error_nameWrong: 'Name ist nicht korrekt',
  label_error_postWrong: 'Postleitzahl ist nicht korrekt',
  label_error_cityReq: 'Standort ist obligatorisch',
  label_error_cityWrong: 'Standort ist nicht korrekt',
  label_error_noOfSharesReq: 'Anzahl der Aktien ist obligatorisch',
  label_error_noOfSharesWrong: 'Anzahl der Aktien ist nicht korrekt',
  label_error_selectedQuantityNoOfSharesReq: 'Aktienanteil ist obligatorisch',
  label_error_selectedQuantityNoOfSharesWrong: 'Aktienanteil ist nicht korrekt',
  label_error_addressWrong: 'Adresse ist nicht korrekt',
  label_error_securityAccNoReq: 'Wertpapierdepotnummer ist obligatorisch',
  label_error_securityAccNoWrong:
    'Bei der Eingabe einer Sparkonto-/Depotnummer können Sie nur Zahlen und Buchstaben verwenden',
  label_error_countryReq: 'Country is required.',
  label_error_countryWrong: 'Country is wrong.',
  label_error_bicReq: 'Bank Identifier Code is required.',
  label_error_bicWrong: 'Bankleitzahl ist nicht korrekt',
  label_error_legalIndentifierWrong: 'Legal entity identifier is wrong.',
  label_error_repNameWrong: 'Representative name is wrong.',
  label_error_proxyNameWrong: 'Proxy name is wrong.',
  label_error_proxyNameReq: 'Name des Bevollmächtigten ist obligatorisch',
  label_poaForm: 'PoA form',
  linkIntermediary_header1:
    'Intermediaries for Institutional Shareholder Services (ISS) Europe',
  linkIntermediary_header2:
    'These are the intermediaries for which Institutional Shareholder Services (ISS) Europe can process regulations',
  linkIntermediary_button1: 'Link to Intermediary group',
  addPermission_header: 'Permissions/Registration form',
  label_error_middleNameWrong: 'Middle name is wrong.',
  label_error_firstNameWrong: 'First name is wrong.',
  label_error_initialsWrong: 'Die Initialen sind nicht korrekt',
  label_error_titleWrong: 'Title is wrong.',
  label_error_titleReq: 'Title is required.',
  voteCollectorbutton0: 'Before deadline',
  voteCollectorbutton1: 'After deadline',
  voteCollectorbutton2: 'Completed',
  label_userType: 'User type',
  label_address: 'Anschrift',
  label_dob: 'Geburtsdatum',
  label_securityAccountNumber: 'Wertpapierkonto',
  label_numberOfShares: 'Anzahl der Aktien',
  label_attendByProxy: 'Vollmacht erteilen',
  label_createNewProxy: 'Neuer Bevollmächtigter',
  label_editProxy: 'Bevollmächtigten ändern',
  label_selectProxy: 'Bevollmächtigten auswählen',
  label_delegateToSaat: 'An SAAT delegieren',
  label_attendOrBlankVoteLine1: 'Teilnahme an der versammlung',
  label_attendOrBlankVoteLine2: 'oder geben sie SAAT eine leere Anweisung',
  label_proxyType: 'Art des Bevollmächtigten',
  label_proxyId: 'Proxy-ID',
  label_none: 'Nein',
  label_vote: 'Per Weisung zur Stimmabgabe',
  label_register: 'Registrieren',
  label_allFor: 'Alle Punkte dafür',
  label_splitVotes: 'Split votes',
  label_entireHolding: 'Vollständiger Anteil',
  label_selectedQuantity: 'Selected quantity',
  label_partialHolding: 'Teilanteil',
  label_for: 'Dafür',
  label_enterNumberOfShares: 'Anzahl der Aktien',
  label_allAgainst: 'Alle Punkte dagegen',
  label_allAbstained: 'Alle Punkte Enthaltung',
  label_attend: 'Teilnahme an der Versammlung',
  label_downloadCertificate: 'Download certificate',
  label_upComingMeetings: 'Kommende Hauptversammlungen',
  label_representativeName: 'Name des Vertreters',
  label_legalEntityIdentifier: 'Legal Entity Identifier',
  label_termsAndConditions: 'Allgemeine Geschäftsbedingungen',
  label_terms:
    'Ich habe die Allgemeinen Geschäftsbedingungen gelesen und stimme ihnen zu',
  label_termsUse: 'Nutzungsbedingungen',
  label_numberOfSecurities: 'Anzahl der Aktien',
  label_attendanceInfo:
    'The following registrations will be automatically cancelled when attendance request is submitted',
  label_votingInfo:
    'The following registrations will be automatically cancelled when voting instruction is submitted',
  label_termsSentence: 'I have read and accepted the ',
  label_belgiumTermsConditions1:
    'Ich/wir bestätige(n), die Allgemeinen Geschäftsbedingungen zur Kenntnis genommen zu haben, ',
  label_belgiumTermsConditions2:
    'deren Inhalt verstanden zu haben und sie zu akzeptieren und habe(n) eine Kopie davon aufbewahrt, ausgedruckt oder an mich/uns geschickt.',
  label_votingViaIMWorkStation:
    'Voting instructions via the intermediary workstation',
  label_votingViaSHWorkStation:
    'Voting instructions via the shareholder workstation',
  label_requestRegistrationViaSHWorkStation:
    'Request a registration ticket via the shareholder workstation',
  label_groupName: 'Group name',
  label_groupCountry: 'Group country',
  label_groupRegisteredOffice: 'Group registered office',
  label_internationalBankAccountNumber: 'International bank account number',
  label_taxDeclarationNumber: 'Tax declaration number',
  label_uploadedDocuments: 'Uploaded documents',
  label_groupStatus: 'Group status',
  label_groupCity: 'Group city',
  notification_text:
    'Sehr geehrter Aktionär, um Ihnen den bestmöglichen Service bieten zu können, bitten wir Sie, Ihre Angaben zu überprüfen.',
  label_companiesOfferingGm:
    'Unternehmen, die das Aktionärsportal von ABN AMRO nutzen, sind:',
  label_seeMore: 'Weitere Informationen',
  label_knowMore: 'Know more',
  label_error_groupName: 'Group name is wrong',
  label_error_groupNameReq: 'Group name is required',
  label_pleaseFillThisField: 'Please fill this field',
  label_proxyProvided: 'Proxy provided',
  label_proxyProfile: 'Proxy profile',
  label_noDataIssuerMessage: 'No data available for issuer',
  label_feedback: 'Feedback',
  label_extensionRequestFor: 'Extension Request for',
  label_shareHolderName: 'Shareholder name',
  label_extendDeadlineTillDate: 'Extend deadline till date',
  label_reasonForDelay: 'Reason for delay',
  label_reasonForReject: 'Reason for rejection',
  label_time: 'Time',
  label_createExtnReq: 'Create extension request',
  label_tillDateReq: 'Extend deadline till date is required',
  label_shareholderNameReq: 'Shareholder name is required',
  label_shareholderPosReq: 'Shareholder position is required',
  label_shareholderPosError: 'Shareholder position is wrong',
  label_shareholderPosIncorrect: 'Shareholder position can be 1 to 999999999',
  label_attachment: 'Attachment',
  label_importFile: 'Import file',
  label_downloadStandardFile: 'Download standard file',
  label_bulkUploadAttendanceRequest: 'Bulk upload attendance request',
  label_MR: 'Mr.',
  label_MS: 'Ms.',
  label_voting_instruction: 'Weisungen zur Stimmabgabe',
  label_attendance_requests: 'Antrag auf Anmeldung',
  label_invite_share_plan: 'Invite share plan',
  label_shareholder_email: 'Shareholder email',
  label_intermediary_email: 'Intermediary email',
  label_notification_overview: 'Notification Overview',
  label_create_extension_request: 'Create Extension Request',
  label_bulk_upload_attendance_requests: 'Bulk upload attendance request',
  label_bulk_upload_voting_instructions: 'Bulk upload voting instructions',
  label_view_attendance_requests: 'View attendance requests',
  label_view_agent_group: 'View agent group',
  label_edit_agent_group: 'Edit agent group',
  label_edit_issuer_details: 'Edit issuer details',
  label_edit_intermediary_details: 'Edit intermediary details',
  label_edit_proxy_intermediary_details: 'Edit proxy intermediary details',
  label_edit_votecollector_details: 'Edit vote collector details',
  label_agent_group: 'Agent users',
  label_view_agent_group_users: 'View agent users',
  label_add_user: 'Add user',
  label_add_issuer: 'Add issuer',
  label_add_intermediary: 'Vermittler hinzufügen',
  label_add_proxy_user: 'Add proxy user',
  label_add_vote_collector: 'Add vote collector',
  label_view_agent_details: 'View agent details',
  label_edit_agent_details: 'Edit user details',
  label_edit_vote_collector_details: 'Edit vote collector details',
  label_view_issuer_group: 'Issuer groups',
  label_view_vote_collector_group: 'Vote collector groups',
  label_view_intermediary_group: 'Intermediary groups',
  label_view_proxy_intermediary_group: 'Proxy intermediary groups',
  label_add_issuer_group: 'Add issuer group',
  label_add_vote_collector_group: 'Add vote collector group',
  label_add_intermediary_group: 'Add intermediary group',
  label_add_proxy_intermediary_group: 'Add proxy intermediary group',
  label_view_intermediary_user: 'View intermediary users',
  label_view_proxy_intermediary_user: 'View proxy intermediary users',
  label_view_issuer_user: 'View issuer users',
  label_view_vote_collector_user: 'View vote collector users',
  label_view_issuer_details: 'View issuer details',
  label_view_intermediary_details: 'View intermediary details',
  label_view_proxy_intermediary_details: 'View proxy intermediary details',
  label_view_vote_collector_details: 'View vote collector details',
  label_add_permissions: 'Add permissions',
  label_link_intermediaries: 'Linked intermediaries',
  label_link_intermediary: 'Link to Intermediary',
  label_linkedIntMessage:
    'If needed intermediary is not listed, please contact Agent to onboard in the system.',
  label_linkedIntError:
    'The PoA for the selected intermediary has expired or is not yet valid, please contact Agent.',
  label_page_title: 'Corporate Broking Portal',
  label_logoUpdate: 'Update logo',
  label_extededTill: 'Intermediary deadline extended till',
  label_reasonForExtension: 'Reason for extension',
  label_privacy_policy: 'Datenschutzrichtlinie und Cookies',
  label_privacy: 'Datenschutz',
  label_cookies: 'Cookies',
  label_contact_details: 'Kontaktangaben',
  label_numberOfSharesRequired: 'Anzahl der Aktien ist obligatorisch',
  label_numberOfSharesWrong:
    'Die Anzahl der Aktien muss in Zahlen angegeben werden und darf nicht mit Null beginnen',
  label_addProxy: '+ Add Proxy',
  label_mandatoryFieldError: 'Bitte Pflichtfelder ausfüllen',
  label_textRequired: 'Text is required',
  label_pwrdReq: 'Bitte Passwort eingeben',
  newPwd_oldPwdError:
    'Das neue Passwort darf nicht mit dem alten identisch sein',
  checkPattern_Error: 'Bitte prüfen Sie das Format',
  label_noProxyAvailable: 'No proxy available',
  label_close: 'Schließen',
  label_feedbackType: 'Art der Rückmeldung:',
  label_feedbackComment: 'Anmerkungen',
  label_feedback_question: 'Frage',
  label_feedback_opinion: 'Anmerkung',
  label_feedback_comment_required: 'Feedback comment is required.',
  label_feedback_comment_maxlength: 'Max length is 4000 characters.',
  label_reason_comment_required: 'Reason comment is required.',
  label_reason_comment_maxlength: 'Max length is 2000 characters.',
  label_phone: 'Telefonnummer',
  label_show_details: 'Details anzeigen',
  label_hide_details: 'Details ausblenden',
  thankYou_msg: 'Your vote is important.Thank you for your voting instruction.',
  thankYou_msg1:
    'If you wish to change your vote, you can do this upto shareholder deadline date of the general meeting.',
  thankYou_msgButton1: 'Vote for other Share classes for this GM',
  thankYou_msgButton2: 'Browse and vote for GMs from other Issuers',
  notificationModal_header: 'Save Customised Message',
  notificationModal_body:
    'Do you want to retain the modified text for future use? (applies to all Issuers)',
  notificationModal_rightButton: 'No,for this instance only',
  notification_successMsg1: 'Message saved successfully for future use',
  notification_successMsg2: 'Message saved successfully for only this instance',
  notification_successMsg3: 'Notification sent successfully',
  label_shareHolderCreated:
    'Ihre Anmeldung ist abgeschlossen. Prüfen Sie den Posteingang Ihrer E-Mail, um Ihr Konto zu aktivieren.',
  label_shareHolderCreated_header: 'Anmeldung abgeschlossen',
  label_shareholderCreationError:
    'Some error has occured while creating Shareholder, Please contact system administration',
  label_agendaDeleted: 'Agenda deleted successfully.',
  label_agendaItemsCopied:
    'Agenda items successfully copied to other language(s).',
  label_documentDeleted: 'Document deleted successfully.',
  label_shareClassDeleted: 'Share class deleted successfully.',
  label_messageSaved: 'Meeting saved successfully.',
  label_createMeetingError:
    'Something went wrong while creating meeting. Please try after sometime',
  label_fillMandatoryFields:
    'Please fill mandatory fields to save this section',
  label_fillValidInput:
    'Please fill mandatory fields with valid input and try again.',
  label_attachmentRequired:
    'Attachment cannot be blank, Please upload a pdf file',
  label_documentLessThan5mb: 'Document should be less than 5mb',
  label_meetingSentToIssuer: 'Meeting sent to Issuer',
  label_meetingApproved: 'Meeting approved successfully',
  label_agendaRequired: 'Please fill agenda section',
  label_docRequired: 'Please fill document section.',
  label_shareClassRequired: 'Please fill share class section.',
  label_create_extn_error:
    'Extend deadline till date must be greater than intermediary deadline date and less tha general meeting date.',
  label_modalConfirmAction: 'Confirm action',
  label_modalVotingInsCreatErr: 'Voting is not available',
  label_modalVotingInsCreatErrMsg:
    'Voting instruction cannot be created for this General Meeting as the intermediary deadline date is already crossed',
  label_modalBulkUploadCreatErrMsg:
    'Bulk upload or upload BO details is not possible for this General Meeting as voting instruction creation is not enabled',
  label_modalBulkUploadAttendCreatErrMsg:
    'Bulk upload or upload BO details is not possible for this General Meeting as attendance request creation is not enabled',
  label_modalShareVotingInsCreatErrMsg:
    'Es ist nicht möglich, für diese Versammlung Weisungen für die Stimmabgabe zu erteilen',
  label_modalShareAttenReqCreatErrMsg:
    'Es ist nicht möglich, sich für diese Versammlung anzumelden',
  label_modalAttReqCreatErr: 'Es ist nicht möglich, sich anzumelden',
  label_modalAttReqCreatErrMsg:
    'You cannot create attendance request for this General Meeting as the intermediary deadline date is already crossed',
  label_modalVirtualReqCreateErr: 'Cannot create virtual participation',
  label_modalVirtualReqNoEntitlements:
    'Not entitlements found for this meeting',
  label_modalVirtualReqErrMsg:
    'You cannot update virtual participation request for this General Meeting since the intermediary deadline date is already expired',
  label_modalRtsCreatErrMsg:
    'You cannot create/edit right to speak request for this General Meeting since the intermediary deadline date is already expired',
  label_modalRtsCancelErrMsg:
    'You cannot cancel right to speak request for this General Meeting since the intermediary deadline date is already expired',
  label_modalDelete: 'Delete message',
  label_modalConfirmDelTemplate:
    'Are you sure you want to delete this template?',
  label_modalUpdateTemp: 'Update template',
  label_modalConfirmUpdateTemplate:
    'Are you sure you want to update this template?',
  label_shareHolderRegistration: 'Anmeldung Aktionär',
  label_registrationCertificate: 'Anmeldezertifikat',
  label_request_type: 'Art der Anfrage',
  lable_newReenterError: 'Passwort nicht gleich',
  issuer_groupUpdated_success: 'Issuer group details updated successfully',
  label_votingInsCreatErr: 'Informationen',
  label_bulkUploadCreatErr: 'Upload error',
  error_shareClass_not_created:
    'Something went wrong while saving share class. Please try after sometime.',
  error_shareClass_not_created_invalid_data:
    'Please fill mandatory fields with valid information',
  error_shareClass_not_created_invalid_participation_data:
      'Invalid Participation method selection, Please fill valid information',
  only_pdf_allowed: 'Invalid document(upload valid pdf file).',
  agenda_file_sizeMsg: 'This agenda file size is ',
  file_sizeMsg: ' MB. Please upload a file less than 5 MB.',
  document_file_sizeMsg: 'This document size is ',
  error_send_to_issuer:
    'There are errors. Please verify if all the sections are filled with valid information',
  newPwd_requiredError: 'New password required',
  reEnter_requiredError: 'Re-enter password required',
  numAlpha_error: 'Only alphabets and numbers are allowed',
  password_renterMatchError:
    'Both New password and Re enter password must be same',
  label_bulkUploadBO: 'Upload BO Details',
  label_registration_no: 'Anmeldungsnummer',
  label__error_registrationNoWrong: 'Registration Number is wrong',
  label__error_registrationNoReq: 'Registration Number is required',
  deadLineVoteModal_header: 'Voting is not available',
  deadLineModal_body:
    'Sie können sich nicht mehr für diese Versammlung anmelden, da die Frist abgelaufen ist.',
  deadLineModalVI_body:
    'Sie können für diese Versammlung keine Weisungen zur Stimmabgabe mehr erteilen, da die Frist abgelaufen ist.',
  deadLineAttendModal_header: 'Attendance request not available',
  lable_nonVotableItem: 'Non-votable item',
  share_class_error_checkbox_not_selected:
    'At least one option among Voting instructions or Request an attendance card must be selected.',
  document_checkbox_not_selected:
    'Please check atleast one checkbox - Attach the file to',
  noOfShares_error1:
    'Split votes should not be greater than Total Number of Shares',
  noOfShares_error2:
    'Sum of Split votes should be equal to Total Number of Shares',
  noOfShares_error3:
    'Partial quantity cannot be greater than Total No of shares',
  votingInstruction_error_msg: 'Bitte für alle Tagesordnungspunkte abstimmen',
  'intermediary-success-msg': 'Intermediary request created successfully',
  reject_meetingErrorr:
    'Something went wrong while rejecting meeting. Please try after sometime',
  approve_meetingErrorr:
    'Something went wrong while approving meeting. Please try after sometime',
  reject_meeting: 'Meeting rejected successfully.',
  approve_meeting: 'Meeting approved successfully.',
  label_loginOrRegister: 'Login or Register',
  label_downloadAttachment: 'Download attachment',
  label_selectActionType: '--Select action type--',
  label_fields: 'Fields',
  label_beforeValue: 'Before value',
  label_afterValue: 'After value',
  label_forWord: 'Dafür',
  label_selectTemplateType: '--Select template type--',
  label_selectTemplateName: '--Select template Name--',
  label_selectWorkstation: '--Select Workstation--',
  label_selectIssuer: '--Select issuer--',
  label_selectScreenName: '--Select screen name--',
  label_saveSuccessfullyStatus: 'Invite share plan sent successfully',
  label_errorImgFile: 'Please upload an image file.',
  label_errorFileSize: 'Please upload a file less than 250 KB.',
  pwdChanged_successMsg: 'Password changed successfully',
  extnReq_cannotCreate: 'Extension request cannot be created for this meeting',
  mailSent_successMsg: 'Mail(s) sent successfully',
  selectDate_error: 'Please select date fields',
  fileSize_1mbError: 'Please upload a pdf file (Max Size 1 MB)',
  selectToDate_error: 'Please select To date which is greater than From date',
  selectRegForm_error: 'Please select Registration Form',
  label_areYouSureModal: 'Are you sure you want to ',
  label_thisRecord: ' this record?',
  label_shareHolder_registered: 'Shareholders registered by ',
  label_for_shareholder: 'for',
  label_toggle: 'Toggle navigation',
  uploadFile_error: 'Please upload a file.',
  fileSizeIs_error: 'This file size is: ',
  confrim_delink: 'Confirm de-link.',
  delink_confirmMsg: 'Are you sure you want to de-link this intermediary?',
  label_thisGroup: ' this group?',
  label_thisUser: ' this user?',
  label_enterUserNameToPasswordReset:
    'Geben Sie Ihre E-Mail-Adresse ein, um ein neues Passwort anzufordern.',
  label_enterValidCredentials:
    'Unbekannte Kombination von Benutzername und Passwort',
  label_emailEmptyError: 'Please fill in email body',
  label_acceptExtnReqConfirm:
    'This request was rejected before, are you sure you want to approve it?',
  label_rejectExtnReqConfirm:
    'This request was approved before, are you sure you want to reject it?',
  label_acceptExtnReq:
    'This request was rejected before, are you sure you want to approve it?',
  label_rejectExtnReq:
    'This request was approved before, are you sure you want to reject it?',
  label_viewEditExtnReq: 'View/Edit Extension request',
  label_section_error: 'There are errors for ',
  label_section_language: 'language(s)',
  areSureDelete_modal: 'Are you sure you want to delete this Meeting?',
  areSureCancel_modal: 'Are you sure you want to cancel this Meeting?',
  areSureReject_modal: 'Are you sure you want to reject this Meeting?',
  createGenMeet_IntermediaryTooltip:
    'Select an intermediary,ADR or transfer agent which is only responsible to process voting / registration to attend instructions for this share class. Please select all intermediaries if this is not applicable.',
  issuer_name_req: 'Issuer name is required.',
  fill_issuer: 'Please fill out Issuer name.',
  meeting_type_req: 'Meeting type is required.',
  fill_meetingType: 'Please fill out meeting type.',
  meeting_name_req: 'Meeting name is required.',
  invalid_meetingName: 'Meeting name field accepts alphabets and numbers.',
  fill_meetingName: 'Please fill out meeting name.',
  website_req: 'Corporate website is required.',
  website_wrong: 'Corporate website is wrong.',
  fill_website: 'Please fill out corporate website.',
  eng: 'English',
  dut: 'Dutch',
  fre: 'French',
  ger: 'German',
  esp: 'Spanish',
  location_req: 'Location name is required.',
  location_wrong: 'Location name is wrong.',
  fill_location: 'Please fill out location name.',
  loc_tckt_req: 'Location on ticket is required.',
  loc_tckt_wrong: 'Location on ticket is wrong.',
  fill_loc_tckt: 'Please fill out location on ticket.',
  street_req: 'Street is required.',
  fill_street: 'Please fill out street.',
  postal_req: 'Postal code is required.',
  postal_wrong: 'Postleitzahl ist nicht korrekt',
  fill_postal: 'Please fill out postal code.',
  city_req: 'Ort ist obligatorisch',
  fill_city: 'Please fill out city.',
  country_req: 'Country name is required.',
  country_fill: 'Please fill out country name.',
  label_selectProxyName: '--Select proxy name--',
  createGenMeet_TimePickerTooltip:
    'With the cursor in hour or minute fields, you can use up/down arrow keys to increase/decrease the time.',
  label_callIntermediary: 'Vermittler anrufen',
  label_lei: 'LEI',
  label_error_leiWrong: 'LEI is wrong',
  uploadPositionStatementFromIntermediary:
    'Positionsübersicht des Vermittlers hochladen',
  intermediaryAcceptVoteCheckbox1:
    'We hereby confirm that we have verified the identity of the aforementioned Securities Holder, and represent and warrant his true identity.',
  intermediaryAcceptVoteCheckbox2:
    'We hereby confirm that the unique identification number is the same as the number provided to us by the aforementioned Securities Holder.',
  intermediaryAcceptVoteCheckbox3:
    'We hereby confirm that the aforementioned Securities Holder holds the mentioned Securities of the Issuing Institution at the record date.',
  intermediaryAcceptVoteCheckbox4:
    'Furthermore, we hereby confirm that no prior voting instructions have been submitted by the aforementioned Securities Holder in connection with this General Meeting and that we nor the aforementioned Securities Holder will submit voting instructions other than those being submitted via the Website.',
  intermediaryAcceptVoteCheckbox5:
    'We acknowledge that we have read, understood and consented to the ',
  intermediaryAcceptVoteCheckbox6:
    ', as well as having saved, printed or sent ourselves a copy thereof.',
  label_viewMore: 'Mehr anzeigen',
  label_addressReq: 'Adresse ist obligatorisch',
  label_initialsReq: 'Initialen sind obligatorisch',
  bulkUpload_processMsg:
    'File is being processed. Please wait while processing the request.',
  bulkUpload_successMsg: 'File processed successfully.',
  bulkUpload_errorMsg: 'There are some errors: ',
  label_sessionLoggedOut:
    'You have not used the portal for 20 minutes and have been logged out automatically. Please click outside this message window to dismiss it.',
  label_sessionWarning:
    'Session is about to expire in 5 minutes. Please respond to avoid logging out',
  label_warning: 'Warning',
  label_gmActionList: 'General Meetings – Action List',
  label_dwtActionList: 'Tax Reclaim – Action List',
  label_decline: 'Decline',
  areSureAccept_modal: 'Are you sure you want to accept this Meeting?',
  areSureDecline_modal:
    'Are you sure you want to decline the role of vote collector for this meeting?',
  label_acceptedVc: 'accepted',
  label_declinedVc: 'declined',
  label_youHave: 'You have ',
  label_roleOfVC: ' the role of vote collector for meeting ',
  label_ofIssuer: ' of Issuer ',
  label_youCanAttend: 'You can vote/attend from ',
  label_dealineDateError: 'You cannot vote for this event – deadline date was ',
  label_registerText: 'Sie haben noch kein Benutzerkonto?',
  label_createAccountButton: 'Ein neues Konto erstellen',
  areSureAccept_modalVc:
    'Are you sure you want to accept the role of vote collector for this meeting?',
  label_requestNumber: 'Request#',
  label_groupAddSuccess: 'New group added successfully',
  label_groupEditSuccess: 'User group updated successfully',
  label_on: 'am',
  label_uploadPosStatement_fromInt:
    'Upload Position statement from Intermediary',
  label_logout: 'Log out',
  label_intPhoneNumber: 'Intermediary phone number',
  phoneNumber_error: 'Die Telefonnummer ist ungültig',
  confirmFor_sendToIssuer:
    'Are you sure you want to send this meeting to issuer?',
  confirmUser_editDetails: 'Are you sure you want to edit user details?',
  confirmGroup_editDetails: 'Are you sure you want to edit this group details?',
  confirmApprove_Gm:
    'Please confirm that you have checked all information and the Registration ticked preview (on the Registrations tab) for this meeting.',
  confirmApprove_GmOtherLang:
    'Please confirm that you have checked all information and the Registration ticked preview (on the Registrations tab) for this meeting in all available languages.',
  pdfFile_formatError: 'Please upload document in PDF file format',
  label_createNewRequest: 'Create new request',
  label_cancelRequestBodyModal:
    'Sind Sie sicher, dass Sie diese Anfrage stornieren möchten?',
  label_cancelRequestHeaderModal: 'Stornierungsanfrage bestätigen',
  label_viCancellationText1:
    'Sie möchten Ihre Weisung zur Stimmabgabe stornieren? Sobald Sie dies bestätigt haben, müssen Sie sich mit Ihrem Vermittler in Verbindung setzen, um die Stornierung zu bearbeiten.',
  label_intermediaryDepartment: 'Intermediary Department',
  label_loginId: 'E-Mail-Adresse',
  label_password: 'Passwort',
  label_searchByIssuer: 'Suche nach Emittenten',
  label_DobError: 'Date of birth should not be future date',
  label_registerButton: 'Anmelden',
  label_noOfSharesError: 'Please enter number of shares.',
  label_viewIssuers: 'View issuers',
  label_viewIntermediaries: 'View intermediaries',
  label_viewProxies: 'View proxies',
  label_viewVCs: 'View vote collectors',
  label_viewAgentUser: 'View agent user',
  label_viewIntermediaryUser: 'View intermediary user',
  label_viewProxyUser: 'View proxy intermediary user',
  label_viewIssuerUser: 'View issuer user',
  label_viewVCUser: 'View vote collector user',
  label_addAgentUser: 'Add agent user',
  label_addIntermediaryUser: 'Add intermediary user',
  label_addProxyUser: 'Add proxy intermediary user',
  label_addIssuerUser: 'Add issuer user',
  label_addVCUser: 'Add vote collector user',
  label_confirmVIRequest_header: 'Weisung zur Stimmabgabe bestätigen',
  label_confirmVI_registration_header: 'Bestätigen Sie die Anmeldung',
  areYouSure_confrimVI_message:
    'Are you sure you want to confirm voting instruction request?',
  label_confirmAttendRequest_header: 'Bestätigen Sie die Anmeldung',
  areYouSure_confrimAttend_message:
    'Are you sure you want to confirm attendance request?',
  callIntermediary_SorryModalbody1:
    'Ihr Vermittler hat uns seine Kontaktdaten noch nicht mitgeteilt. Sie möchten ABN AMRO Corporate Broking kontaktieren unter ',
  callIntermediary_SorryModalbody2:
    ' oder corporate.broking@nl.abnamro.com, damit wir Sie mit der richtigen Abteilung Ihres Vermittlers in Verbindung setzen können.',
  label_callIntermediary_VI_registration:
    'Ich danke Ihnen für Ihre Weisungen zur Stimmabgabe. Sie müssen sich jetzt noch über Ihren Vermittler anmelden. ',
  label_callIntermediary_AR_registration:
    'Sie haben sich zur Teilnahme an der Versammlung angemeldet, diese Anmeldung muss noch von Ihrem Vermittler bestätigt werden.',
  label_ask_provideIdentification_VI:
    'Wenden Sie sich daher an Ihren Vermittler und bestätigen Sie die folgende Identifikationsnummer:',
  label_sh_vi_modalBody2:
    'Es kann sein, dass Ihr Vermittler diese Nummer nicht kennt. In einem solchen Fall kann sich Ihr Vermittler an ABN AMRO (AVA@nl.abnamro.com) wenden.',
  label_sh_vi_modalBody3:
    'Sollten Sie weitere Fragen haben, zögern Sie bitte nicht, ABN AMRO zu kontaktieren (corporate.broking@nl.abnamro.com)',
  label_toRegister_callIntermediary:
    'Rufen Sie Ihren Vermittler an, um Ihre Weisungen zur Stimmabgabe zu bestätigen.',
  label_ask_provideIdentification:
    'Sie werden dann nach Ihrer Identifikationsnummer gefragt:   ',
  label_contactInfo: 'Kontaktangaben',
  label_telephoneNumber: 'Telefonnummer',
  label_deptPhoneNumber: 'Telefonnummer',
  lable_onlyNumbers_error: 'Please enter number of shares',
  label_createNewAR: 'Anmeldung zur Versammlung',
  label_createNewVI: 'Weisungen zur Stimmabgabe einreichen',
  label_editVotingIns: 'Weisung zur Stimmabgabe anzeigen',
  label_createPE: 'Create PE',
  label_bulkUploadQrf: 'Bulk QRF',
  label_dwt_notification_link: 'DWT notification link',
  label_recipient: 'Recipient',
  label_downloadQRFBulkTemplate: 'DWT QRF Bulk upload template',
  label_bulkUploadQRF: 'Bulk upload QRF claim',
  label_request_date_time: 'Request date/time',
  label_requested_new_end: 'Requested new end date/time',
  label_QRF_PE: 'QRF / PE',
  label_current_QRF_PE_End_date: 'Current QRF/PE End date',
  label_reason_for_extension_request: 'Reason for extension request',
  label_document_download: 'Document download',
  label_downloadclaimoverview: 'Download Claims Overview',
  label_cc: 'cc',
  label_vebDefault: 'VEB[Standard]',
  label_SelectSecurityClass: '--Auswahl der Art der Aktien--',
  label_voteAttendEnd:
    'Es ist nicht mehr möglich, Weisungen zur Stimmabgabe zu erteilen oder sich für diese Versammlung anzumelden',
  label_voteAttendStart:
    'Es ist noch nicht möglich, Weisungen zur Stimmabgabe zu erteilen oder sich für diese Versammlung anzumelden',
  confirm_proxyLinking: 'Are you sure you want to link this intermediary?',
  label_modalAttReqActionErrMsg:
    'You cannot accept/reject requests for this General Meeting as the intermediary deadline date is already crossed',
  max4000_characters_error: 'Maximum 4000 characters are allowed.',
  label_allSectionNotFilled:
    'Please fill all the sections with valid input and proceed.',
  label_terms_IN_line1:
    'We hereby confirm that we have verified the identity of the aforementioned shareholder and warrant his true identity.',
  label_terms_IN_line2:
    'We hereby confirm that the aforementioned shareholder is in possession of the mentioned securities of the Company at the record date. Should the Company not use a record date, then we hereby warrant that the aforementioned shareholder is in possession of the mentioned securities of the Company at the General Meeting date.',
  label_terms_IN_line3:
    "In addition, we hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned shareholder as registered for this General Meeting, with right of substitution, to represent, act and vote on the shareholder's behalf, in accordance with the shareholder's instructions,  at this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Company, the civil law notary or any other person who is entrusted with the registration and/or the proxy voting procedure for this General Meeting.",
  label_terms_IN_line4:
    'Furthermore, we hereby confirm that no prior voting instructions have been submitted or will be submitted by the aforementioned shareholder in connection with this General Meeting and that we nor the aforementioned shareholder will submit voting instructions other than those being submitted via this portal on the Website.',
  label_scroll_toAccept:
    'Scrollen Sie nach unten, um die Nutzungsbedingungen zu lesen und zu akzeptieren.',
  acceptTc_shRegistration:
    'Ich bestätige, dass ich die Nutzungsbedingungen zur Kenntnis genommen habe, ihren Inhalt verstehe und akzeptiere und ein Exemplar aufbewahrt, ausgedruckt oder an mich selbst geschickt habe.',
  label_shRegistration_infoMsg:
    'Wir bitten Sie, die gleichen persönlichen Daten anzugeben, die auch Ihrem Finanzinstitut bekannt sind. ',
  label_shRegistration_infoMsg2:
    'Wir verwenden die von Ihnen eingegebenen Daten, um Ihre Stimmabgabe im Zusammenhang mit der Hauptversammlung zu erfassen oder um Ihnen eine Anmeldebescheinigung zuzusenden. Möchten Sie mehr darüber erfahren, wie wir mit Ihren Daten umgehen? Siehe unsere ',
  label_shRegistration_infoMsg3: 'Datenschutzerklärung.',
  file_sizeMsg25MB: ' MB. Please upload a file less than or equal to 25 MB.',
  label_all: '--Alle--',
  contact_heading1: 'ABN AMRO Bank N.V. Corporate Broking',
  label_shareholderLogin: 'Anmeldung als Aktionär',
  intermediary_notAllowedToVote:
    'You are not allowed to process voting instructions. Please contact ABN AMRO Bank N.V.',
  intermediary_noPOAAvailableMessage:
    "There are no valid PoA's in place, so at this moment you are not allowed to process voting instructions. Please contact ABN AMRO Bank N.V.",
  intermediary_modalcheck1:
    'We hereby confirm that we have verified the identity of the aforementioned Securities Holder(s), and represent and warrant his/their true identity.',
  intermediary_modalcheck2:
    'We hereby confirm that the aforementioned Securities Holder(s) hold(s) the mentioned Securities of the Issuing Institution at the record date.',
  intermediary_VI_modalcheck3:
    'We hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned Securities Holder(s), with right of substitution, to represent, act and vote on behalf of the Securities Holder(s), in accordance with the Securities Holder(s) instructions, at this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Issuing Institution, the civil law notary or any other person who is entrusted with the registration and/or the proxy voting procedure for this General Meeting.',
  intermediary_modalcheck4:
    'We hereby confirm that no prior voting instructions have been submitted or will be submitted by the aforementioned Securities Holder(s) in connection with this General Meeting and that we nor the aforementioned Securities Holder(s) will submit voting instructions other than those being submitted via the Website.',
  intermediary_modalcheck5:
    'We hereby confirm that we have uploaded the individual voting instruction(s) for which we have submitted a total voting instruction (block vote)',
  intermediary_modalcheck6:
    'We hereby confirm that we will provide the registration certificate(s) to the Securities Holder(s) and instruct the Securities Holder(s) to bring the registration certificate(s) to the General Meeting. In case of the appointment of a Proxy Holder, we hereby confirm that we will provide the Proxy(s) to the Securities Holder(s) and instruct the Securities Holder(s) to print, manually sign and provide this Proxy to the Proxy Holder, who must bring this manually signed document to the General Meeting.',
  intermediary_AR_reject_modalcheck4:
    'We hereby confirm that we will instruct the Securities Holder(s), where applicable, to collect the manually signed document from the Proxy Holder(s).',
  intermediary_AR_modalcheck3:
    'We hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned Securities Holder(s), with right of substitution, to register the Securities Holder(s) for participation for this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Issuing Institution, the civil law notary or any other person who is entrusted with the registration procedure for this General Meeting.',
  intermediary_BulkUpload_int_checkbox5: 'We hereby appoint',
  intermediary_BulkUpload_int_checkbox5_2:
    'as Proxy Holder to represent the Securities Holder(s) listed in this bulk upload at the General Meeting of ',
  intermediary_BulkUpload_int_checkbox5_3: '(the "Company") on',
  intermediary_BulkUpload_int_checkbox5_4:
    " and to exercise the voting rights attached to the Securities listed in this bulk upload on the Securities Holder(s)' behalf in this General Meeting.",
  intDeadline_shdedline_Tooltip:
    'Please note that for the Belgium market this date is always the six calendar day before the date of the meeting.',
  label_proxy: 'Vollmacht',
  label_participation_by_proxy : 'Teilnahme durch Bevollmächtigte',
  label_vote_by_correspondence: 'Briefwahl',
  label_participation_method: 'Participation Method',
  label_select_participation_method: '--Select Participation method--',
  vi_intermediary_individual_disclaimer1: 'Wir ernennen hiermit ',
  vi_intermediary_disclaimer1: 'Ich/wir ernenne(n) hiermit ',
  vi_intermediary_disclaimer2: ' von ',
  vi_intermediary_disclaimer3:
    ' als Bevollmächtigter (mit Vertretungsrecht) zur Vertretung des oben genannten Wertpapierinhabers auf der Hauptversammlung der ',
  vi_intermediary_disclaimer4: ' (die "Gesellschaft"), die stattfindet am ',
  vi_intermediary_disclaimer5: ' um ',
  vi_intermediary_disclaimer6: ' in ',
  vi_intermediary_disclaimer7:
    ' und die mit den vorgenannten Wertpapieren verbundenen Stimmrechte im Namen des Wertpapierinhabers in der Hauptversammlung auszuüben.',
  vi_intermediary_disclaimer_telenet0:
    'Liegen dem Bevollmächtigten keine Weisungen zur Stimmabgabe über die einzelnen Tagesordnungspunkte vor oder sind die übermittelten Weisungen aus irgendeinem Grund unklar oder muss über die von der Hauptversammlung zu fassenden Beschlüsse während der Versammlung unter Einhaltung des Gesellschaftskodex abgestimmt werden, so stimmt der Bevollmächtigte stets für den vom Verwaltungsrat vorgeschlagenen Beschlussvorschlag, gegebenenfalls in geänderter Fassung.\n',
  vi_intermediary_disclaimer8:
    'Hiermit erteile(n) ich/wir dem Bevollmächtigten die folgenden Weisungen zur Stimmabgabe in der Allgemeinen Hauptversammlung der Gesellschaft:',
  vi_vote_by_correspondence_disclaimer: 'Ihre Stimme wird direkt dem Emittenten der Versammlung zugewiesen und Ihre Stimme wird dem Emittenten unverzüglich nach der Bestätigung der Berechtigung Ihres Intermediärs mitgeteilt.',
  vi_shareholder_disclaimer1: 'Ich/wir ernenne(n) hiermit ',
  vi_shareholder_disclaimer2: ' von ',
  vi_shareholder_disclaimer3:
    ' als Bevollmächtigten (mit Vertretungsrecht), der in meinem/unserem Namen an der Hauptversammlung der ',
  vi_shareholder_disclaimer4: ' (die "Gesellschaft"), die stattfindet am ',
  vi_shareholder_disclaimer5: ' um ',
  vi_shareholder_disclaimer6: ' in ',
  vi_shareholder_disclaimer7:
    'in meinem/unserem Namen an den Beratungen teilzunehmen und die mit den oben genannten Wertpapieren verbundenen Stimmrechte gemäß den oben genannten Weisungen zur Stimmabgabe auszuüben und alle Dokumente zu unterzeichnen und/oder alle sonstigen Handlungen zur Bestätigung der abgegebenen Stimmen vorzunehmen. ',
  vi_shareholder_disclaimer8:
    'Hiermit erteile(n) ich/wir dem Stimmenrechtsammler die oben genannten Anweisungen, in meinem/unserem Namen auf der Jahreshauptversammlung der Gesellschaft abzustimmen.',
  vi_shareholder_disclaimer8_with_right_of_substitution:
    'Hiermit erteile(n) ich/wir dem Stimmenrechtsammler (mit Vertretungsrecht) die oben genannten Weisungen, in meinem/unserem Namen auf der Allgemeinen Hauptversammlung der Gesellschaft abzustimmen.',
  label_tou_registration: 'Anmeldung',
  label_tou_registration_definitions:
    'Die Anmeldung zur Teilnahme an einer bestimmten Allgemeinen Hauptversammlung über das Anmeldeportal;',
  label_tou_registration1: 'Anmeldeportal',
  label_tou_registration_definitions1:
    'Der Teil der Website, über den der Nutzer (i) die Anmeldung für die Teilnahme an einer bestimmten Hauptversammlung abschließen sowie (ii) einen Bevollmächtigten ernennen und Weisungen für die Stimmabgabe in einer bestimmten Hauptversammlung erteilen kann;',
  'label_terms_regSection4.1':
    'Über das Anmeldeportal können Sie sich für die Teilnahme an einer bestimmten Hauptversammlung anmelden.',
  'label_terms_regSection4.2':
    'Um die Anmeldung abzuschließen, muss der Nutzer ("Unterzeichnungsvorgang"):',
  'label_terms_regSection4.3':
    'Der Nutzer muss die geforderten Daten wahrheitsgemäß in die entsprechenden Felder im Anmeldungsportal der Website eintragen. Die vom Nutzer im Anmeldungsportal eingegebenen Daten werden nach Erhalt einer E-Mail-Warnung von ABN AMRO von dem vom Nutzer im Anmeldungsportal angegebenen Vermittler überprüft. Der Vermittler informiert ABN AMRO über alle unrichtigen Angaben. Der Vermittler meldet dies dann dem Nutzer, der die fehlerhaften Daten bis zum Ende des Zeitraums, in dem der Nutzer das Anmeldungsportal für eine bestimmte Hauptversammlung nutzen kann, korrigieren kann. Der Vermittler prüft die angepassten Daten, und wenn alle Daten korrekt sind, erhält der Nutzer eine entsprechende Benachrichtigung von ABN AMRO. Korrigiert der Nutzer die fehlerhaften Daten nicht innerhalb des Zeitraums, in dem er das Anmeldungsportal für eine bestimmte Hauptversammlung nutzen kann, kann die Anmeldung zur Teilnahme nicht abgeschlossen werden und gilt als nicht erfolgt.',
  'label_terms_regSection4.21':
    'Die eindeutige Identifikationsnummer, die im Anmeldungsportal erscheint, an den Vermittler weitergeben, der diese Nummer und die Identität des Nutzers überprüfen wird',
  'label_terms_regSection4.22':
    'Diese Nutzungsbedingungen lesen und seine Zustimmung zu diesen bekräftigen',
  'label_terms_regSection4.23': 'Seine Anmeldung bestätigen.',
  'label_terms_regSection4.24':
    'Mit dem Abschluss des oben beschriebenen Unterschriftsvorgangs erkennt der Nutzer an und akzeptiert, dass die Anmeldung von ihm (auch wenn ein Dritter die Anmeldung mit oder ohne Zustimmung unterzeichnet hat) mit einer elektronischen Signatur gültig unterzeichnet wurde. Der Nutzer erkennt an und akzeptiert, dass die im Anmeldungsportal verwendete elektronische Unterschrift, insbesondere das Unterschriftsverfahren, die gleiche Rechtswirkung hat wie eine handschriftliche Unterschrift.',
  'label_terms_proxySection4.4new':
    'Der Bevollmächtigte hat in der Hauptversammlung die gleichen Rechte wie der vertretene Wertpapierinhaber, insbesondere das Recht, das Wort zu ergreifen, Fragen zu stellen und das Stimmrecht auszuüben.',
  'label_terms_regSection4.4.1new':
    'Die eindeutige Identifikationsnummer, die im Vollmachtsportal erscheint, an den Vermittler weitergeben, der diese Nummer und die Identität des Nutzers überprüfen wird',
  'label_terms_regSection4.4.2new':
    'Seine Zustimmung zu diesen Nutzungsbedingungen lesen und bekräftigen',
  'label_terms_regSection4.4.3new': 'Seine Anmeldung bestätigen.',
  'label_terms_proxySection4.6':
    'Die Vollmacht ist kein Antrag auf Erteilung einer Vollmacht.',
  'label_terms_proxySection4.7':
    'Die für eine bestimmte Hauptversammlung erteilte Vollmacht gilt für aufeinanderfolgende Hauptversammlungen, die mit derselben Tagesordnung einberufen werden.',
  'label_terms_proxySection4.8':
    'Vollmachten, die vor der Veröffentlichung einer etwaigen ergänzten Tagesordnung erteilt wurden, bleiben für die Punkte der Tagesordnung, für die sie gelten, gültig. Der Stimmrechtsvertreter ist nicht befugt, ohne Weisungen über die neuen zu behandelnden Tagesordnungspunkte abzustimmen.',
  'label_terms_generalProvisions11.5.2new':
    'A Consumer domiciled in Belgium may bring proceedings in relation these Terms of Use and/or the use of the Website before the competent court in the Netherlands, or before the competent court in Belgium. In the latter case, the applicable laws shall be the laws of Belgium.',
  'label_terms_generalProvisions11.5.3new':
    'Proceedings in relation to these Terms of Use and/or the use of the Website may be brought against a Consumer domiciled in Belgium only before the court in Belgium. In this case, the applicable laws shall be the laws of Belgium.',
  'label_terms_definitions1.5header': 'Wertpapiere',
  'label_terms_definitions1.6header': 'WI-Unterzeichnungsverfahren',
  locale_tou_and: 'und',
  label_tou_securitiesHolder: 'Vermittler',
  label_tou_termsUse: 'Vollmacht',
  label_tou_uniqueIdentificationNumber: 'Stimmrechtsvertreter',
  label_tou_user: 'Vollmachtsportal',
  label_tou_header4: 'Anmeldung',
  'label_tou_header4.1': 'ANMELDUNG DURCH DEN WERTPAPIERINHABER',
  'label_terms_regSHSection4.1.1':
    'Über das Anmeldungsportal kann sich ein Wertpapierinhaber für die Teilnahme an einer bestimmten Allgemeinen Hauptversammlung anmelden.',
  'label_terms_regSHSection4.1.2':
    'Um die Anmeldung (oder den Widerruf derselben) abzuschließen, muss der Wertpapierinhaber („WI-Unterzeichnungsverfahren"):',
  'label_terms_regSHSection4.1.21':
    'Die im Anmeldungsportal angegebene eindeutige Identifikationsnummer an den Vermittler übermitteln, der diese Nummer sowie die Identität und Kapazität des Wertpapierinhabers überprüft',
  'label_terms_regSHSection4.1.22':
    'Seine Zustimmung zu diesen Nutzungsbedingungen lesen und bekräftigen',
  'label_terms_regSHSection4.1.23': 'Seine Anmeldung bestätigen.',
  'label_terms_regSHSection4.1.3':
    'Mit dem Abschluss des oben beschriebenen WI-Unterzeichnungsverfahrens erkennt der Wertpapierinhaber an und akzeptiert, dass die Anmeldung (bzw. der Widerruf derselben) von ihm rechtsgültig unterzeichnet wurde (auch für den Fall, dass ein Dritter, mit oder ohne Zustimmung, die Anmeldung (bzw. den Widerruf derselben) mit einer elektronischen Signatur unterzeichnet hat. Der Wertpapierinhaber erkennt an und akzeptiert, dass die im Anmeldungsportal verwendete elektronische Signatur, insbesondere das WI-Unterzeichnungsverfahren, die gleiche Rechtswirkung hat wie eine handschriftliche Unterschrift.',
  'label_terms_regSHSection4.1.4':
    'Nach Abschluss der Anmeldung erhält der Wertpapierinhaber eine Anmeldebescheinigung, die er zur Hauptversammlung mitbringen muss.',
  'label_terms_regSHSection4.1.5':
    'Der Inhaber des Wertpapiers muss die geforderten Daten wahrheitsgemäß in die entsprechenden Felder des Anmeldungsportals auf der Website eintragen. Die vom Wertpapierinhaber in das Anmeldungsportal eingegebenen Daten werden nach Erhalt einer E-Mail-Benachrichtung von ABN AMRO von dem vom Wertpapierinhaber im Anmeldungsportal angegebenen Vermittler überprüft. Der Vermittler informiert ABN AMRO über alle unrichtigen Angaben. Der Vermittler meldet dies dann dem Wertpapierinhaber, der die fehlerhaften Daten bis zum Ende des Zeitraums, in dem der Wertpapierinhaber das Anmeldungsportal für eine bestimmte Hauptversammlung nutzen kann, korrigieren kann. Der Vermittler prüft die angepassten Daten, und wenn alle Daten korrekt sind, erhält der Wertpapierinhaber eine entsprechende Mitteilung von ABN AMRO. Korrigiert der Wertpapierinhaber die fehlerhaften Daten nicht innerhalb des Zeitraums, in dem der Wertpapierinhaber das Anmeldungsportal für eine bestimmte Hauptversammlung nutzen kann, kann die Anmeldung zur Teilnahme nicht abgeschlossen werden, und die Anmeldung gilt als nicht erfolgt.',
  'label_tou_header4.2': 'ANMELDUNG DURCH DEN VERMITTLER',
  'label_terms_regSHSection4.2.1':
    'Über das Anmeldungsportal kann ein Vermittler, der im Namen und im Auftrag eines Wertpapierinhabers handelt, einen Wertpapierinhaber zur Teilnahme an einer bestimmten Hauptversammlung anmelden.',
  'label_terms_regSHSection4.2.2':
    'Um die Anmeldung (oder deren Rücknahme) abzuschließen, muss der Vermittler („Vermittler-Unterzeichnungsverfahren"):',
  'label_terms_regSHSection4.2.21':
    'Die Identität und Fähigkeit des Wertpapierinhabers überprüfen und bestätigen',
  'label_terms_regSHSection4.2.22':
    'Seine Zustimmung zu diesen Nutzungsbedingungen lesen und bekräftigen',
  'label_terms_regSHSection4.2.23': 'Die Anmeldung bestätigen',
  'label_terms_regSHSection4.2.3':
    'Mit dem Abschluss des oben beschriebenen Vermittler-Unterzeichnungsverfahrens erkennt der Vermittler an und akzeptiert, dass die Anmeldung (oder deren Rücknahme) von ihm rechtsgültig unterzeichnet wurde (auch wenn ein Dritter, mit oder ohne Zustimmung, die Anmeldung (oder deren Rücknahme) mit einer elektronischen Signatur unterzeichnet hat). Der Vermittler erkennt an und akzeptiert, dass die im Anmeldungsportal verwendete elektronische Signatur, insbesondere das Vermittler-Unterzeichnungsverfahren, die gleiche rechtliche Wirkung hat wie eine handschriftliche Unterschrift.',
  'label_terms_regSHSection4.2.4':
    'Nach Abschluss der Anmeldung erhält der Vermittler eine Anmeldebescheinigung für den/die von ihm angemeldeten Wertpapierinhaber. Der Vermittler sollte diese Anmeldebescheinigung dem/den Wertpapierinhaber(n) zur Verfügung stellen, der/die sie zur Hauptversammlung mitbringen sollte(n).',
  'label_terms_regSHSection4.2.5':
    'Der Vermittler muss die geforderten Daten wahrheitsgemäß in die entsprechenden Felder des Anmeldungsportals auf der Website eintragen.',
  'label_terms_regSHSection4.2.6':
    'Der Vermittler, der das Anmeldungsportal nutzt und im Namen und für Rechnung eines Wertpapierinhabers handelt, (i) tut dies ausschließlich auf der Grundlage und im Rahmen einer rechtsgültigen Vollmacht, (ii) erfüllt alle Anforderungen, die sich aus einer solchen Vollmacht ergeben oder mit ihr zusammenhängen, und (iii) legt ABN AMRO, der Emittentin, dem Notar oder jeder anderen Person, die mit dem Anmeldungs- und/oder Vollmachtsverfahren für eine bestimmte Hauptversammlung betraut ist, auf erstes Anfordern die Dokumente vor, die die Vollmacht belegen, im Namen und für Rechnung des Wertpapierinhabers zu handeln.',
  label_downloadPDF_voting: 'Voting instruction per registration PDF',
  label_downloadExcel_voting: 'Voting instruction per registration Excel',
  label_accountLocked:
    'Benutzerkonto ist gesperrt, setzen Sie Ihr Passwort zurück.',
  label_technicalError: 'Technical Error, Please try again later',
  label_sessionExpired: 'User Session has expired, Please Login to continue',
  label_selectVC: '--Unternehmen auswählen--',
  label_confirmVotes_prevGm:
    'Bestätigte Weisungen zur Stimmabgabe aus vergangenen Versammlungen',
  label_no_ar_available: 'Keine Anmeldungen vorhanden',
  label_no_vi_available: 'Keine Weisungen zur Stimmabgabe vorhanden',
  label_approved_RC: 'Genehmigt, Löschungsantrag eingereicht',
  label_cancellation_requested: 'Löschungsantrag eingereicht',
  extnbtn3: 'Cancelled',
  label_noAR: 'No attendance requests available in this status',
  label_noVI: 'No voting instructions available in this status',
  label_no_confVI_available:
    'Keine bestätigten Weisungen zur Stimmabgabe vorhanden',
  label_votingExists_error:
    'Für diese Wertpapierart und diesen Vermittler gibt es bereits eine Weisung zur Stimmabgabe. Siehe “Meine abgegebenen Stimmen/Anmeldungen“',
  label_cancelReg_info:
    'Die nachstehenden Anmeldungen werden storniert, sobald die Weisung zur Stimmabgabe von Ihrem Vermittler bestätigt wird',
  label_cancelVoting_info:
    'Die nachstehende Weisung zur Stimmabgabe wird storniert, sobald dieser Anmeldungsantrag von Ihrem Vermittler bestätigt wird',
  label_noVCavailable: 'No vote collector available',
  label_noIntermediaryAvailable: 'No intermediary available',
  label_notVotable: 'Kein Abstimmungspunkt',
  label_notVotableAgenda:
    'Es gibt keine Abstimmungspunkte für diese Versammlung',
  modal_header_selectLang: 'Wählen Sie eine Sprache zum Herunterladen',
  label_accessDate: 'Zugriffsdatum',
  mail_sentToIssuer_text1: 'Mail has been sent already on ',
  mail_sentToIssuer_text2: '. Please check before sending again.',
  label_updatePwd: 'Passwort ändern',
  modal_areYousure_changePwd:
    'Sind Sie sicher, dass Sie das Passwort ändern möchten?',
  label_update_accountDetails: 'Profil aktualisieren',
  modal_areYouSure_updateDetails:
    'Sind Sie sicher, dass Sie das Profil aktualisieren möchten?',
  label_noGMMessage: 'No general meeting available',
  label_proxyTitle: 'Proxy title',
  label_proxyCountry: 'Proxy country',
  label_proxyCity: 'Proxy city',
  label_noDataGMIssuerMessage: 'No general meeting available for issuer',
  label_utc: 'UTC',
  label_uploadBOFile: 'Upload BO file',
  label_uploadBOFileError:
    'Uploading a BO file in ".xlsx" is mandatory if the user type is NOMI or TRUS',
  label_checkShareholder: 'Check for shareholder',
  label_edit_IN_AR_BOFile: 'Anmeldung ändern',
  label_total_voting_instrunction: 'Total voting instructions',
  label_voting_inst_per_reg: 'Voting instruction per registration',
  label_pdf: 'Pdf',
  label_excel: 'Excel',
  label_cancelReq: 'Anfrage löschen',
  label_reqCancellation: 'Antrag auf Löschung',
  label_dnldRegCerti: 'Anmeldebescheinigung herunterladen',
  label_addIntSucessMsg: 'La demande d’intermédiaire a été créée',
  label_pwdSuccessMsg: 'Das Passwort wurde aktualisiert',
  lable_accountUpdateSuccessMsg: 'Benutzerkonto wurde aktualisiert',
  label_noShareclassAvailable: 'No shareclass available',
  label_selectRegCertLang: 'Select registration certificate language',
  label_sendRegTicketToShareholder:
    'Send registration tickets to shareholders based on provided mail address after upload in ',
  label_pn: 'Telefonnummer',
  label_coc: 'Handelskammer',
  label_vat: 'Umsatzsteueridentifikationsnummer',
  label_contact_details_footer: 'Kontaktangaben',
  label_noSHGMFound: 'Derzeit finden keine Hauptversammlungen statt',
  label_voting_disabled:
    'Die Stimmabgabe ist für diese Versammlung deaktiviert.',
  label_attendance_disabled:
    'Die Teilnahme an dieser Versammlung ist nicht möglich.',
  checkPattern_Pwd_length: 'Das Passwort muss mindestens 8 Zeichen lang sein',
  label_DobInvalid: 'Geburtsdatum ist obligatorisch (TT-MM-JJJJ)',
  label_dobFutureError: 'Das Geburtsdatum darf nicht in der Zukunft liegen',
  label_dateFutureError: 'Das Datum darf nicht in der Zukunft liegen',
  label_dateInvalid: 'Bitte geben Sie das Datum in „TT-MM-JJJJ“ ein',
  label_voteNotPossible:
    'Es ist nicht möglich, für diese Versammlung Weisungen zur Stimmabgabe zu erteilen',
  label_attendNotPossible:
    'Es ist nicht möglich, sich für diese Versammlung anzumelden',
  label_attendNotEnabled1:
    'Leider ist es den Aktionären nicht (mehr) möglich, an der Hauptversammlung teilzunehmen. Sie können Kontakt aufnehmen mit',
  label_attendNotEnabled2:
    'Vollmacht/Weisungen zur Stimmabgabe können über die Schaltfläche „per Weisung" erteilt werden. Wenn eine virtuelle Teilnahme möglich ist, können sich die Aktionäre über die Schaltfläche „Virtuell teilnehmen" für die virtuelle Teilnahme anmelden',
  label_attendDisabled1_intermediary:
    'It is unfortunately not possible for shareholders to attend the meeting in person. Therefor create new registration and also bulk upload is not allowed. Your clients (shareholders) should contact the company ',
  label_attendDisabled2_intermediary:
    ' for approval to attend the meeting in person.',
  label_voteAttendNotAllowed:
    'Es ist nicht mehr möglich, Weisungen zur Stimmabgabe zu erteilen oder sich für diese Versammlung anzumelden',
  label_voteAttendNotAllowedAccessDate:
    'Abstimmen / Teilnehmen wird nach dem Zugangsdatum der Aktionäre aktiviert',
  label_shareholderExists:
    'Diese E-Mail-Adresse ist bereits registriert. Sie können sich direkt einloggen oder ein neues Passwort anfordern, wenn Sie es vergessen haben.',
  label_oldPassIncorrect: 'Altes Passwort ist falsch',
  label_existLastPassList:
    'Dieses Passwort existiert bereits in der Liste der letzten 12 Passwörter',
  label_passLengthIncorrect: 'Passwortlänge ist nicht korrekt',
  label_dataNotFound: 'Keine Daten für den angegebenen Eintrag gefunden',
  label_passNotMatch: 'Passwort stimmt nicht überein',
  label_accessDenied: 'Zugang verweigert',
  label_globalError_1:
    'Leider ist ein Fehler bei der angeforderten Bearbeitung aufgetreten. Andere Bearbeitungen können funktionieren, können aber nicht garantiert werden.',
  label_globalError_2:
    'Für eine direkte Kommunikation mit dem technischen Support senden Sie bitte eine E-Mail an corporate.broking@nl.abnamro.com und teilen Sie uns mit, welche Aktion Sie durchgeführt haben, damit wir Sie kontaktieren können.',
  label_error: 'Falsch!',
  label_newVote: 'Neue Anweisung',
  label_newRegistration: 'Neue Anmeldung',
  label_createRTS: 'Create new RTS request',
  label_editRTS: 'Edit RTS request',
  label_continue: 'Continue',
  label_conf_votes: 'Bestätigte Weisungen zur Stimmabgabe',
  lable_AR_proxy_error:
    'Die Anmeldung (für die Teilnahme an der Versammlung selbst) ist bereits für diese Versammlung erfolgt',
  lable_AR_shares_error:
    'Sie können für diese Versammlung nicht abstimmen oder sich anmelden, da Sie mit der von Ihnen angegebenen Anzahl von Aktien die Gesamtzahl der für diese Aktienklasse ausstehenden Aktien überschreiten.',
  label_edit_extn_request: 'Edit Extension Request',
  label_shGMNotFound:
    'Derzeit sind keine Allgemeinen Hauptversammlungen verfügbar ',
  label_forYourSearchTerm: 'für Ihre Suche',
  label_defaulProxyForbulkUpload:
    'Add "Iedere vertegenwoordiger van de VEB" as proxy for all attendance requests in this upload file',
  label_request_date: 'Request date',
  label_dateandtime: 'Date and time',
  label_exclamation: '!',
  label_noDWTavailable: 'There are no DWT events available',
  label_noDataGMIssuerMessage_DW: 'No DWT events available for issuer',
  label_portalInfoExample:
    'This portal is still under construction and the information shown is an example',
  label_error_nameInvalid:
    'Name ist ungültig. Es werden mindestens 2 gültige Zeichen erwartet',
  label_thereAreNo: ' There are no',
  label_eventsAtMoment: 'tax reclaim events at this moment',
  label_draft: 'draft',
  label_pending: 'pending',
  label_toBePublished: 'to be published',
  label_live: 'live',
  label_completed: 'completed',
  label_cancelled: 'cancelled',
  label_documentLessThan25mb: 'Document should be less than 25mb',
  label_iWouldAttend:
    'Ich möchte mich zur Teilnahme an der Versammlung anmelden',
  label_iWouldVote:
    'Ich möchte Weisungen für die Stimmabgabe in der Versammlung erteilen',
  label_checkSCMsg:
    'Add check to prevent register or vote to wrong share class',
  label_sendEmailEuro: 'Send email to Euroclear',
  label_downloadEuroclearReport: 'Download Euroclear report',
  label_agentWS: 'Agent workstation',
  label_sendVIreg_toEuroclear:
    'Option to send registrations and voting instructions to Euroclear',
  label_proceed: 'Weiter',
  label_dear: 'Dear ',
  label_noShareholderDetails: 'No shareholder details found',
  label_euroclear_mesage: 'Mail has been sent already on',
  label_plsCheck_mesage: 'Please check before sending again.',
  label_reSendEmailEuro:
    'Mail has been already sent. Do you want to send it again?',
  label_subject_required: 'Subject is required.',
  label_emailboday_required: 'Email body is required.',
  sendVIreg_toEuroclear_Tooltip: 'This option is mandatory for Shell',
  label_nwkError_1: 'Please validate your internet connectivity and check:',
  label_download_intermediary_instructions: 'Download accepted instructions',
  label_nwkError_2: '-- Checking the network cables, modem, and router',
  label_nwkError_3: '-- Reconnecting to Wi-Fi',
  label_nwkError_4:
    '-- Refresh the browser (press F5) once the connectivity issue is resolved',
  label_network_error_lost: 'Internet connection lost',
  label_network_error_1:
    'The page could not be loaded as there is or was an issue with your internet connection.',
  label_network_error_2:
    'Please check your connection and refresh the browser (press F5) once the connection issue is resolved.',
  label_noVCavailable_forVote:
    'This general meeting and shareclass does not have any vote collector',
  label_aegon_issuer:
    'Die Hauptversammlung kann live auf der Website von Aegon verfolgt werden',
  label_instruction_not_available:
    'There are no accepted instructions to download',
  vi_intermediary_disclaimer_telenet1:
    'IDw Consult BV, mit Herrn Bert De Graeve als ständigem Vertreter, ist das führende Organ der Telenet Group Holding NV und hat daher einen potenziellen Interessenkonflikt gemäß Artikel 7:143, §4 des Gesellschaftsgesetzes. Im Falle eines potenziellen Interessenkonflikts wird er bei der Ausübung der Stimmrechtsvollmacht nur gemäß den in dieser Vollmacht enthaltenen spezifischen Weisungen zur Stimmabgabe abstimmen. Fehlt eine ausdrückliche Weisung zur Stimmabgabe, so wird davon ausgegangen, dass er die ausdrückliche Weisung erhalten hat, für diesen Punkt zu stimmen.',
  vi_intermediary_disclaimer_telenet2: '',
  lable_numberOfShares_error: 'Invalid number of shares',
  lable_5posOnly: 'Die Initialen können bis zu 5 Stellen lang sein',
  label_votingAdvise: 'Voting advise',
  label_shareholder_link: 'www.abnamro.com/shareholder',
  label_intermediary_link: 'www.abnamro.com/intermediary',
  label_proceedToLogout: 'Weiter zu ',
  label_asACustomerOf: 'Als Kunde von ',
  label_voteViaIntermediary:
    'können Sie sich für die Versammlung nur anmelden über die Website von ',
  label_alex_binkBank_warning:
    ' können Sie Weisungen zur Stimmabgabe nur über die Website einreichen von',
  label_reloadCancelError:
    'Bitte wählen Sie eine andere Versammlung oder eine andere Anteilsklasse, um fortzufahren.',
  label_teleNumber: 'Telefonnummer',
  label_addresseeEmail: 'E-Mail-Adresse',
  lable_contactDetails: 'Kontaktangaben',
  pwdChanged_successMsg_proceed:
    'Password changed successfully. Redirecting to login page...',
  pwdExpiry_msg:
    'Your password is expired, please change your password. Be aware that you cannot use the last 12 used passwords.',
  label_viewVotingInstruction: 'View voting instruction',
  label_login_failed: 'Login redirect failed ',
  label_noVIAvailable: 'No voting instruction available for ',
  label_of: 'oder',
  label_pwd_requirement: 'Please check the password requirements',
  label_one_email:
    'Please ensure that only one valid email id is entered in the "To" address field',
  lable_mobile_number: 'Mobiltelefon',
  label_mobile_number_reenter: 'Wiederholen Sie Ihre Mobiltelefonnummer',
  lable_mobile_number_guidelines:
    'Wählen Sie die richtige Landesvorwahl und geben Sie Ihre Mobiltelefonnummer gemäß den nachstehenden Anweisungen ein:',
  lable_mobile_number_numeric:
    '- Die Mobilfunknummer darf nur aus Nummern bestehen',
  lable_mobile_number_length: '- Die maximal zulässige Länge beträgt 15 Zahlen',
  label_mobile_number_numeric_error:
    'Die Mobilfunknummer sollte nur aus Zahlen bestehen.',
  label_phone_number_exists_error: 'Diese Telefonnummer ist bereits vergeben.',
  label_country_code_error: 'Please select the country code.',
  label_virtual_participation: 'Virtuele Teilnahme',
  label_virtual_participation_and_vote: 'Virtuell an der Sitzung teilnehmen und abstimmen',
  label_virtual_participation_no_voting:
    'Virtuelle Anwesenheit, Abstimmen nur mit Vollmacht',
  label_attend_virtualy: 'Virtuelle Teilnahme',
  label_attend_sh: 'Anmelden',
  label_vote_sh: 'Stimmen',
  label_virtualPartNotAllowedAccessDate:
    'Die virtuelle Teilnahme ist nach dem Zugangsdatum des Aktionärs möglich',
  label_register_virtualParticipation_step_counter_1: 'Aktien und Kontonummer',
  label_register_virtualParticipation_step_counter_2: 'Telefon, Bedingungen',
  label_register_virtualParticipation_step_counter_3: 'Verifizierungscode',
  label_register_virtualParticipation:
    'Anmeldung zur virtuellen Teilnahme an der Versammlung',
  label_register_virtualParticipation_verify_phone:
    'Überprüfung der Telefonnummer',
  label_register_virtualParticipation_acknowledge: 'Anerkennen und akzeptieren',
  label_register_virtualParticipation_enter_code: 'Code eingeben',
  label_register_virtualParticipation_success:
    'Ihre Anmeldung zur virtuellen Teilnahme war erfolgreich',
  label_register_virtualParticipation_success_message:
    'Ihr Antrag auf virtuelle Teilnahme an der Versammlung muss von Ihrem Vermittler genehmigt werden.',
  label_register_virtualParticipation_please_confirm:
    'Bitte bestätigen Sie Ihrem Vermittler Ihre Depotnummer und Ihre Mobiltelefonnummer',
  label_register_virtualParticipation_question: 'Frage',
  label_register_virtualParticipation_question_message:
    'Wenn Sie Fragen haben, zögern Sie bitte nicht, sich an die ABN AMRO Bank zu wenden.',
  label_verfication_code_sent:
    'Ein neuer Bestätigungscode wurde an Ihr Mobiltelefon gesendet.',
  label_verifying_code: 'Verifizierungscode...',
  label_verfication_sending_new_code: 'Neuen Code senden',
  label_otp_resend_message_part1: 'Die Schaltfläche Erneut senden wird in  ',
  label_otp_resend_message_part2: ' Sekunden aktiviert. Bitte warten Sie auf die Einmal-PIN (OTP).',
  label_send_again: 'Erneut senden',
  label_code: 'Code',
  label_back: 'Zurück',
  label_verify: 'Überprüfen',
  label_contact_intermediary: 'Kontakt mit Vermittler aufnehmen',
  label_join_meeting: 'An der Versammlung teilnehmen',
  label_to_my_events: 'Zu meinen Veranstaltungen',
  label_account: 'Depotnummer',
  label_terms_vp1:
    'I/We herby confirm that I/We have read, understood and consented the',
  label_terms_vp2:
    'as well as having saved, printed or sent myself/ourselves a copy thereof.',
  label_contact: 'Kontakt',
  label_helpdesk: 'Helpdesk',
  label_shares: 'Aktien',
  label_SAN_error: 'Bitte geben Sie eine gültige Depotnummer ein',
  label_SAN_exists_error:
    'Securities account number already exists for this meeting',
  lable_TermsAndConditions: 'Bedingungen ',
  lable_phoneNumberVerification:
    'Sobald diese Telefonnummer verifiziert ist, kann sie nicht mehr geändert werden. Sie muss dann bis nach der Versammlung verwendet werden.',
  label_virtualParticipationRequests: 'Virtual participation requests',
  label_no_virtualParticipation:
    'Keine Anträge auf virtuelle Anwesenheit verfügbar',
  lable_mobileNumMismatch:
    'die eingegebenen Telefonnummern stimmen nicht überein',
  label_phoneNumber_exists_meeting_error:
    ' Sie sind bereits mit einer anderen Telefonnummer für diese Versammlung registriert. Überprüfen und korrigieren Sie ggf. die Anmeldungen in Ihrer Anmeldungsübersicht.',
  label_numeric_value_error: 'Bitte nur Zahlen eingeben',
  label_meeting_details: 'Daten der Versammlung',
  label_join: 'Teilnehmen',
  label_join_meeting_enable_hover_text:
    'Wählen Sie, um virtuell an der Versammlung teilzunehmen',
  label_join_meeting_disable_hover_text1:
    ' Sie können eine Verbindung zur Versammlung herstellen von',
  label_join_meeting_disable_hover_text2: 'bis Mitternacht',
  label_virtual_participation_leave_cbp_warning:
    'Sie sind im Begriff, das elektronische ABN AMRO-Abstimmungsportal zu verlassen und die virtuelle Teilnahmeanwendung/-Website aufzurufen. Diese Anwendung/Website wird nicht von ABN AMRO bereitgestellt oder betrieben. ABN AMRO ist daher nicht verantwortlich für deren Inhalt, Verfügbarkeit und Funktionalität. Bei Fragen oder Problemen mit der virtuellen Teilnahmeanwendung/-Website wenden Sie sich bitte an +3120 628 6070.',
  label_button_accept_and_proceed: 'Akzeptieren und fortfahren',
  label_helpDesk_Number: '+31 20 628 6070',
  label_Important_Information: 'Wichtige Informationen',
  label_cbp_email: 'corporate.broking@nl.abnamro.com',
  label_dual_factor_authentication_success:
    'Sie wurden erfolgreich zur virtuellen Teilnahmeanwendung/-Website weitergeleitet',
  label_authentication_generic_message:
    'Leider können wir Ihre Anfrage nicht bearbeiten. Bitte wenden Sie sich an den Helpdesk.',
  label_2FA_OTP_fail:
    'Die Erstellung/Überprüfung des Codes (OTP) ist nicht möglich. Bitte versuchen Sie es erneut.',
  label_Invalid_OTP_msg:
    'Ungültiger/abgelaufener Code (OTP). Bitte senden Sie erneut, um einen neuen Code zu erhalten.',
  CBP_ERR_573:
    'Diese Depotnummer ist bereits für diese Versammlung registriert',
  label_like_to_attend_virtually:
    'Ich würde gerne virtuell an der Versammlung teilnehmen',
  label_like_to_attend_and_vote_virtually:
      'Ich möchte an der Versammlung virtuell teilnehmen und abstimmen',
  label_virtual_Meeting_Id_mandatory_msg:
    'For virtual participation, meeting id is mandatory',
  label_virtual_participation_provider_required_msg: 'A provider is required',
  label_download_registration_list: 'Download registration list',
  label_vp_registration_risk:
    'Sie können unten bestätigen, dass Sie virtuell an der Allgemeinen Hauptversammlung teilnehmen möchten.\n\nWenn Sie fortfahren möchten, erkennen Sie die Risiken an, die mit der virtuellen Teilnahme an der Hauptversammlung verbunden sind, und akzeptieren diese, indem Sie auf das entsprechende Feld unten klicken und den Abschnitt über die virtuelle Teilnahme aufrufen, indem Sie auf die Schaltfläche „Akzeptieren und fortfahren" klicken.\n\nZu den Risiken, die mit der virtuellen Teilnahme an der Hauptversammlung verbunden sind, gehören: Ausfälle von Ausrüstungen für virtuelle Versammlungen, Software, Netzinfrastruktur, Servern, Internet- oder Telefonverbindungen, Video- oder Sprachanlagen, die gegebenenfalls das Ergebnis eines Informationssicherheitsvorfalls sein können (u. a. unbefugte Nutzung, Hacking, Denial of Service (DoS)-Angriff, Zugangsverweigerung, Fehlfunktionen oder Eindringen infolge von Diebstahl oder unrechtmäßigem Abfangen eines Passworts oder Codes für den Netzzugang sowie andere Arten von Cyber-Angriffen) oder die durch mechanische Störungen, wie Stromausfälle während der Hauptversammlung und andere unvorhergesehene technische Probleme oder Unterbrechungen im Zusammenhang mit der Website im Allgemeinen, dem Bereich der virtuellen Teilnahme, der Hauptversammlung oder die von Ihnen, dem Aktionär, verwendeten Geräten und/oder Verbindungen, verursacht werden.\n\nDurch Anklicken der unten stehenden Felder bestätigen Sie:\n',
  label_vp_registration_risk_item1:
    'dass Sie die oben beschriebenen Risiken anerkennen und akzeptieren und dass Sie unsere Bedingungen für die Anmeldung und Nutzung des Bereichs Virtuelle Teilnahme gelesen und verstanden haben.',
  label_vp_registration_risk_item2:
    'dass Sie berechtigt sind, diese Risiken zu akzeptieren, weil Sie selbst Aktionär sind oder eine entsprechende Vollmacht erhalten haben.',
  label_vp_registration_risk_item3:
    'dass Sie die vom Emittenten für anwendbar erklärte hybride/virtuelle Stimmrechtspolitik für Aktionäre gelesen und verstanden haben und dieser zustimmen. Diese Politik kann hier eingesehen werden: ',
  label_vp_registration_risk_item3_no_link:
    'hiermit bestätigen Sie, falls vorhanden, dass Sie, die Richtlinie in Bezug auf die hybride/virtuelle Versammlung/die Teilnahme und die von der ausstellenden Institution für anwendbar erklärte Datenschutzerklärung gelesen, verstanden und akzeptiert haben.',
  label_vp_registration_risk_document:
    'Die Nutzungsbedingungen, die für die Nutzung der Website im Allgemeinen und des Bereichs Virtuelle Teilnahme im Besonderen gelten, finden Sie hier: ',
  label_policy_issuing_institution: 'Richtlinien des Emittenten',
  label_virtual_Meeting_date_mandatory_msg: 'Enable join by date is required',
  label_Enable_join_date_error_msg:
    'Enable join by date should be greater than the shareholder access date',
  label_Enable_join_by: 'Enable join by',
  label_voting_options: 'Abstimmungsoptionen',
  label_record_date_required: 'Record date is required',
  label_record_date_format:
    'Record date format should be like \n 01-April-2017\n',
  label_record_date_before_GM:
    'Record date should be before general meeting date.',
  label_record_date_after_convocation:
    'Record date should be after convocation date.',
  label_publication_date_required: 'publication date is required.',
  label_publication_date_format:
    'publication date format should be like \n 01-April-2017\n.',
  label_publication_date_before_GM:
    'publication date should be before general meeting date.',
  label_publication_date_after_convocation:
    'publication date should be after convocation date.',
  label_gmProductType: 'GM',
  virtual_participation_allowed_tooltip:
    'Virtual participation can only be disabled when no pending or approved virtual participation requests exist for the meeting',
  registered_share_help_text:
    ' Bitte geben Sie die Art der Aktien an, mit denen Sie teilnehmen möchten. Wenn Sie Aktien über das Aktionärsregister der Gesellschaft halten, wählen Sie bitte Aktienregister.',
  label_intermediaryHelpText:
    'Der Vermittler ist die Einrichtung, bei dem Sie Ihr Depot führen',
  label_approved_registrations: 'Approved registrations',
  label_approved_securities: 'Approved securities',
  label_pending_registrations: 'Pending registrations',
  label_total_pending_registrations: 'Total pending registrations',
  label_overview_of_live_general_meeting: 'Overview of live general meeting',
  label_modal_header_send_preview: 'Send preview',
  label_modal_confirm_template_updated: 'Template updated',
  label_modal_confirm_preview_sent: 'Preview email is sent',
  label_modal_failed_sent_failed: 'Sending the preview failed',
  label_modal_preview_sending: 'Sending preview...',
  CBP_ERR_604: 'reCAPTCHA-Verifizierung fehlgeschlagen',
  CBP_ERR_227:
    'Diese E-Mail-Adresse ist bereits registriert. Sie können sich direkt anmelden oder ein neues Passwort anfordern, wenn Sie es vergessen haben.',
  CBP_ERR_572: 'Diese Telefonnummer ist bereits im System vorhanden.',
  label_status_LIVE: 'Live',
  label_status_PENDING: 'Pending',
  label_enterNumberOfSecurities: 'Angabe der Anzahl der Aktien',
  vi_disclaimer_greenyard1:
    'Wenn Sie eine Vollmacht für die außerordentliche Hauptversammlung der Aktionäre erteilt haben, sollte die ausgefüllte und unterzeichnete Vollmacht im Original entweder per Post an die Gesellschaft unter der folgenden Adresse geschickt werden: ',
  vi_disclaimer_greenyard2:
    'Greenyard NV, z. Hd. Frau Fran Ooms, Strijbroek 10, 2860 Sint-Katelijne-Waver (Belgien), oder bei der außerordentlichen Hauptversammlung der Aktionäre durch den Bevollmächtigten hinterlegt werden. Wenn die Gesellschaft das Original der Vollmacht nicht vor Beginn der außerordentlichen Hauptversammlung erhalten hat, ist die Vollmacht ungültig.',
  ie11_xlsx_download_warning:
    'Das Herunterladen von .xlsx wird vom IE11 nicht vollständig unterstützt. Sollte der Download fehlschlagen, versuchen Sie es in einem anderen Browser (Chrome, Firefox, Safari oder Edge).',
  label_more_info: 'Weitere Infos',
  toolTip_doNotPublish:
    'When selected the shareclass will not be published to the market and to normal shareholders. (When all shareclasses of a meeting are selected, the entire meeting itself is invisible to the market and shareholders)',
  label_sh_different_custodian_message: `Wenn Sie auch bei einer anderen Depotbank Stimmrechtsunterlagen haben, <a href="mailto:${GM_CONTACT_EMAIL}">, kontaktieren Sie uns bitte</a> `,
  label_shareholderGmCategory0: 'Versammlungen mit Abstimmungsoptionen',
  label_shareholderGmCategory1: 'Versammlungen mit dem Stichtagsdatum in der Vergangenheit',
  label_shareholderGmCategory2: 'Versammlungen mit dem Zugriffsdatum in der Zukunft',
};
