import './register-shareholder.scss';

/**
 * @ngdoc controller
 * @name commonModule.RegisterController
 * @description This controller mainly deals with registration of new
 *              shareholder
 * @requires shareHolderService is the service which is being used to interact with REST
 * @requires $scope for storing information in scope
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $filter to translate messages from locale file
 * @requries $timeout for timeout message
 */
('use strict');

angular.module('loginModule').controller('RegisterController', [
  '$scope',
  '$log',
  '$filter',
  '$sessionStorage',
  'ModelWindowService',
  '$state',
  '$timeout',
  '$uibModal',
  'shareHolderService',
  'touService',
  'captchaService',
  function (
    $scope,
    $log,
    $filter,
    $sessionStorage,
    ModelWindowService,
    $state,
    $timeout,
    $uibModal,
    shareHolderService,
    touService,
    captchaService
  ) {
    $scope.recaptchaDisabled = true;
    $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;
    $scope.securityAccountValidation = validationRegex.SECURITY_ACCOUNT_NR;
    $scope.shRegistrationPage = true;
    $scope.showIntermediary = true;
    $scope.disableIntermediary = false;
    $scope.success = false;
    $scope.error = false;
    $scope.showTermsOfUse = false;
    $scope.requestObj = {};
    $sessionStorage.userId = '';
    if ($sessionStorage.workstationData) {
      $scope.languageCode = $sessionStorage.workstationData.selectedLang;
    } else {
      $scope.languageCode = $sessionStorage.selectedLang;
    }
    $scope.loading = true;
    $scope.loadTermsOfUse = function () {
      $scope.termsOfUseUrl = touService.getTouUrl('GM', $scope.languageCode);
      $scope.showTermsOfUse = true;
    };
    // Below is the service call to interact with REST and get
    // the country list, options for title and type of user dropdowns
    shareHolderService.registerRequest($scope.languageCode).then(
      function (resp) {
        $log.debug(resp);
        $scope.registerShareholder = resp;
        var defaultShTypeCheck = $filter('filter')(
          $scope.registerShareholder.data.shareHolderTypeVOs,
          {
            type: 'NAT',
          }
        );
        if (defaultShTypeCheck) {
          $scope.type = 'NAT';
        }
        /*patchwork for registration page when opened in new tab directly*/
        if (
          resp.data.countryCodeVO.length > 0 &&
          resp.data.intermediaryVOs.length > 0
        ) {
          $scope.loading = false;
        } else {
          $scope.loading = true;
        }
        if (resp.data.shareHolderTitleVO.length <= 0) {
          $scope.registerShareholder.data.shareHolderTitleVO.push(
            { title: 'Mr.' },
            { title: 'Ms.' }
          );
          $scope.languageCode = 'EN';
        }
        if (resp.data.shareHolderTypeVOs.length <= 0) {
          $scope.registerShareholder.data.shareHolderTypeVOs.push(
            { type: 'LGL', description: 'Legal Person' },
            { type: 'NAT', description: 'Natural Person' }
          );
          $scope.languageCode = 'EN';
        }
        /*patchwork for registration page when opened in new tab directly*/
        $log.debug($scope.registerShareholder);
      },
      function (err) {
        $scope.loading = false;
        $log.debug(err);
      }
    );
    // when language changes, following piece of code is
    // executed from header-controller.js
    $scope.$on('onLanguageChangeVotingInstruction', function (event, lang) {
      $scope.languageCode = lang;
      shareHolderService.registerRequest($scope.languageCode).then(
        function (resp) {
          $log.debug(resp);
          $scope.registerShareholder = resp;
          $log.debug($scope.registerShareholder);
        },
        function (err) {
          $log.debug(err);
        }
      );
    });
    $scope.intermediary = function () {
      if (!$scope.disableIntermediary) {
        $scope.disableIntermediary = true;
        $scope.selectIntermediary = '';
        $scope.reqValid = true;
      } else {
        $scope.disableIntermediary = false;
        $scope.reqValid = false;
      }
    };

    //To open privacy policy in new tab based on language selection
    $scope.openPrivacyTab = function () {
      if (
        $sessionStorage.selectedLang === 'EN' ||
        $sessionStorage.selectedLang === 'FR' ||
        $sessionStorage.selectedLang === 'DE' ||
        $sessionStorage.selectedLang === 'ES'
      ) {
        window.open(
          'https://www.abnamro.com/en/home/information/privacy-statement',
          '_blank'
        );
      } else if ($sessionStorage.selectedLang === 'NL') {
        window.open(
          'https://www.abnamro.com/nl/home/informatie/privacyverklaring',
          '_blank'
        );
      }
    };

    // Below function is to check the status of the register button
    // based on the form validation and potential enabled recaptcha response
    $scope.checkRegisterButtonStatus = function () {
      if ($scope.recaptchaDisabled) {
        return false;
      }
      return $scope.c1.expired || !c1.gRecaptchaResponse;
    };

    // Below function calls on click of OK button in success
    // modal window
    $scope.goToLoginPage = function () {
      $state.go('shareholderlogin');
    };
    // The below function calls on submit of registration
    // form, this is to store the data from UI and
    // pass it to REST call for registration of new
    // shareholder
    $scope.submit = function () {
      $scope.requestObj.shareHolderType = $scope.type;
      if ($scope.title) {
        $scope.requestObj.title = $scope.title;
      }
      if ($scope.initials) {
        $scope.requestObj.initials = $scope.initials;
      }

      $scope.requestObj.phoneCountryCode = 0;
      if ($scope.mobilePhone && $scope.phoneCountry) {
        $scope.requestObj.phoneCountryCode = parseInt(
          $scope.phoneCountry.dialCode,
          10
        );
      }

      $scope.requestObj.lastName = $scope.name;
      $scope.requestObj.address = $scope.address;
      $scope.requestObj.postalCode = $scope.postalCode;
      $scope.requestObj.city = $scope.city;
      $scope.requestObj.country = $scope.selectedCountry;
      $scope.requestObj.emailAddress = $scope.emailAddressSH;
      $scope.requestObj.langCode = $scope.languageCode;
      $scope.requestObj.securityAccountNumber = $scope.passwordSH;
      $scope.requestObj.intermediaryId = $scope.selectIntermediary;
      $scope.requestObj.contactPerson = $scope.repName;
      $scope.requestObj.legalEntityIdentifier = $scope.legalEntityId;
      $scope.requestObj.phoneNumber = $scope.mobilePhone;
      $scope.requestObj.recaptchaResponseToken = 'recaptcha-disabled';
      if (!$scope.recaptchaDisabled) {
        $scope.requestObj.recaptchaResponseToken = captchaService.getResponseToken();
      }

      shareHolderService.submitRegistration($scope.requestObj).then(
        function () {
          $scope.success = true;
          $scope.modalHeader = $filter('translate')(
            'label_shareHolderCreated_header'
          );
          $scope.modalBody = $filter('translate')('label_shareHolderCreated');
          ModelWindowService.showModelWindow('openSuccessModal');
          $timeout(function () {
            $scope.success = false;
          }, 10000);

          // reset the recaptcha
          if (!$scope.recaptchaDisabled) {
            $scope.c1.reset();
          }
        },
        function (err) {
          $scope.errorCode = err.data.errors[0].errorCode;

          // reset the recaptcha
          if (!$scope.recaptchaDisabled) {
            $scope.c1.reset();
          }

          if ($scope.errorCode === 'CBP_ERR_227') {
            $scope.timedout = $uibModal.open({
              templateUrl: 'success-dialog.html',
            });
            $timeout(function () {
              $scope.timedout.close();
              $scope.timedout = null;
              $state.go('shareholderlogin');
            }, 3000);
          } else if ($scope.errorCode === 'CBP_ERR_604') {
            $scope.recaptchaError = true;
          } else {
            $scope.error = true;
          }
          $timeout(function () {
            $scope.error = false;
            $scope.recaptchaError = false;
          }, 10000);
        }
      );
    };
  },
]);
