import { GM_CONTACT_EMAIL } from '../global/constants';

export default {
  label_hi: 'Hi ',
  label_generalMeeting1: 'General Meeting',
  label_generalMeetingName: 'General meeting name',
  label_issuer: 'Issuer',
  label_identificationNumber: 'Identification number',
  label_issuer_name: 'Issuer name',
  label_type: 'Type',
  label_genMeetingDate: 'General meeting date',
  label_requestSubmittedAt: 'Request submitted on',
  label_requestSubmittedBy: 'Request Submitted by',
  label_submittedBy: 'Submitted by',
  label_extendedIntermediaryDeadline: 'Intermediary deadline',
  label_new_end_date: 'New end date/time',
  label_meetingDate: 'Meeting date',
  label_status: 'Status',
  label_action: 'Action',
  label_advancedSearch: 'Advanced search',
  label_searchForShareholder: 'Search for Shareholder',
  label_cet: ' CET',
  label_vc_group_success: 'Display name saved successfully for given group',
  label_securityClass: 'Securities class',
  label_createGenMeeting: 'Create general meeting',
  label_createDWTevent: 'Create DWT event',
  label_editDWTevent: 'Edit DWT event',
  label_editGenMeeting: 'Edit general meeting',
  label_downloadRegFormTemplate: 'Download registration form template',
  label_extReq: 'Extension request',
  label_vc_alias_name_error:
    'Please ensure that display name in English language is entered',
  label_approveExtReqError:
    'Please enter a valid date. Maximum 5 hours of extension time is allowed and Extension Date cannot be greater than General Meeting Date',
  label_cancelExtnReq: 'Extension Request has been cancelled successfully',
  label_approveExtnReq: 'Extension Request has been approved successfully',
  label_rejectExtensionReq: 'Extension Request has been rejected successfully',
  label_rejectAllExtnRequests:
    'Extension Requests have been rejected successfully',
  label_notAllowedExtnReq: 'Issuer does not allow (new) extension requests',
  label_userMaintenance: 'User maintenance',
  label_auditAndTemplate: 'Audit and template',
  label_liveMeetingOverview: 'Live meetings overview',
  label_shareHolderEmail: 'Shareholder email',
  label_dwtEvents: 'DWT Events',
  label_entity: 'Entity',
  label_linkedIntMessage:
    'If needed intermediary is not listed, please contact Agent to onboard in the system.',
  label_linkedIntError:
    'The PoA for the selected intermediary has expired or is not yet valid, please contact Agent.',
  label_sharePlan_reinvite:
    'Invite share plan email will be sent to the selected share plan holders',
  label_publicationDate: 'Publication date',
  label_registrationDeadline: 'Registration deadline',
  label_registrationDeadlineDate: 'Registration deadline date',
  label_closingDate: 'Shareholder deadline date',
  label_intermediaryDeadline: 'Intermediary deadline',
  label_options: 'Actions',
  label_typeAndLocation: 'Type and location',
  label_schedule: 'Schedule',
  label_shareClasses: 'Share classes',
  label_agenda: 'Agenda',
  label_agenda_number: 'Agenda number',
  label_features: 'Features',
  label_documents: 'Documents',
  label_inviteSharePlan: 'Invite share plan',
  label_select: '--Select--',
  label_meetingType: 'Meeting type',
  label_meetingName: 'Meeting name',
  label_name: 'Name',
  label_corporateWebsite: 'Corporate website',
  label_website: 'Website',
  label_location: 'Location',
  label_locationName: 'Location name',
  label_locationOnTicket: 'Location on ticket',
  label_street: 'Street',
  label_postalCode: 'Postal code',
  label_country: 'Country',
  label_proxiesList: 'Clearing custodian',
  label_selectClearingIntermediary: '--Select clearing intermediary--',
  label_timeZone: 'Time zone',
  label_ibanNumber: 'IBAN number',
  label_directDebit: 'Direct debit',
  label_convocationDate: 'Convocation date',
  label_shareholderAccessDate: 'Shareholder access date',
  label_intermediaryAccessDate: 'Intermediary access date',
  label_recordDate: 'Record date',
  label_shareholderDeadlineDate: 'Deadline date',
  label_intermediaryDeadlineDate: 'Intermediary deadline date',
  label_regListReleaseDate: 'Registration list release date',
  label_noOfSecurities: 'Number of securities',
  label_generalMeetingDate: 'General meeting date',
  label_error_hoursAndMinutes:
    'Please enter hours from 0 to 23 and minutes from 0 to 59',
  label_prefixNumber: 'Prefix number',
  label_isin: 'ISIN',
  label_shareClassName: 'Share class name',
  label_intermediary: 'Intermediary',
  label_intermediary_and_csd: 'Intermediary and csd',
  label_csd: 'Central Securities Depository',
  label_shareholder: 'Shareholder',
  label_shareplanholder: 'Share plan holder',
  label_search: 'Search',
  label_additionalShareClass: 'Additional share class',
  label_additionalDocs: 'Additional documents',
  label_attachDocument: 'Attach document',
  label_browse: 'Browse',
  label_votable: 'Votable',
  label_agendaItem: 'Agenda item',
  label_agent: 'Agent ',
  label_addNewVoteCollector: 'Add new vote collector',
  label_addVoteCollector: 'Add vote collector',
  label_noDataFound: 'No data Found',
  label_login: 'Login',
  label_loginHeader: 'login',
  label_forgotPwrd: 'Password reset',
  label_gmNotice: 'GM notice',
  label_optOutGmNotice:
    'Select if you do not want to receive notification mails for General Meetings',
  label_optOutDwtNotice: 'Opt out of Tax Reclaim group emails',
  label_webaPage: 'Visible on relevant web pages',
  label_attendanceCard: 'Attendance card',
  label_previous: ' Previous',
  label_next: 'Next ',
  label_prev: 'Prev',
  label_first: 'First',
  label_last: 'Last',
  label_nl: 'NL',
  label_en: 'EN',
  label_fr: 'FR',
  label_es: 'ES',
  label_pipe: ' | ',
  label_colonStar: ':*',
  label_Star: '*',
  label_colon: ':',
  label_hash: '#',
  label_comma: ',',
  label_questionMark: '?',
  label_noDataMessage: 'No data available',
  label_agents: 'Agents',
  label_issuers: 'Issuers',
  label_approve: 'Approve',
  label_reject: 'Reject',
  label_rejectAll: 'Reject all',
  label_accept: 'Accept',
  label_confirmationMessage: 'Confirmation message',
  label_areYouSure: 'Are you sure you want to approve?',
  label_areYouSureCancel: 'Are you sure you want to cancel?',
  label_areYouSureSave: 'Are you sure you want to save?',
  label_yes: 'Yes',
  label_resend: 'Resend',
  label_no: 'No',
  label_save: 'Save',
  label_view: 'View',
  label_viewUsers: 'View users',
  label_edit: 'Edit',
  label_notification: 'Notification',
  label_notification_link: 'GM Notification Sheet',
  label_inviteShareplan: 'Invite share plan',
  label_resendInviteSharePlan: 'Resend invite share plan',
  label_add: 'Add ',
  label_city: 'City',
  label_logo: 'Logo',
  label_addNew: 'Add new',
  label_admin: 'Admin',
  label_phoneNumber: 'Phone number',
  label_personalPhoneNumber: 'Personal phone number',
  label_ok: 'Ok',
  label_groupMail: 'Opt-out for emails related to General Meetings',
  label_optOutTaxReclaim: 'Opt-out for emails related to Tax reclaim events',
  label_cancel: 'Cancel',
  label_confirm: 'Confirm',
  label_file: 'File',
  label_title: 'Title',
  label_firstName: 'First name',
  label_lastName: 'Last name',
  label_meetingTypeAGM: 'AGM',
  label_meetingTypeEGM: 'EGM',
  label_goBack: 'go back',
  label_home: 'Home',
  label_generalMeeting: 'General Meetings',
  label_DWT: 'DWT',
  label_shareholderID: 'Shareholder ID',
  label_corporateBroking: 'Corporate Broking',
  label_btnPending: 'Pending',
  label_btnAccepted: 'Accepted',
  label_btnApproved: 'Approved',
  label_btnRejected: 'Rejected',
  label_btnCancelled: 'Cancelled',
  label_requestCreatedAt: 'Request created on',
  label_nameBeneficialOwner: 'Name beneficial owner',
  label_shareholderPosition: 'Shareholder position',
  label_option: 'Option',
  label_delete: 'Delete',
  label_generalDetails: 'General details',
  label_registrations: 'Registrations',
  label_voteCollector: 'Vote collector',
  label_locationOnCertificate: 'Location on certificate',
  label_votingResult: 'Voting result',
  label_votingAvailable: 'Voting available',
  label_votingNotAvailable: 'Voting not available',
  label_votingItem: 'Voting item',
  label_defaultVotingOptions: '(for, against, abstain)',
  label_intermediaryWorkstation: 'Intermediary workstation',
  label_shareholderWorkstation: 'Shareholder workstation',
  label_SplitVotingRequired: 'Split voting required',
  label_disclosureOfUnderlyingBeneficialOwnersRequired:
    'Disclosure of underlying beneficial owners required',
  label_fillText:
    '* Fill out all fields in this page and click "Save" button to navigate to next section.',
  label_generalMeetingShouldBeVisible: 'General meeting should be visible',
  label_enableNewVotingResolution: 'Enable new voting resolution',
  label_agentLogoOnRegistrationTicket: 'Agent logo on registration ticket',
  label_issuerLogoOnRegistrationTicket: 'Issuer logo on registration ticket',
  label_emailAddress: 'Email address',
  genDetails_label2: 'Disclosure of underlying beneficial owners required',
  label_shareClass: 'Share class',
  label_companyName: 'Company name',
  label_company: 'Company',
  label_voteConfirmation: 'Vote confirmation',
  label_votingInstructions: 'Voting instructions',
  label_viewVotingInstructions: 'View voting instructions',
  label_viewSharePlanHolder: 'View Share plan holder',
  label_viewattendanceRequests: 'View attendance requests',
  label_voteRightsPerShare: 'Total # of shares',
  label_entitledVoteRightsShares: '# of shares entitled to vote',
  label_forAgainstVotingInstructions: 'For/Against Voting instruction',
  label_abstainedVotingInstructions: 'Abstained Voting instruction',
  label_shareplan: 'Share plan',
  label_votecollector: 'Vote collector',
  label_resend_selected: 'Resend Selected',
  label_securities: 'securities',
  label_instruction: 'instruction',
  label_attendanceRegistration: 'Attendance registration',
  label_votingInstruction: 'Voting instruction',
  label_oldPwrd: 'Old password',
  label_newPwrd: 'New password',
  label_reenterPwrd: 'Re-enter password',
  label_changePwrd: 'Change password',
  label_submit: 'Submit',
  label_pwrdRequirements: 'Password requirements',
  label_total: 'Total',
  label_download: 'Download',
  label_registrationListPDF: 'Registration list PDF',
  label_registrationListXLS: 'Registration list XLS',
  label_shareholdersAttendanceRegistration:
    'Shareholders requested for attendance registration',
  label_shareholdersVotingInstructions:
    'Shareholders requested for voting instructions',
  label_reinviteShareplan: 'View/Reinvite share plan holder',
  'label_%ofOutstandingVotingRights': '% of outstanding voting rights',
  label_attendanceRequest: 'Attendance request',
  label_noAction: 'No action',
  label_registrationTicketPreview: 'Registration ticket preview',
  label_downloadRegCertificate: 'Download all registration certificates',
  label_registrationCertificates: 'Registration certificates',
  label_createNewRegistration: 'Create new registration',
  label_downloadAll: 'Download all',
  label_uppercaseLetters: 'At least 1 uppercase letter',
  label_lowercaseLetters: 'At least 1 lowercase letter',
  label_numbers: 'At least 1 number',
  label_specialCharacters:
    "At least 1 special character ($ + , : ; = ? @ # | ' . ^ * ) ( % ! - ~ } { ] [ _ / )",
  label_8ormoreCharacters: 'Minimum 8 and maximum 20 characters',
  label_email: 'Email',
  label_addOtherLang: 'Add other language',
  label_important: 'Important',
  label_registrationTicketCreated: 'Registration ticket created',
  label_emailAuditTrail: 'Email Audit Trail',
  label_auditTrail: 'Audit Trail',
  label_templates: 'Templates',
  label_uploadTemplates: 'Upload templates',
  label_sentOutMails: 'Sent out mails',
  label_reset: 'Reset',
  label_securitiesHolder: 'Securities holder',
  label_registrationNumber: 'Registration number',
  label_proxyName: 'Proxy name',
  label_noOfShares: 'No of shares',
  label_fromDate: 'From date',
  label_toDate: 'To date',
  label_intermediaries: 'Intermediaries',
  label_proxy_intermediary: 'Received from',
  label_emailType: 'Email type',
  label_receiverEmailAddress: 'Receiver email address',
  label_subject: 'Subject',
  label_emailDetails: 'Email details',
  label_sentTo: 'Sent to',
  label_userName: 'User name',
  label_from: 'From',
  label_to: 'To',
  label_auditTrailDetails: 'Audit Trail Details',
  label_user: 'User',
  label_platform: 'Platform',
  label_Date: 'Date',
  label_templateType: 'Template type',
  label_subbjectOrDescription: 'Subject/Description',
  label_descriptionDetails: 'Description details',
  label_template: 'Template',
  label_language: 'Language',
  label_modified: 'Modified',
  label_sender: 'Sender',
  label_documentType: 'Document type',
  label_dwtBulkUpload: 'DWT bulk upload',
  label_bulkUpload: 'Bulk upload',
  label_bulkUploadVI: 'Bulk upload voting instructions',
  label_users: 'Users',
  label_role: 'Role',
  label_event: 'Event',
  label_eventName: 'Event name',
  label_dwtEvent: 'DWT envent',
  label_votingInvitationWithLinkToMeetings:
    'Voting invitation with link to meetings',
  label_sentDate: 'Sent date',
  label_newIntermediary: 'New Intermediary',
  label_intermediaryName: 'Intermediary name',
  label_intermediaryPhone: 'Intermediary phone number',
  label_intermediaryEmail: 'Intermediary email address',
  label_intermediaryContactName: 'Intermediary contact name',
  label_intermediaryGroupName: 'Intermediary group name',
  label_bic: 'BIC',
  label_registrationForm: 'Registration form',
  label_processVotingInstructions: 'Process voting instructions',
  label_receiveMeetingNotification: 'Receive meeting notification via Swift',
  label_processRegRequest: 'Process registration request',
  label_visibleInShareholderWorkstation: 'Visible in Shareholder workstation',
  label_CanProcessOnlyAssignedShareclasses:
    'Can process only assigned share classes',
  label_processCustomTemplate:
    'Process custom template for RC email to shareholder',
  label_addIssuer: 'Add Issuer',
  label_permissionsForms: 'Permissions / Registration forms',
  label_validFrom: 'Valid from',
  label_validTo: 'Valid to',
  label_openBracket: '(',
  label_closeBracket: ')',
  label_shareholderType: 'Shareholder type',
  label_selectShareholderType: '--Select Shareholder type--',
  label_initials: 'Initials',
  label_isAdmin: 'Is Admin',
  label_searchResults: 'Search results for',
  label_resetInstructionsSent: 'Reset instruction sent',
  label_resetPwrd: 'Reset password',
  label_checkEmail: 'Check your email',
  label_canProceedVotingInstructions: 'Can process voting instructons',
  label_addIntermediary: 'Add intermediary',
  label_addMoreIntermediary: 'Add more intermediary',
  label_addNewProxy: 'Add new proxy',
  label_thankYou: 'Thank you',
  forgotPassSuccess_label1:
    'The request for changing your password has been processed. If the username you entered exists, an email with instructions will be sent to you.',
  label_entityName: 'Entity name',
  label_actionType: 'Action type',
  label_sentDateGMT: 'Sent date and time',
  label_screenName: 'Screen name',
  label_templateName: 'Template name',
  label_workstation: 'Workstation',
  label_templateText: 'Template text',
  label_doNotPublish: 'Do not publish',
  genMeetRegistration_label1: 'Total number of outstanding voting rights',
  genDetails_label1:
    'Include meeting documents along with registration certificate',
  agent_home_welcomeText1: `Welcome to ABN AMRO's Corporate Broking Portal, where you can facilitate your general meetings and DWT processes.`,
  agent_home_welcomeText2: 'Please find below your ',
  label_actionList: 'action list.',
  createGenMeet_fillFormText: 'Fill form in',
  label_euroclear_shares: 'Euroclear shares',
  createGenMeet_draftSaveText: 'Draft automatically saved at',
  createGenMeet_shareClass_label1:
    'Total number of outstanding securities, entitled to vote',
  createGenMeet_shareClass_label2:
    'Vote rights per share(for this share class)',
  createGenMeet_shareClass_label3: 'Total number of voting rights',
  createGenMeet_shareClass_label10:
    'Total number of outstanding securities for Euroclear Nederland, entitled to vote',
  createGenMeet_shareClass_maxARAllowed:
    'Maximum number of attendance requests allowed',
  createGenMeet_vote_by_proxy: 'Vote through proxy',
  createGenMeet_vote_by_correspondence: 'Vote by correspondence',
  createGenMeet_shareClass_label5: 'Attendance request',
  createGenMeet_shareClass_label5_1: 'Virtual participation',
  createGenMeet_shareClass_label6: 'Registration list available via',
  createGenMeet_shareClass_label7: 'Vote collector workstation',
  createGenMeet_shareClass_label8: 'Authorized vote collectors',
  createGenMeet_shareClass_label9: 'Issuer',
  votingByCorrespondence: 'Voting by correspondence',
  virtualParticipation: 'Virtual participation',
  virtualParticipation_selection_warning: 'Please setup Virtual participation in Share Classes section before setting meeting Id and Provider ',
  createGenMeet_shareClass_agent_itself: 'Agent',
  createGenMeet_agenda_label1: 'Description (if applicable)',
  createGenMeet_agenda_label2: 'New venture approval',
  createGenMeet_agenda_label3: 'Add agenda item',
  createGenMeet_agenda_copy: 'Copy agenda items',
  createGenMeet_agenda_copy_tooltip:
    'Copies all agenda items to any other selected language',
  createGenMeet_doc_label1: 'Attach file to',
  createGenMeet_feature_label1: 'Intermediary workstations',
  createGenMeet_feature_label2: 'Split voting allowed',
  createGenMeet_feature_rightToSpeak:
    'Attendance request with right to speak allowed',
  createGenMeet_feature_label3: 'Shareholder workstations',
  createGenMeet_feature_label5: 'Split voting allowed',
  createGenMeet_feature_label6: 'Securities account number disclosure required',
  createGenMeet_feature_linkedSC:
    'Display message to vote/attend for the share class linked meeting',
  linkedSC_feature_tooltip:
    "Enabling this feature will alert shareholders to vote/attend for the meeting which has shared share class(ISIN). The message will be displayed in shareholder work station only if the current date is with in the range of other meeting's shareholder access and deadline dates",
  linkedMeeting_attend_label: 'To also attend the ',
  linkedMeeting_vote_label: 'To also vote for the ',
  label_plsClick_Yes_toContinue: 'please click on "Yes" to continue',
  label_registrationTicket: 'Registration ticket',
  createGenMeet_feature_label8: 'Include barcode on registration ticket',
  createGenMeet_feature_label9: 'Agent logo on attendance card',
  createGenMeet_feature_label10: 'Issuer logo on attendance card',
  createGenMeet_feature_label11:
    'Include blank power of attorney on registration ticket',
  createGenMeet_feature_label12:
    'Include meeting document along with registration certificate',
  virtualParticipation_allowed: 'Virtual participation allowed',
  only_9_12_alphanumeric_error: 'Please enter alphanumeric value of length 9 to 12 characters only',
  virtual_participation_provider_meeting_id: 'Meeting ID',
  virtual_participation_provider_info1:
    '- It should be 9 numerics without any special characters. example: 127909002.',
  virtual_participation_provider_info2:
    '- Please share the meeting id and the issuer details with the virtual participation provider once the meeting is successfully created.',
  createGenMeet_InviteSP_label1: 'From address',
  label_toAddress: 'To address',
  createGenMeet_InviteSP_label2: 'Email subject',
  createGenMeet_InviteSP_label3: 'Email body',
  createGenMeet_InviteSP_label4: ' Include uploaded files',
  createGenMeet_InviteSP_label5: 'No Files uploaded',
  createGenMeet_InviteSP_label6: 'Please confirm your registration',
  createGenMeet_InviteSP_label7: 'Step 1 ',
  createGenMeet_InviteSP_label8: 'Step 2 ',
  createGenMeet_InviteSP_download: 'Download Share Plan template',
  createGenMeet_InviteSP_upload: 'Upload Share Plan data',
  createGenMeet_InviteSP_placeholder1: 'corporate.broking.finance_dummy@nl.com',
  createGenMeet_InviteSP_placeholder2: 'Please confirm your registration',
  createGenMeet_InviteSP_tooltip1:
    'Template needs to be filled in with share plan data by issuer.',
  createGenMeet_InviteSP_tooltip2:
    'Template downloaded earlier needs to be uploaded with share plan data.',
  userMaint_voteCollector_label: 'Vote collector',
  userMaint_intermediary_label: 'Intermediary',
  userMaint_proxy_label: 'Proxy Intermediary',
  userMaint_groupDetails_label: 'Group details',
  userMaint_userAccounts_label: 'User accounts',
  userMaint_groupEmailAddress_label: 'Group email address',
  userMaint_bankIdentifierCode_label: 'Bank identifier code',
  userMaint_bankAccountNumber_label: 'Bank account number',
  userMaint_usersList_label: 'Users list',
  userMaint_officePhone_label: 'Office phone',
  userMaint_commentsForRejection_label: 'Comments for rejection',
  userMaint_issuer_warning_alreadyvc_label:
    'The provided e-mail is already registered as Vote Collector. Are you sure you want to add it as Issuer?',
  addUser_userAccounts_label: 'User account',
  forgotPass_label1: 'Reset instruction sent',
  forgotPass_label2:
    'Have you lost your password? You can request a new password. We will e-mail an activation link, and you can then enter a new password.',
  viewAttendance_label1:
    'Share holder name/Registration number/Securities account number',
  viewAttendance_label2: 'Shareholders registered list',
  viewVotingInstructions_label1: 'Securities holders registered list',
  searchSharePlanHolder_label: 'Securities holder name/Email',
  item0: 'Home',
  item1: 'General Meetings',
  item3: 'DWT',
  item4: 'Shareholder ID',
  item5: 'Corporate Broking',
  item2: 'My Account',
  button0: 'Draft',
  button1: 'Pending',
  button2: 'To be published',
  button3: 'Live',
  button4: 'Completed',
  button5: 'Cancelled',
  extnbtn0: 'Pending',
  extnbtn1: 'Accepted',
  extnbtn2: 'Rejected',
  label_votingConfirmation: 'Voting confirmation',
  label_votingInstructionReport: 'Voting instruction reports',
  label_downloadPDF: 'Download PDF',
  label_downloadExcel: 'Download Excel',
  label_summary: 'Summary',
  label_myVotes: 'My votes/registrations',
  label_Details: 'Details',
  label_inFavor: 'In favor',
  label_against: 'Against',
  label_Abstained: 'Abstained',
  label_addAttachments: 'Add attachments',
  label_addText: 'Add text',
  label_send: 'Send',
  adminAgent_label_profile: 'Profile',
  adminAgent_label_changePwrd: 'Change password',
  adminAgent_label_initials: 'Initials',
  adminAgent_label_middleName: 'Middle name',
  adminAgent_label_phoneNumber: 'Phone number',
  adminAgent_label_pwrdRequirements: 'Password requirements',
  adminAgent_label_confirmation: 'Confirmation message',
  adminAgent_label_wantToSave: 'Are you sure you want to save?',
  gmNotification_label_1: 'Notification overview',
  gmNotification_label_2: 'Attachments to notification',
  gmNotification_label_3: 'Customise message',
  gmNotification_label_4: 'Meeting notice',
  gmNotification_label_5: 'Recipient list',
  label_recipientAddress: 'Recipient address',
  gmNotification_custMsg1: 'Available means of participation',
  gmNotification_custMsg2:
    'Additional information for notification of participation',
  gmNotification_custMsg3: 'Narrative',
  gmNotification_custMsg4:
    'Other requirements for participation (if applicable)',
  voting_result: 'Voting results',
  select_recipient: '--Select recipient--',
  select_shareClass: '--Select Share Class--',
  label_selectProxyType: '--Select proxy type--',
  label_selectCountry: '--Select country--',
  label_selectUserType: '--Select user type--',
  label_selectTitle: '--Select title--',
  label_selectIntermediary: '--Select Intermediary--',
  gmGeneralDetails_label: 'Shareholder',
  label_serialNo: 'Sl No.',
  label_error_lastNameReq: 'Last name is required.',
  label_error_lastNameWrong: 'Last name is wrong.',
  label_error_officeNumReq: 'Office phone number is required.',
  label_error_officeNumWrong: 'Office phone number is wrong',
  label_error_phoneNumReq: 'Phone number is required',
  label_error_persoNumWrong: 'Personal phone number is wrong',
  label_error_phoneNumWrong:
    'Phone number is too short or not matching the format',
  label_error_emailReq: 'Email is required.',
  label_error_emailWrong:
    'Email is either too short or not matching the format.',
  label_error_name: 'Name is required.',
  label_error_nameWrong: 'Name is invalid.',
  label_error_tinWrong:
    'TIN/RSIN is invalid. Minimum 2 valid characters are expected',
  label_error_postWrong: 'Postal code is wrong.',
  label_error_cityReq: 'City is required.',
  label_error_cityWrong:
    'City is invalid. Minimum 2 valid characters are expected',
  label_error_noOfSharesReq: 'Number of Shares is required',
  label_error_noOfSharesWrong: 'Number of Shares is wrong',
  label_error_selectedQuantityNoOfSharesReq: 'Partial Holding is required',
  label_error_selectedQuantityNoOfSharesWrong: 'Partial Holding is wrong',
  label_error_addressWrong: 'Address is wrong.',
  label_error_securityAccNoReq: 'Securities account number is required.',
  label_error_securityAccNoWrong:
    'When entering a securities account number only numbers and letters may be used',
  label_error_countryReq: 'Country is required.',
  label_error_countryWrong: 'Country is wrong.',
  label_error_bicReq: 'Bank Identifier Code is required.',
  label_error_bicWrong: 'Bank Identifier Code is wrong.',
  label_error_legalIndentifierWrong: 'Legal entity identifier is wrong.',
  label_error_repNameWrong: 'Representative name is wrong.',
  label_error_proxyNameWrong: 'Proxy name is wrong.',
  label_error_proxyNameReq: 'Proxy name is required.',
  label_poaForm: 'PoA form',
  linkIntermediary_header1:
    'Intermediaries for Institutional Shareholder Services (ISS) Europe',
  linkIntermediary_header2:
    'These are the intermediaries for which Institutional Shareholder Services (ISS) Europe can process regulations',
  linkIntermediary_button1: 'Link to Intermediary group',
  addPermission_header: 'Permissions/Registration form',
  label_error_middleNameWrong: 'Middle name is wrong.',
  label_error_firstNameWrong: 'First name is wrong.',
  label_error_initialsWrong: 'Initials are wrong.',
  label_error_titleWrong: 'Title is wrong.',
  label_error_titleReq: 'Title is required.',
  voteCollectorbutton0: 'Before deadline',
  voteCollectorbutton1: 'After deadline',
  voteCollectorbutton2: 'Completed',
  label_userType: 'User type',
  label_address: 'Address',
  label_dob: 'Date of birth',
  label_securityAccountNumber: 'Securities account',
  label_numberOfShares: 'Number of shares',
  label_attendByProxy: 'Attend by proxy',
  label_createNewProxy: 'Create new proxy',
  label_editProxy: 'Edit proxy',
  label_selectProxy: 'Select proxy',
  label_delegateToSaat: 'Delegate to SAAT',
  label_attendOrBlankVoteLine1: 'Attend meeting in person',
  label_attendOrBlankVoteLine2: 'or give a blank proxy to SAAT',
  label_proxyType: 'Proxy type',
  label_proxyId: 'Proxy id',
  label_none: 'None',
  label_vote: 'Give voting instructions',
  label_register: 'New user registration',
  label_allFor: 'All for',
  label_splitVotes: 'Split votes',
  label_entireHolding: 'Entire holding',
  label_selectedQuantity: 'Selected quantity',
  label_partialHolding: 'Partial holding',
  label_for: 'For',
  label_enterNumberOfShares: 'Enter number of shares',
  label_allAgainst: 'All against',
  label_allAbstained: 'All abstained',
  label_attend: 'Attend meeting in person',
  label_downloadCertificate: 'Download certificate',
  label_upComingMeetings: 'Upcoming General Meetings',
  label_representativeName: 'Representative name',
  label_legalEntityIdentifier: 'Legal entity identifier',
  label_termsAndConditions: 'Terms and conditions',
  label_terms: 'terms and conditions',
  label_termsUse: 'Terms of Use',
  label_numberOfSecurities: 'Number of securities',
  label_attendanceInfo:
    'The following voting instruction will be automatically cancelled when attendance request is submitted',
  label_votingInfo:
    'The following registrations will be automatically cancelled when voting instruction is submitted',
  label_termsSentence: 'I have read and accepted the ',
  label_belgiumTermsConditions1:
    'I/we hereby confirm that I/we have read, understood and consented to the ',
  label_belgiumTermsConditions2:
    'as well as having saved, printed or sent myself/ourselves a copy thereof.',
  label_votingViaIMWorkStation:
    'Voting instructions via the intermediary workstation',
  label_votingViaSHWorkStation:
    'Voting instructions via the shareholder workstation',
  label_requestRegistrationViaSHWorkStation:
    'Request a registration ticket via the shareholder workstation',
  label_groupName: 'Group name',
  label_displayName: 'Display name',
  label_groupCountry: 'Group country',
  label_groupRegisteredOffice: 'Group registered office',
  label_internationalBankAccountNumber: 'International bank account number',
  label_taxDeclarationNumber: 'Tax declaration number',
  label_uploadedDocuments: 'Uploaded documents',
  label_groupStatus: 'Group status',
  label_groupCity: 'Group city',
  notification_text:
    'Dear Shareholder, in order to provide a better service, please ensure that your details are up to date.',
  label_companiesOfferingGm:
    "Companies using ABN AMRO's shareholder portal are:",
  label_seeMore: 'See more',
  label_knowMore: 'Know more',
  label_error_groupName: 'Group name is wrong',
  label_error_groupNameReq: 'Group name is required',
  label_pleaseFillThisField: 'Please fill this field',
  label_proxyProvided: 'Proxy provided',
  label_proxyProfile: 'Proxy profile',
  label_noDataIssuerMessage: 'No data available for issuer',
  label_feedback: 'Feedback',
  label_extensionRequestFor: 'Extension Request for',
  label_shareHolderName: 'Shareholder name',
  label_extendDeadlineTillDate: 'Extend deadline till date',
  label_reasonForDelay: 'Reason for delay',
  label_reasonForReject: 'Reason for rejection',
  label_time: 'Time',
  label_createExtnReq: 'Create extension request',
  label_viewEditExtnReq: 'View/Edit Extension request',
  label_viewExtnReq: 'View extension requests',
  label_tillDateReq: 'Extend deadline till date is required',
  label_shareholderNameReq: 'Shareholder name is required',
  label_shareholderPosReq: 'Shareholder position is required',
  label_shareholderPosError: 'Shareholder position is wrong',
  label_shareholderPosIncorrect: 'Shareholder position can be 1 to 999999999',
  label_attachment: 'Attachment',
  label_importFile: 'Import file',
  label_downloadStandardFile: 'Download standard file',
  label_bulkUploadAttendanceRequest: 'Bulk upload attendance request',
  label_MR: 'Mr.',
  label_MS: 'Ms.',
  label_voting_instruction: 'Voting instructions',
  label_attendance_requests: 'Attendance requests',
  label_invite_share_plan: 'Invite share plan',
  label_shareholder_email: 'Shareholder email',
  label_intermediary_email: 'Intermediary email',
  label_notification_overview: 'Notification Overview',
  label_create_extension_request: 'Create Extension Request',
  label_bulk_upload_attendance_requests: 'Bulk upload attendance request',
  label_bulk_upload_voting_instructions: 'Bulk upload voting instructions',
  label_view_attendance_requests: 'View attendance requests',
  label_view_agent_group: 'View agent group',
  label_edit_agent_group: 'Edit agent group',
  label_edit_issuer_details: 'Edit issuer details',
  label_edit_intermediary_details: 'Edit intermediary details',
  label_edit_proxy_intermediary_details: 'Edit proxy intermediary details',
  label_edit_votecollector_details: 'Edit vote collector details',
  label_agent_group: 'Agent users',
  label_view_agent_group_users: 'View agent users',
  label_add_user: 'Add user',
  label_add_issuer: 'Add issuer',
  label_add_intermediary: 'Add intermediary',
  label_add_proxy_user: 'Add proxy user',
  label_add_vote_collector: 'Add vote collector',
  label_view_agent_details: 'View agent details',
  label_edit_agent_details: 'Edit user details',
  label_edit_vote_collector_details: 'Edit vote collector details',
  label_view_issuer_group: 'Issuer groups',
  label_view_vote_collector_group: 'Vote collector groups',
  label_view_intermediary_group: 'Intermediary groups',
  label_view_proxy_intermediary_group: 'Proxy intermediary groups',
  label_add_issuer_group: 'Add issuer group',
  label_add_vote_collector_group: 'Add vote collector group',
  label_add_intermediary_group: 'Add intermediary group',
  label_add_proxy_intermediary_group: 'Add proxy intermediary group',
  label_view_intermediary_user: 'View intermediary users',
  label_view_proxy_intermediary_user: 'View proxy intermediary users',
  label_view_issuer_user: 'View issuer users',
  label_view_vote_collector_user: 'View vote collector users',
  label_view_issuer_details: 'View issuer details',
  label_view_intermediary_details: 'View intermediary details',
  label_view_proxy_intermediary_details: 'View proxy intermediary details',
  label_view_vote_collector_details: 'View vote collector details',
  label_add_permissions: 'Add permissions',
  label_link_intermediaries: 'Linked intermediaries',
  label_link_intermediary: 'Link to Intermediary',
  label_page_title: 'Corporate Broking Portal',
  label_logoUpdate: 'Update logo',
  label_extededTill: 'Intermediary deadline extended till',
  label_extendedIntermediary: 'Extended Intermediary deadline',
  label_dwt_extededTill: 'Deadline date extension till',
  label_reason: 'Reason for extension',
  label_reasonForExtension: 'Reason for extension',
  label_privacy_policy: 'Privacy and cookie policy',
  label_privacy: 'Privacy',
  label_cookies: 'Cookies',
  label_contact_details: 'Contact details',
  label_numberOfSharesRequired: 'Number of shares is required',
  label_numberOfSharesWrong:
    'Number of shares should be numbers and should not start with zero',
  label_addProxy: '+ Add Proxy',
  label_mandatoryFieldError: 'Please fill the mandatory fields',
  label_textRequired: 'Text is required',
  label_pwrdReq: 'Password required',
  newPwd_oldPwdError: 'New password cannot be the same as old password',
  checkPattern_Error: 'Please check the pattern',
  label_noProxyAvailable: 'No proxy available',
  label_close: 'Close',
  label_feedbackType: 'Feedback type',
  label_feedbackComment: 'Comments',
  label_feedback_question: 'Question',
  label_feedback_opinion: 'Suggestion',
  label_feedback_comment_required: 'Feedback comment is required.',
  label_feedback_comment_maxlength: 'Max length is 4000 characters.',
  label_reason_comment_required: 'Reason comment is required.',
  label_reason_comment_maxlength: 'Max length is 2000 characters.',
  label_phone: 'Phone',
  label_show_details: 'Show Details',
  label_hide_details: 'Hide Details',
  thankYou_msg: 'Your vote is important.Thank you for your voting instruction.',
  thankYou_msg1:
    'If you wish to change your vote, you can do this upto shareholder deadline date of the general meeting.',
  thankYou_msgButton1: 'Vote for other Share classes for this GM',
  thankYou_msgButton2: 'Browse and vote for GMs from other Issuers',
  notificationModal_header: 'Save Customised Message',
  notificationModal_body:
    'Do you want to retain the modified text for future use? (applies to all Issuers)',
  notificationModal_rightButton: 'No,for this instance only',
  notification_successMsg1: 'Message saved successfully for future use',
  notification_successMsg2: 'Message saved successfully for only this instance',
  notification_successMsg3: 'Notification sent successfully',
  label_shareHolderCreated:
    'Your registration is completed. Please check your email to activate your account.',
  label_shareHolderCreated_header: 'Registration completed',
  label_shareholderCreationError:
    'Some error has occured while creating Shareholder, Please contact system administration',
  label_agendaDeleted: 'Agenda deleted successfully.',
  label_agendaItemsCopied:
    'Agenda items successfully copied to other language(s).',
  label_documentDeleted: 'Document deleted successfully.',
  label_shareClassDeleted: 'Share class deleted successfully.',
  label_messageSaved: 'Meeting saved successfully.',
  label_createMeetingError:
    'Something went wrong while creating meeting. Please try after sometime',
  label_fillMandatoryFields:
    'Please fill mandatory fields to save this section',
  label_fillValidInput:
    'Please fill mandatory fields with valid input and try again.',
  label_attachmentRequired:
    'Attachment cannot be blank, Please upload a pdf file',
  label_documentLessThan5mb: 'Document should be less than 5mb',
  label_documentNamelength: 'Document name should be less than 200 characters',
  label_meetingSentToIssuer: 'Meeting sent to Issuer',
  label_meetingApproved: 'Meeting approved successfully',
  label_agendaRequired: 'Please fill agenda section',
  label_docRequired: 'Please fill document section.',
  label_shareClassRequired: 'Please fill share class section.',
  label_create_extn_error:
    'Extend deadline till date must be greater than intermediary deadline date and less than general meeting date.',
  label_vote_collector_group: 'Vote collector group',
  label_modalConfirmAction: 'Confirm action',
  label_modalVotingInsCreatErr: 'Voting is not available',
  label_modalVotingInsCreatErrMsg:
    'Voting instructions cannot be created for this General Meeting since the intermediary deadline date is already expired',
  label_modalBulkUploadCreateErrMsg:
    'Bulk upload or upload BO details is not possible for this General Meeting as the intermediary deadline date is already crossed',
  label_modalBulkUploadCreatErrMsg:
    'Bulk upload or upload BO details is not possible for this General Meeting as voting instruction creation is not enabled',
  label_modalBulkUploadAttendCreateErrMsg:
    'Bulk upload or upload BO details is not possible for this General Meeting as the intermediary deadline date is already crossed',
  label_modalBulkUploadAttendCreatErrMsg:
    'Bulk upload or upload BO details is not possible for this General Meeting as attendance request creation is not enabled',
  label_modalShareVotingInsCreatErrMsg:
    'Voting instruction is not enabled for this meeting',
  label_modalShareAttenReqCreatErrMsg:
    'Attendance request is not enabled for this meeting',
  label_modalAttReqCreatErr: 'Attendance request is not available',
  label_modalAttReqCreatErrMsg:
    'You cannot create an attendance request for this General Meeting since the intermediary deadline date is already expired',
  label_modalVirtualReqCreateErr: 'Cannot create virtual participation',
  label_modalVirtualReqNoEntitlements:
    'Not entitlements found for this meeting',
  label_modalVirtualReqErrMsg:
    'You cannot update virtual participation request for this General Meeting since the intermediary deadline date is already expired',
  label_modalRtsCreatErrMsg:
    'You cannot create/edit right to speak request for this General Meeting since the intermediary deadline date is already expired',
  label_modalRtsCancelErrMsg:
    'You cannot cancel right to speak request for this General Meeting since the intermediary deadline date is already expired',
  label_modalDelete: 'Delete message',
  label_modalConfirmDelTemplate:
    'Are you sure you want to delete this template?',
  label_modalUpdateTemp: 'Update template',
  label_modalConfirmUpdateTemplate:
    'Are you sure you want to update this template?',
  label_shareHolderRegistration: 'Shareholder registration',
  label_registrationCertificate: 'Registration certificate',
  label_request_type: 'Request type',
  lable_newReenterError: 'New password and re-entered password should match',
  issuer_groupUpdated_success: 'Issuer group details updated successfully',
  label_votingInsCreatErr: 'Information',
  label_bulkUploadCreatErr: 'Upload error',
  error_shareClass_not_created:
    'Something went wrong while saving share class. Please try after sometime.',
  error_shareClass_not_created_invalid_data:
    'Please fill mandatory fields with valid information',
  error_issuer_votecollector_not_selected:
      'Please Select Issuer Vote collector if Vote by Correspondence is selected',
  error_shareClass_not_created_invalid_participation_data:
      'Invalid Participation method selection, Please fill valid information',
  only_pdf_allowed: 'Invalid document(upload valid pdf file).',
  agenda_file_sizeMsg: 'This agenda file size is',
  file_sizeMsg: ' MB. Please upload a file less than 5 MB.',
  document_file_sizeMsg: 'This document size is',
  error_send_to_issuer:
    'There are errors. Please verify if all the sections are filled with valid information',
  newPwd_requiredError: 'New password required',
  reEnter_requiredError: 'Re-enter password required',
  numAlpha_error: 'Only alphabets and numbers are allowed',
  password_renterMatchError:
    'Both New password and Re enter password must be same',
  label_bulkUploadBO: 'Upload BO Details',
  label_registration_no: 'Registration Number',
  label__error_registrationNoWrong: 'Registration Number is wrong',
  label__error_registrationNoReq: 'Registration Number is required',
  deadLineVoteModal_header: 'Voting is not available',
  deadLineModal_body:
    'An Attendance request is not possible for this meeting since the deadline is expired.',
  deadLineModalVI_body:
    'Voting instructions cannot be submitted for this meeting since the deadline is expired.',
  deadLineAttendModal_header: 'Attendance request not available',
  lable_nonVotableItem: 'Non-votable item',
  share_class_error_checkbox_not_selected:
    'At least one option among Voting instructions or Request an attendance card must be selected.',
  document_checkbox_not_selected:
    'Please check atleast one checkbox - Attach the file to',
  noOfShares_error1:
    'Split votes should not be greater than Total Number of Shares',
  noOfShares_error2:
    'Sum of Split votes should be equal to Total Number of Shares',
  noOfShares_error3:
    'Partial quantity cannot be greater than Total No of shares',
  votingInstruction_error_msg: 'Please vote for all agenda items',
  'intermediary-success-msg': 'Intermediary request created successfully',
  reject_meetingErrorr:
    'Something went wrong while rejecting meeting. Please try after sometime',
  approve_meetingErrorr:
    'Something went wrong while approving meeting. Please try after sometime',
  reject_meeting: 'Meeting rejected successfully.',
  approve_meeting: 'Meeting approved successfully.',
  label_loginOrRegister: 'Login or Register',
  label_downloadAttachment: 'Download attachment',
  label_selectActionType: '--Select action type--',
  label_fields: 'Fields',
  label_beforeValue: 'Before value',
  label_afterValue: 'After value',
  label_forWord: 'For',
  label_selectTemplateType: '--Select template type--',
  label_selectTemplateName: '--Select template Name--',
  label_selectWorkstation: '--Select Workstation--',
  label_selectIssuer: '--Select issuer--',
  label_selectIsin: '--Select ISIN--',
  label_selectScreenName: '--Select screen name--',
  label_saveSuccessfullyStatus: 'Invite share plan sent successfully',
  label_errorImgFile: 'Please upload an image file.',
  label_errorFileSize: 'Please upload a file less than 250KB.',
  pwdChanged_successMsg: 'Password changed successfully',
  extnReq_cannotCreate: 'Extension request cannot be created for this meeting',
  vec_initiationMsg:
    'Processing confirmation, an e-mail will be sent when finished',
  selectDate_error: 'Please select date fields',
  fileSize_1mbError: 'Please upload a pdf file (Max Size 1 MB)',
  selectToDate_error: 'Please select to date which is greater than from date',
  selectRegForm_error: 'Please select Registration Form',
  label_areYouSureModal: 'Are you sure you want to ',
  label_thisRecord: ' this record?',
  label_shareHolder_registered: 'Shareholders registered by ',
  label_for_shareholder: 'for',
  label_toggle: 'Toggle navigation',
  uploadFile_error: 'Please upload a file.',
  fileSizeIs_error: 'This file size is',
  confrim_delink: 'Confirm de-link.',
  delink_confirmMsg: 'Are you sure you want to de-link this intermediary?',
  label_thisGroup: ' this group?',
  label_thisUser: ' this user?',
  label_enterUserNameToPasswordReset:
    'Please enter your email address to initiate password reset',
  label_enterValidCredentials: 'Please enter valid credentials',
  label_emailEmptyError: 'Please fill in email body',
  label_acceptExtnReqConfirm: 'Are you sure you want to approve it?',
  label_rejectExtnReqConfirm: 'Are you sure you want to reject it?',
  label_acceptExtnReq:
    'This request was rejected before, are you sure you want to approve it?',
  label_rejectExtnReq:
    'This request was approved before, are you sure you want to reject it?',
  label_section_error: 'There are errors for ',
  label_section_language: 'language(s)',
  areSureDelete_modal: 'Are you sure you want to delete this Meeting?',
  areSureCancel_modal: 'Are you sure you want to cancel this Meeting?',
  areSureReject_modal: 'Are you sure you want to reject this Meeting?',
  createGenMeet_IntermediaryTooltip:
    'Select an intermediary, ADR or transfer agent which is only responsible to process voting instructions/ registration to attend for this share class. Please select all intermediaries if this is not applicable.',
  issuer_name_req: 'Issuer name is required.',
  fill_issuer: 'Please fill out Issuer name.',
  meeting_type_req: 'Meeting type is required.',
  fill_meetingType: 'Please fill out meeting type.',
  meeting_name_req: 'Meeting name is required.',
  invalid_meetingName: 'Meeting name field accepts alphabets and numbers.',
  fill_meetingName: 'Please fill out meeting name.',
  website_req: 'Corporate website is required.',
  website_wrong: 'Corporate website is wrong.',
  fill_website: 'Please fill out corporate website.',
  eng: 'English',
  dut: 'Dutch',
  fre: 'French',
  ger: 'German',
  esp: 'Spanish',
  location_req: 'Location name is required.',
  location_wrong: 'Location name is wrong.',
  fill_location: 'Please fill out location name.',
  loc_tckt_req: 'Location on ticket is required.',
  loc_tckt_wrong: 'Location on ticket is wrong.',
  fill_loc_tckt: 'Please fill out location on ticket.',
  street_req: 'Street is required.',
  fill_street: 'Please fill out street.',
  postal_req: 'Postal code is required.',
  postal_wrong: 'Postal code is wrong.',
  fill_postal: 'Please fill out postal code.',
  city_req: 'City is required.',
  fill_city: 'Please fill out city.',
  country_req: 'Country name is required.',
  country_fill: 'Please fill out country name.',
  label_current: 'Current',
  label_updateStatus: 'Update status',
  /* create/edit DWT */
  label_currency: 'Currency',
  label_dividendType: 'Dividend Type',
  label_enablePEReclaim: 'Enable PE reclaim',
  label_enableDirectDebitOfQRF: 'Enable direct debit of QRF claims',
  label_IBANDirectDebitOfQRF: 'IBAN for direct debit of QRF claims',
  label_enableDirectDebitOfPE: 'Enable direct debit of PE claims',
  label_IBANDirectDebitOfPE: 'IBAN for direct debit of PE claims',
  label_error_IBANDirectDebitOfQRFReg:
    'IBAN for Direct Debit of QRF claims is required',
  label_error_IBANDirectDebitOfQRFWrong:
    'IBAN for Direct Debit of QRF claims is invalid',
  label_error_IBANDirectDebitOfPEReg:
    'IBAN for Direct Debit of PE claims is required',
  label_error_IBANDirectDebitOfPEWrong:
    'IBAN for Direct Debit of PE claims is invalid',
  label_dividendRecordDate: 'Dividend record date',
  label_dividendPaymentDate: 'Dividend payment date',
  label_error_dividendRecordDateReg: 'Dividend record date is required',
  label_error_dividendRecordDateWrong:
    'Dividend record date format should be like 01-April-2017',
  label_error_dividendPaymentDateReg: 'Dividend payment date is required',
  label_error_dividendPaymentDateWrong:
    'Dividend payment date format should be like 01-April-2017',
  label_QRFStartDate: 'QRF start date',
  label_QRFEndDate: 'QRF end date',
  label_PEStartDate: 'PE start date',
  label_PEEndDate: 'PE end date',
  label_error_QRFStartDateReq: 'QRF start date is required',
  label_error_QRFStartDateWrong:
    'QRF start date format should be like 01-April-2017',
  label_error_QRFEndDateReq: 'QRF end date is required',
  label_error_QRFEndDateWrong:
    'QRF end date format should be like 01-April-2017',
  label_error_PEStartDateReq: 'PE start date is required',
  label_error_PEStartDateWrong:
    'PE start date format should be like 01-April-2017',
  label_error_PEEndDateReq: 'PE end date is required',
  label_error_PEEndDateWrong: 'PE end date format should be like 01-April-2017',
  label_error_dateOfSubmissionReq:
    'Date of submission to tax authority is required',
  label_error_dateOfSubmissionWrong:
    'Date of submission to tax authority format should be like 01-April-2017',
  label_dateOfSubmission: 'Date of submission to tax authority',
  label_dwtLandingPage: 'DWT reclaim events',
  label_createDWT: 'Create DWT event',
  label_DWTPercentagePerSecurity: 'DWT % per security',
  label_DWTperSecurity: 'DWT per security',
  label_dividendPerSecurityGross: 'Dividend per security (gross)',
  label_dividendPerSecurityNet: 'Dividend per security (net)',
  label_nominalWithholdingTaxPerShare: 'Nominal withholding tax per share',
  label_error_DWTPercentagePerSecurityReq: 'DWT % per security is required',
  label_error_DWTPercentagePerSecurityWrong: 'DWT % per security is invalid',
  label_error_DWTperSecurityReq: 'DWT per security is required',
  label_error_DWTperSecurityWrong:
    'Enter numeric value. Maximum 10 digits allowed after decimal.',
  label_error_dividendPerSecurityGrossReq:
    'Dividend per security (gross) is required',
  label_error_dividendPerSecurityGrossWrong:
    'Dividend per security (gross) is invalid',
  label_error_dividendPerSecurityNetReq:
    'Dividend per security (net) is required',
  label_error_dividendPerSecurityNetWrong:
    'Enter numeric value. Maximum 10 digits allowed after decimal.',
  label_error_nominalWithholdingTaxPerShareReq:
    'Nominal withholding tax per share is required',
  label_error_nominalWithholdingTaxPerShareWrong:
    'Nominal withholding tax per share is invalid',
  label_error_tdNumberReg: 'Tax declaration Number is required',
  label_error_tdNumberWrong: 'Tax declaration Number is invalid',
  label_dwtEventDetails: 'DWT event details',
  label_dwtEventClaims: 'DWT claim details',
  label_dwtPaymentDetails: 'DWT payment details',
  sendEmail_taxAuthority: 'Send email to tax authority',
  label_taxReclaim: 'Tax Reclaim',
  label_error_eventName: 'Event name is required',
  label_error_eventNameWrong: 'Event name is invalid',
  label_dividend: 'Dividend',
  label_sendToIssuer: 'Send to Issuer',
  label_error_dwtPaymentDate:
    'Dividend payment date should be greater than Dividend record date',
  label_error_dwtQRFStartDate:
    'QRF start date should be greater than Dividend record date',
  label_error_dwtQRFEndDate1:
    'QRF end date should be greater than QRF start date',
  label_error_dwtQRFEndDate2:
    'QRF end date should be greater than Dividend record date',
  label_error_dwtQRFEndDate3:
    'QRF end date should be greater than Dividend payment date',
  label_error_dwtPEStartDate:
    'PE Start date should be greater than Dividend record date',
  label_error_dwtPEEndDate1: 'PE end date should be greater than PE start date',
  label_error_dwtPEEndDate2:
    'PE end date should be greater than Dividend record date',
  label_error_dwtPEEndDate3:
    'PE end date should be greater than Dividend payment date',
  label_error_dwtTaxSubmissionDate:
    'Date of submission to tax authority should be greater than PE/QRF end date',
  label_dwtReq: 'Delete request',
  label_dwtCancelReq: 'Cancel request',
  label_dwtModalConfirmDelTemplate:
    'Are you sure you want to delete this draft event?',
  label_dwtModalConfirmCancelTemplate:
    'Are you sure you want to cancel this event?',
  label_createDwttooltip1:
    'The tax declaration number (length 20) has the structure:',
  label_createDwttooltip2:
    '- position 1 - 9: RSIN/fiscal identification nr of the issuer.',
  label_createDwttooltip3:
    '- position 10 - 12: standard the following 3 characteristics:"DIB"',
  label_createDwttooltip4:
    '- position 13 - 16: year of which the dividend is payable',
  label_createDwttooltip5:
    '- position 17 - 18: month of which the dividend is payable',
  label_createDwttooltip6:
    '- position 19 - 20: prefix number, this number depends on the number of tax declarations in one month. 01 for the first submission in the relevant month, 02 for the first submission in the relevant month.',
  label_createReqModalSendToIssuer: 'Send to Issuer',
  label_createReqModalApprove: 'Approve Request',
  label_dwtModalConfirmCreateTemplate:
    'Are you sure you want to send to issuer for approval?',
  label_dwtModalApproveCreateTemplate: 'Are you sure you want to approve?',
  label_createDwtIssuerNameReq:
    'Please select the mandate field - Issuer Name to save the DWT event',
  label_createDwtSubmitErr: 'Please fill valid inputs and proceed with save',
  label_createDwtShareClassNameReq: 'New securities class name is mandatory',
  label_createDwtNewIsinReq: 'New ISIN is mandatory',
  label_createDwtNewIsinAndShareClassReq:
    'New ISIN and New securities class name is mandatory',
  label_securityClassName: 'Securities class name',
  label_fillMandatoryInput:
    'Please fill mandatory fields (*) with valid input and try again.',
  label_saveAsDraft: 'Save as Draft',
  label_issuer_comment: 'Issuer additional comments',
  label_not_listed: '-Not listed-',
  label_addNewISIN: 'Add a new ISIN',
  label_new_isin: 'New ISIN',
  label_error_newISINReg: 'ISIN is required',
  label_error_newISINWrong:
    'ISIN should be of the format - Country code, 9 digits-identifier,1 digit-single check-digit.',
  label_new_securityClassName: 'New Security class name',
  label_error_newSecurityClassNameReg: 'Security class name is required',
  label_error_newSecurityClassNameWrong: 'Security class name is invalid',
  label_error_pastDate:
    'Date less than or equal to the current date is not recommended',
  label_toBeAuthorized_PE_error:
    'There are no participation exemption claims to be authorized.',
  label_toBeAuthorized_QRF_error:
    'There are no Quick refund claims to be authorized.',
  label_dividendPercentMaxError:
    'Please enter a value less than or equal to 100. Maximum 5 digits allowed after decimal.',
  label_dividendGrossMaxError:
    'Please enter a valid number less than or equal to 1000. Maximum 10 digits allowed after decimal.',
  /* Start : UM For DWT */
  label_direct_debit_enabled: 'Direct debit enabled',
  label_error_postalcodeReq: 'Postal code is required',
  label_error_addressReq: 'Address is required',
  label_iban_dd_qrf: 'IBAN for direct debit of QRF claims',
  label_iban_dd_pe: 'IBAN for direct debit of PE claims',
  label_rsin_fiscal: 'RSIN/Fiscal ID',
  label_upload_agree_form: 'Agreement form',
  label_valid_from: 'Agreement form valid from',
  label_valid_till: 'Agreement form valid till',
  label_pay_ref: 'Payment reference',
  label_error_rsinFiscalReq: 'RSIN/Fiscal ID is required',
  label_error_rsinFiscalWrong: 'RSIN/Fiscal ID is invalid',
  label_normal: 'Normal',
  label_view_only: 'View only',
  label_error_ibanReq: 'IBAN Number is required',
  label_error_ibanWrong: 'IBAN Number is wrong',
  label_error_regDateRange:
    'Registration form valid till date cannot be less than valid from date.',
  label_error_regPastDate:
    'Registration form valid till date cannot be in the past',
  label_error_agrDateRange:
    'Agreement form valid till date cannot be less than valid from date.',
  label_error_agrPastDate:
    'Agreement form valid till date cannot be in the past',
  label_error_iban:
    'IBAN format is not correct. Correct format is XXnn [Country Code format] where XX is ISO country code',
  label_DWT_valid_from: 'valid from',
  label_DWT_valid_till: 'valid till',
  label_show_disabled_users: 'Show disabled users',
  label_show_deleted_users: 'Show deleted users',
  label_receive_group_emails: 'Receive emails related to tax reclaim events',
  label_DHR: 'Dhr.',
  label_MW: 'Mw.',
  label_acc_type: 'Account type',
  label_acc_type_dep: 'Department',
  label_acc_type_user: 'User',
  label_dep_name: 'Department name',
  label_error_depNameReq: 'Department name is required',
  label_number_of_intermediary: 'Number of intermediaries',
  label_add_shareholder: 'Add PE shareholder',
  label_edit_shareholder: 'Edit PE shareholder',
  label_view_shareholder_list: 'View PE shareholders',
  label_view_shareholder_details: 'View PE shareholder details',
  label_legal_entity: 'Legal entity',
  label_natural_person: 'Natural person',
  label_error_NameReq: 'Name is required.',
  label_error_NameWrong: 'Name is required.',
  label_contact_person: 'Contact person',
  label_street_adress: 'Street address',
  label_Intermediary: 'Intermediary',
  label_proof_of_entitlement: 'Proof of entitlement',
  label_notify: 'Notify',
  label_department: 'Department',
  imgfileSize_Error: 'Please upload a image file (Max Size 250KB)',
  isin_warning:
    'ISIN not in recommended format. Please ignore this message if these are not Ordinary shares',
  label_error_isinReq: 'ISIN is required',
  label_error_shareclassnameReq: 'Shareclass name is required',
  label_delete_confirmation: 'Are you sure you want to delete this record?',
  label_delete_meeting_doc_confirmation:
    'The selected meeting document will be deleted for ALL languages',
  label_confirm_delete: 'Confirm Delete',
  label_removeRegForm: 'Remove Registration Form',
  label_view_shareclass_list: 'View shareclass',
  label_add_shareclass: 'Add shareclass',
  label_edit_shareclass: 'Edit shareclass',
  label_default: 'Default',
  label_shareclass_error:
    'Shareclass is not present for this issuer group, You will not be able to create shareholder.',
  label_error_intermediaryListSH:
    'There should be at least one Intermediary associated with a PE shareholder.',
  label_error_emptyIntermediarySH:
    'Intermediary name cannot be blank, Please select any intermediary.',
  label_error_emptyNotListedIntermediarySH:
    'Not listed intermediary name cannot be blank.',
  label_error_emptyIntermediaryEmailSH:
    'Not listed intermediary email cannot be blank.',
  label_not_uploaded: 'File not uploaded',
  label_additional_comments: 'Additional comments',
  label_selectisin: '--Select ISIN--',
  label_add_user_now: 'Add users now',
  label_later: 'Later',
  label_tax_authority: 'Tax Authority',
  label_issuer_heading: 'All users except those of Intermediary Tax Reclaim',
  label_inter_heading: 'Intermediary Tax Reclaim',
  label_coc: 'Chamber of commerce',
  label_vat: 'VAT Number',
  label_edit_details: 'Edit Above Details',
  label_coc_req: 'Chamber of commerce is required',
  label_error_vatNoReq: 'VAT number is required',
  label_is_required: ' is required',
  label_is_wrong: ' is wrong',
  label_reinstate: 'Reinstate',
  label_deleted: 'Deleted',
  label_all_users: 'All Users',
  label_workstation_type: 'Workstation type/Role',
  label_result_number: 'Result Number',

  /* END : UM For DWT */
  /* DWT Detail page */
  label_intermediary_submitted_claim:
    'Intermediary Group that has submitted the claim',
  label_DWT_reclaim_payment_summary: 'DWT reclaim payment summary',
  label_dwt_type: 'Type',
  label_total_submitted_by_issuer: 'Total submitted by issuer',
  label_payment_requested: 'Payment requested',
  label_payment_received: 'Payment received',
  label_payment_completed_to_intermediary: 'Payment completed to intermediary',
  label_Total_claimed_amount: 'Total claimed amount',
  label_payment_details_overview: 'Payment details overview',
  label_payment_request_date: 'Payment request date',
  label_issuer_payment_reference: 'Issuer payment reference',
  label_total_amount: 'Total amount',
  label_invoice: 'Invoice',
  label_payment_status: 'Payment status',
  label_dwt_action: 'Action',
  label_intermediaryPaymentReference: 'Intermediary payment reference',
  label_intermediaryPayAmount: 'Intermediary pay amount',
  label_intermediaryIBAN: 'Intermediary IBAN',
  label_paymentStatus: 'Payment status',
  label_taxDeclarationNo: 'Tax declaration number (aangifte nummer)',
  label_eventStatus: 'Event status',
  label_peShareHolders: 'PE Shareholders',
  label_noOfIntermediaries: 'No. of Intermediaries',
  label_directDebitDetials: 'Direct Debit Details',
  label_ibanForQRF: 'IBAN for QRF',
  label_ibanForPE: 'IBAN for PE',
  label_eventComments: 'Event comments',
  label_addComment: 'Add Comment',
  label_calculatedPercentValue:
    '% calculated over the nominal value of a newly issued share',
  label_selectProxyName: '--Select proxy name--',
  createGenMeet_TimePickerTooltip:
    'With the cursor in hour or minute fields, you can use up/down arrow keys to increase/decrease the time.',
  label_callIntermediary: 'Call Intermediary',
  label_lei: 'LEI',
  label_error_leiWrong: 'LEI is wrong',
  uploadPositionStatementFromIntermediary:
    'Upload Position statement from Intermediary',
  intermediaryAcceptVoteCheckbox1:
    'We hereby confirm that we have verified the identity of the aforementioned Securities Holder, and represent and warrant his true identity.',
  intermediaryAcceptVoteCheckbox2:
    'We hereby confirm that the unique identification number is the same as the number provided to us by the aforementioned Securities Holder.',
  intermediaryAcceptVoteCheckboxVP2:
    'We hereby confirm that the mobile number and securities account number are the same as the number provided to us by the aforementioned Securities Holder.',
  intermediaryAcceptVoteCheckbox3:
    'We hereby confirm that the aforementioned Securities Holder holds the mentioned Securities of the Issuing Institution at the record date.',
  intermediaryAcceptVoteCheckbox4:
    'Furthermore, we hereby confirm that no prior voting instructions have been submitted by the aforementioned Securities Holder in connection with this General Meeting and that we nor the aforementioned Securities Holder will submit voting instructions other than those being submitted via the Website.',
  intermediaryAcceptVoteCheckbox5:
    'We acknowledge that we have read, understood and consented to the ',
  intermediaryAcceptVoteCheckbox6:
    ', as well as having saved, printed or sent ourselves a copy thereof.',
  label_viewMore: 'View More',
  label_claimsOverviewTable: 'Claims overview table',
  label_toBeAuthorised: 'To be authorized',
  label_submittedDate: 'Submitted date',
  label_paymentRequestedDate: 'Payment requested date',
  label_paymentReceivedDate: 'Payment received date',
  label_paymentCompletedDate: 'Payment completed date',
  label_submitted: 'Submitted',
  label_paymentRequested: 'Payment requested',
  label_paymentReceived: 'Payment received',
  label_paymentCompleted: 'Payment completed',
  label_claimStatus: 'Claim status',
  label_all: 'All',
  label_sentToIssuerDate: 'Sent to issuer date',
  label_sentToIssuer: 'Sent to issuer',
  label_dividendDetails: 'Dividend details',
  label_beneficialOwnerName: 'Beneficial owner name',
  label_peClaimsTable: 'PE (deelnemersvrijstelling) claims',
  label_qrfClaimsTable: 'QRF claims',
  label_position: 'Position',
  label_positionReg: 'Position is required.',
  label_positionMaxlengthError: 'Only 12 digits are allowed.',
  label_dwtClaimAmount: 'DWT claim amount',
  label_claimOptions: 'Claim options',
  label_6616OtherForm: '6166/Qualification/Other form',
  label_IB9296Form: 'IB92/96 form',
  label_Reports: 'Reports',
  label_createdDate: 'Created date',
  label_QRF: 'QRF',
  label_PE: 'PE',
  label_extension_type: 'Claim type',
  label_addressReq: 'Address is required',
  label_initialsReq: 'Initials are required',
  bulkUpload_processMsg:
    'File is being processed. Please wait while processing the request.',
  bulkUpload_successMsg: 'File processed successfully.',
  bulkUpload_errorMsg: 'There are some errors',
  label_sessionLoggedOut:
    'You have not used the portal for 20 minutes and have been logged out automatically. Please click outside this message window to dismiss it.',
  label_sessionWarning:
    'Session is about to expire in 5 minutes. Please respond to avoid logging out',
  label_warning: 'Warning',
  label_gmActionList: 'General Meetings – Action List',
  label_dwtActionList: 'Tax Reclaim – Action List',
  label_decline: 'Decline',
  areSureAccept_modal: 'Are you sure you want to accept this Meeting?',
  areSureDecline_modal:
    'Are you sure you want to decline the role of vote collector for this meeting?',
  label_acceptedVc: 'accepted',
  label_declinedVc: 'declined',
  label_youHave: 'You have ',
  label_roleOfVC: ' the role of vote collector for meeting ',
  label_ofIssuer: ' of Issuer ',
  label_youCanAttend: 'You can vote/attend from ',
  label_dealineDateError: 'You cannot vote for this event – deadline date was ',
  label_intermediaryConfirmation: 'Intermediary confirmation',
  label_rejectedDate: 'Rejected date',
  label_dwttooltip:
    'For your information, please find hereby the representations and warranties, which various intermediaries have given to us, before submitting their claims on behalf of their underlying clients to you. Click the button to download the document.',
  label_ortherFormtooltip1:
    'Form 6166/Other: This is a letter printed on U.S. Department of Treasury stationery certifying that the individuals or entities listed are residents of the United States for purposes of the income tax laws of the United States. This form can be used to claim income tax treaty benefits and certain other tax benefits in foreign countries (like the Netherlands). Via our portal, intermediaries are required to upload/refresh this basis on an annual basis.',
  label_ortherFormtooltip3:
    'Per 1 January 2024, a dividend withholding tax exemption came into force for proceeds received by non-taxable entities or comparable foreign entities (e.g. pension funds). The new withholding exemption is optional but can only be applied if a so-called qualification decision has been issued to the beneficiary to the dividends. This is a decision in which the Dutch Tax Inspector declares that the dividend beneficiary meets a set of requirements.',
  label_ortherFormtooltip2:
    'With "Other Forms" we mean other forms which can be used as tax residency certificate in order claim income tax treaty benefits. These forms need to be updated in accordance with the guidelines as provided by the Dutch tax authorities.',
  label_ibFormtooltip1:
    'Form IB 92: This is a form which enables an investor to reclaim the Dutch dividend holding tax being withheld on a certain dividend payment. Via our portal, intermediaries are required to upload such form per Dutch dividend withholding tax reclaim event.',
  label_ibFormtooltip2:
    'Form IB 96: This is a form which enables a US investor to reclaim the Dutch dividend holding tax being withheld on a certain dividend payment. Via our portal, intermediaries are required to upload such form per Dutch dividend withholding tax reclaim event.',
  label_Reason_text: 'Reason text',
  label_undoSubmit: 'Undo submit',
  label_undoReject: 'Undo reject',
  label_QRFReclaimStartDate: 'QRF reclaim start date',
  label_QRFReclaimEndDate: 'QRF reclaim end date',
  label_PEReclaimStartDate: 'PE reclaim start date',
  label_PEReclaimEndDate: 'PE reclaim end date',
  label_dateRepaymentTaxAuthority: 'Date of repayment tax authority',
  label_submitPEClaim: 'Submit PE claim',
  label_submitQRFClaim: 'Submit QRF claim',
  label_QRFBulkUpload: 'QRF bulk upload',
  label_dwtToBeAuthorized: 'To be autorized',
  label_authorizeAllClaims: 'Authorize all claims',
  label_authorizeSelectedClaims: 'Authorize selected claims',
  label_authorizeAllPEClaims: 'Authorize all PE claims',
  label_authorizeSelectedPEClaims: 'Authorize selected PE claims',
  label_authorizeAllQRFClaims: 'Authorize all QRF claims',
  label_authorizeSelectedQRFClaims: 'Authorize selected QRF claims',
  label_claimsNotselected: 'Please select the claims to authorize',
  label_securityClassDetails: 'Securities class details',
  label_commentConfirmationModal: 'Are you sure you want to add the comment ?',
  label_commentConfirmationHeader: 'Add comment confirmation',
  label_submitAllClaims: 'Submit all claims',
  label_submitSelectedClaims: 'Submit selected claims',
  label_requestQRFPayment: 'Request QRF payment',
  label_registerText: `Don't have an account yet? `,
  label_createAccountButton: 'Create an account',
  areSureAccept_modalVc:
    'Are you sure you want to accept the role of vote collector for this meeting?',
  label_requestNumber: 'Request#',
  label_groupAddSuccess: 'New group added successfully',
  label_groupEditSuccess: 'User group updated successfully',
  label_on: 'on',
  label_uploadPosStatement_fromInt:
    'Upload Position statement from Intermediary',
  label_logout: 'Log out',
  label_intPhoneNumber: 'Intermediary phone number',
  phoneNumber_error: 'Phone number is not valid',
  label_terms_ABNAMRO: 'ABN AMRO',
  label_san: 'Securities Account number',
  label_rsin: 'RSIN/BSN/FIN(of Shareholder)',
  label_tin: 'TIN',
  label_pn: 'Phone number',
  label_fund_manager: 'Fund Manager',
  label_claim_amount: 'Claim Amount',
  label_payment_reference: 'Payment reference',
  dwt_createPE_req: 'Business message to be added here.',
  label_pe_qrf_end_date: 'PE/QRF End date',
  label_QRFNodata:
    'There are no quick reclaim fund claims for this event with this status.',
  label_PENodata:
    'There are no participation exemption claims for this event with this status.',
  label_tobeAuthoriseDate: 'To be authorized Date',
  pdfFile_formatError: 'Please upload document in PDF file format',
  confirmFor_sendToIssuer:
    'Are you sure you want to send this meeting to issuer?',
  confirmUser_editDetails: 'Are you sure you want to edit user details?',
  confirmGroup_editDetails: 'Are you sure you want to edit this group details?',
  confirmApprove_Gm:
    'Please confirm that you have checked all information and the Registration ticket preview (on the Registrations tab) for this meeting.',
  confirmApprove_GmOtherLang:
    'Please confirm that you have checked all information and the Registration ticket preview (on the Registrations tab) for this meeting in all available languages.',
  label_Security_Name: 'Security name',
  label_Payment_date: 'Payment date',
  lable_undoSubmitModal: 'recall this submitted claim ?',
  lable_undoRejectModal: 'recall this rejected claim ?',
  lable_PEClaimDetailedView: 'PE claim details',
  lable_QRFClaimDetailedView: 'QRF claim details',
  label_editPEClaim: 'Edit PE claim',
  label_editQRFClaim: 'Edit QRF claim',
  label_tinRsin: 'TIN/RSIN',
  label_dwtShareholderDetails: 'Share holder Details',
  label_dwtSearchEventClaims: 'DWT Event Claims',
  label_dwtReclaimedAmount: 'DWT Reclaimed amount',
  label_claimsAmountOverviewTable: 'Claims Amount overview table',
  label_submitAllPEClaims: ' submit all PE claims',
  label_submitSelectedPEClaims: ' submit selected PE claims',
  label_submitAllQRFClaims: ' submit all QRF claims',
  label_submitSelectedQRFClaims: ' submit selected QRF claims',
  label_intermediary_details: 'Intermediary details',
  label_FIN_TIN: 'Fiscal Identification number/TIN',
  label_shareHolderIdentifiers: 'Shareholder Identifiers',
  label_shareHolderAddress: 'Shareholder address',
  label_shareHolderCI: 'Shareholder contact Information',
  label_calimDates: 'Claim dates',
  label_calimLastModifiedBy: 'Claim last modified by',
  label_noClaims: 'There are no claims for this event',
  label_submitThisClaim: ' submit this claim ?',
  label_rejectionComments: 'Rejection comments',
  areSureRejectClaim_modal: 'Are you sure you want to reject this claim ?',
  label_createPE: 'Create PE',
  label_bulkUploadQrf: 'Bulk',
  label_no6166Form: 'No form 6166/other uploaded for this securities holder.',
  label_noIB92_96Form: 'No form IB92/96 uploaded for this claim.',
  label_show6616OtherForm: 'Show 6166/Qualification/Other form',
  label_showIB9296Form: 'Show IB92/96 form',
  label_backToOverview: 'Back to claims overview',
  label_dwt_notification_link: 'DWT notification link',
  label_recipient: 'Recipient',
  label_modalDeleteBody: 'Are you sure you want to delete this claim ?',
  label_recievedDate: 'Received date',
  label_upload6616OtherForm: 'Upload 6166/Qualification/Other form',
  label_uploadIB9296Form: 'Upload IB92/96 form',
  label_claimId: 'Claim ID',
  label_claimType: 'Claim type',
  label_issuerName: 'Issuer name',
  label_recieved: 'Received',
  label_createPeClaim: 'Create PE claim',
  label_uploadQrfClaims: 'Upload QRF claims',
  label_createQrfClaim: 'Create QRF claim',
  label_downloadQRFBulkTemplate: 'DWT QRF Bulk upload template',
  label_bulkUploadQRF: 'Bulk upload QRF claim',
  label_modalDeleteOtherFormBody:
    'Are you sure you want to delete this 6166/Qualification/other form ?',
  label_modalDeleteIbFormBody:
    'Are you sure you want to delete this IB92/96 form ?',
  label_request_date_time: 'Request date/time',
  label_requested_new_end: 'Requested new end date/time',
  label_QRF_PE: 'QRF / PE',
  label_current_QRF_PE_End_date: 'Current QRF/PE End date',
  label_reason_for_extension_request: 'Reason for extension request',
  label_requestPaymentDate: 'Request Payment date',
  label_overviewTab: 'Overview',
  label_claimsTab: 'Claims',
  label_paymentsTab: 'Payments',
  label_authorizeTab: 'Authorize',
  label_eventStatusText: 'Pending for approval',
  label_commentsAddedBy: 'Comments added by',
  label_commentsAddedOn: 'Comments added on',
  label_requestPaymentModal:
    'Please note that claims yet to be submitted will be rejected if you proceed',
  lable_reqPaymentButton: 'Proceed with payment request',
  label_dwtModalRejectEvent: 'Are you sure you want to reject this event?',
  label_dwtModalApproveEvent: 'Are you sure you want to approve this event?',
  label_document_download: 'Document download',
  label_event_name: 'Event name',
  label_loginId: 'E-mail address',
  label_password: 'Password ',
  label_calimModifiedOn: 'Claim modified on',
  label_QRFSearchNodata: 'No QRF claim with beneficial owner name = ',
  label_PESearchNodata: 'No PE claim with beneficial owner name = ',
  label_downloadclaimoverview: 'Download Claims Overview',
  label_cc: 'cc',
  label_fileNameError:
    'File name including file extension should be less than 50 characters',
  label_rejectClaim: 'Reject claim',
  label_QRFPayment: 'QRF payment',
  label_amount: 'Amount',
  label_invoiceDate: 'Invoice Date',
  label_iban: 'IBAN',
  label_claimsDetails: 'DWT claim details',
  label_referenceNumber: 'Reference number',
  label_PEPayments: 'PE payments',
  label_eur: '(EUR)',
  label_createNewRequest: 'Create new request',
  label_cancelRequestBodyModal:
    'Are you sure you want to cancel submitted request ?',
  label_cancelRequestHeaderModal: 'Confirm cancellation request',
  label_viCancellationText1:
    'You are requesting for cancellation of approved voting instruction. Once you confirm, please make sure to contact your intermediary to process the cancellation request.',
  label_beneficialOwner: 'Beneficial owner',
  label_intermediaryDepartment: 'Intermediary Department',
  label_searchByIssuer: 'Search by issuer',
  label_DobError: 'Date of birth should not be future date',
  label_DobInvalid: 'Date of birth is mandatory (dd-MM-yyyy)',
  label_dobFutureError: 'Date of birth cannot be future date',
  label_dateFutureError: 'Date cannot be future date',
  label_dateInvalid: "Date should be in 'dd-MM-yyyy' format",
  label_registerButton: 'Register',
  label_noOfSharesError: 'Please enter number of shares.',
  label_viewIssuers: 'View issuers',
  label_viewIntermediaries: 'View intermediaries',
  label_viewProxies: 'View proxies',
  label_viewVCs: 'View vote collectors',
  label_viewAgentUser: 'View agent user',
  label_viewIntermediaryUser: 'View intermediary user',
  label_viewProxyUser: 'View proxy intermediary user',
  label_viewIssuerUser: 'View issuer user',
  label_viewVCUser: 'View vote collector user',
  label_addAgentUser: 'Add agent user',
  label_addIntermediaryUser: 'Add intermediary user',
  label_addProxyUser: 'Add proxy intermediary user',
  label_addIssuerUser: 'Add issuer user',
  label_addVCUser: 'Add vote collector user',
  label_confirmVIRequest_header: 'Confirm voting instruction request',
  label_confirmVI_registration_header: 'Confirm your registration',
  areYouSure_confrimVI_message:
    'Are you sure you want to confirm voting instruction request?',
  label_confirmAttendRequest_header: 'Confirm attendance request',
  areYouSure_confrimAttend_message:
    'Are you sure you want to confirm attendance request?',
  callIntermediary_SorryModalbody1:
    'Sorry, your intermediary has not provided its contact details yet. Please contact ABN AMRO Corporate Broking via ',
  callIntermediary_SorryModalbody2:
    ' or corporate.broking@nl.abnamro.com so we can help you to get in contact with the correct department at your intermediary.',
  label_callIntermediary_VI_registration:
    'Thank you for your voting instruction. You are now required to register yourself via your intermediary.',
  label_callIntermediary_AR_registration:
    'Now that you have requested to attend the meeting, you are required to register yourself via your intermediary.',
  label_ask_provideIdentification_VI:
    'You are therefore requested to contact your intermediary and to confirm the following identification number:',
  label_sh_vi_modalBody2:
    'It could occur that your intermediary is not familiar with this number. In such case your intermediary can contact ABN AMRO (AVA@nl.abnamro.com).',
  label_sh_vi_modalBody3:
    'If you have any further questions, please do not hesitate to contact ABN AMRO (corporate.broking@nl.abnamro.com).',
  label_toRegister_callIntermediary:
    'To register your votes please call your intermediary.',
  label_ask_provideIdentification:
    'You will be asked to present your Identification number:    ',
  label_contactInfo: 'Contact details',
  label_telephoneNumber: 'Telephone number',
  label_deptPhoneNumber: 'Department phone Number',
  lable_onlyNumbers_error: 'Please enter number of shares',
  label_createNewAR: 'Create new attendance request',
  label_createNewVI: 'Create new voting instruction',
  label_editVotingIns: 'Edit voting instruction',
  label_vebDefault: 'VEB[Default]',
  label_SelectSecurityClass: '--Select security class--',
  label_voteAttendEnd: 'You can no longer vote or register for this meeting',
  label_voteAttendStart:
    'Voting and registration yet to start for this meeting',
  confirm_proxyLinking: 'Are you sure you want to link this intermediary?',
  label_modalAttReqActionErrMsg: 'Information',
  max4000_characters_error: 'Maximum 4000 characters are allowed.',
  label_allSectionNotFilled:
    'Please fill all the sections with valid input and proceed.',
  max190_characters_error: 'Maximum 190 characters are allowed.',
  label_terms_IN_line1:
    'We hereby confirm that we have verified the identity of the aforementioned shareholder and warrant his true identity.',
  label_terms_IN_line2:
    'We hereby confirm that the aforementioned shareholder is in possession of the mentioned securities of the Company at the record date. Should the Company not use a record date, then we hereby warrant that the aforementioned shareholder is in possession of the mentioned securities of the Company at the General Meeting date.',
  label_terms_IN_line3:
    "In addition, we hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned shareholder as registered for this General Meeting, with right of substitution, to represent, act and vote on the shareholder's behalf, in accordance with the shareholder's instructions,  at this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Company, the civil law notary or any other person who is entrusted with the registration and/or the proxy voting procedure for this General Meeting.",
  label_terms_IN_line4:
    'Furthermore, we hereby confirm that no prior voting instructions have been submitted or will be submitted by the aforementioned shareholder in connection with this General Meeting and that we nor the aforementioned shareholder will submit voting instructions other than those being submitted via this portal on the Website.',
  label_scroll_toAccept: 'Scroll down to read and accept the Terms of Use.',
  acceptTc_shRegistration:
    'I acknowledge that I have read, understood and consented to the Terms of Use as well as having saved, printed or sent myself a copy thereof.',
  label_shRegistration_infoMsg:
    'Please include your personal details as known by your intermediary. ',
  label_shRegistration_infoMsg2:
    'We’ll only use the personal data you provide to record your votes regarding the general meeting or to send you a registration certificate. For more information how we use your data, see our ',
  label_shRegistration_infoMsg3: 'Privacy Statement.',
  file_sizeMsg25MB: ' MB. Please upload a file less than or equal to 25 MB.',
  contact_heading1: 'ABN AMRO Bank N.V. Corporate Broking',
  label_shareholderLogin: 'Shareholder login',
  intermediary_notAllowedToVote:
    'You are not allowed to process voting instructions. Please contact ABN AMRO Bank N.V.',
  intermediary_noPOAAvailableMessage:
    "There are no valid PoA's in place, so at this moment you are not allowed to process voting instructions. Please contact ABN AMRO Bank N.V.",
  intermediary_modalcheck1:
    'We hereby confirm that we have verified the identity of the aforementioned Securities Holder(s), and represent and warrant his/their true identity.',
  intermediary_modalcheck2:
    'We hereby confirm that the aforementioned Securities Holder(s) hold(s) the mentioned Securities of the Issuing Institution at the record date.',
  intermediary_VI_modalcheck3:
    'We hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned Securities Holder(s) to, in name and  on behalf of the Securities Holder(s), appoint the aforementioned Vote Collector to exercise the voting rights attached to the Securities on behalf of the Securities Holder(s) in this General Meeting. We will provide the documentation proving our authority to act in the name and on behalf of the Securities Holder(s) to ABN AMRO, the Issuing Institution, the civil law notary or any other person who is entrusted with the registration and/or the proxy voting procedure for this General Meeting.',
  intermediary_modalcheck4:
    'We hereby confirm that no prior voting instructions have been submitted or will be submitted by the aforementioned Securities Holder(s) in connection with this General Meeting and that we nor the aforementioned Securities Holder(s) will submit voting instructions other than those being submitted via the Website.',
  intermediary_modalcheck5:
    'We hereby confirm that we have uploaded the individual voting instruction(s) for which we have submitted a total voting instruction(s) (block vote)',
  intermediary_modalcheck6:
    'We hereby confirm that we will provide the registration certificate(s) to the Securities Holder(s) and instruct the Securities Holder(s) to bring the registration certificate(s) to the General Meeting. In case of the appointment of a Proxy Holder, we hereby confirm that we will provide the Proxy(s) to the Securities Holder(s) and instruct the Securities Holder(s) to print, manually sign and provide this Proxy to the Proxy Holder, who must bring this manually signed document to the General Meeting.',
  intermediary_AR_modalcheck3:
    'We hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned Securities Holder(s)  to, in name and on behalf of the Securities Holder(s): (i) register the Securities Holder(s) for participation for this General Meeting, and (ii) in case of the appointment of a Proxy Holder, appoint the aforementioned Proxy Holder(s) to exercise the voting rights attached to the Securities on behalf of the Securities Holder(s) in this General Meeting. We will provide the documentation proving our authority to act in the name and on behalf of the Securities Holder(s) to ABN AMRO, the Issuing Institution, the civil law notary or any other person who is entrusted with the registration and/or proxy voting procedure for this General Meeting.',
  intermediary_AR_reject_modalcheck4:
    'We hereby confirm that we will instruct the Securities Holder(s), where applicable, to collect the manually signed document from the Proxy Holder(s).',
  intermediary_BulkUpload_int_checkbox5: 'We hereby appoint',
  intermediary_BulkUpload_int_checkbox5_2:
    ' as Proxy Holder (with a right of substitution) to attend the General Meeting of ',
  intermediary_BulkUpload_int_checkbox5_3: '(the "Company") on',
  intermediary_BulkUpload_int_checkbox5_4:
    ' in name of the Securities Holder(s) listed in this bulk upload, to participate in the deliberations and to exercise the voting rights attached to the Securities listed in this bulk upload in name of the Securities Holder(s) in accordance with the instructions as specified in the bulk upload, and to sign all documents and/or perform any other actions to confirm the casted votes.',
  intDeadline_shdedline_Tooltip:
    'Please note that for the Belgium market this date is always the six calendar day before the date of the meeting.',
  votecollector_list_tooltip:
    'Please click on group name(s) to enter the vote collector display names.',
  label_proxy: 'Proxy',
  label_participation_by_proxy : 'Participation by Proxy',
  label_vote_by_correspondence: 'Voting by correspondence',
  label_participation_method: 'Participation Method',
  label_select_participation_method: '--Select Participation method--',
  vi_intermediary_individual_disclaimer1: 'We hereby appoint ',
  vi_intermediary_disclaimer1: 'I/we hereby appoint ',
  vi_intermediary_disclaimer2: ' of ',
  vi_intermediary_disclaimer3:
    ' as Proxy Holder (with a right of substitution) to attend the General Meeting of ',
  vi_intermediary_disclaimer4: ' (the "Company") on ',
  vi_intermediary_disclaimer5: ' at ',
  vi_intermediary_disclaimer6: ' at ',
  vi_intermediary_disclaimer7:
    ' in name of the aforementioned Securities Holder, to participate in the deliberations and to exercise the voting rights attached to the aforementioned Securities in name of the Securities Holder in accordance with the instructions below, and to sign all documents and/or perform any other actions to confirm the casted votes.',
  vi_intermediary_disclaimer_telenet0:
    'In case of absence of voting instructions given to the proxy holder with regard to the respective agenda items or if, for whatever reason, there is a lack of clarity with regard to the voting instructions or if there should be a vote on decisions to be taken by the general meeting during the meeting, subject to compliance with the company code, the proxy holder will always vote in favour of the proposed resolution, possibly amended.',
  vi_intermediary_disclaimer8:
    "We hereby give the Vote Collector the following instructions to vote in name of the aforementioned Securities Holder at the Company's General Meeting:",
  vi_vote_by_correspondence_disclaimer: 'Your vote will directly be assigned to the issuer of the meeting and your vote will immediately be disclosed to the issuer after the entitlement confirmation of your intermediary.',
  vi_shareholder_disclaimer1: 'I/we hereby appoint ',
  vi_shareholder_disclaimer2: ' of ',
  vi_shareholder_disclaimer3:
    ' as Proxy Holder (with a right of substitution) to attend the General Meeting of ',
  vi_shareholder_disclaimer4: ' (the "Company") on ',
  vi_shareholder_disclaimer5: ' at ',
  vi_shareholder_disclaimer6: ' at ',
  vi_shareholder_disclaimer7:
    ', to participate in the deliberations and to exercise the voting rights attached to the aforementioned Securities in my/our name in accordance with the instructions mentioned above, and to sign all documents and/or perform any other actions to confirm the casted votes.',
  vi_shareholder_disclaimer8:
    "I/we hereby give the Vote Collector the following instructions mentioned above to vote in my/our name at the Company's General Meeting.",
  vi_shareholder_disclaimer8_with_right_of_substitution:
    "I/we hereby give the Vote Collector (with a right of substitution) the following instructions mentioned above to vote in my/our name at the Company's General Meeting.",
  label_tou_registration: 'Registration',
  label_tou_registration_definitions:
    'the registration for participation for a specific General Meeting via the Registration Section;',
  label_tou_registration1: 'Registration Section',
  label_tou_registration_definitions1:
    'the section of the Website which enables the User to (i) register a Securities Holder for participation for a specific General Meeting, and (ii) appoint any Proxy Holder and issue voting instructions for a specific General Meeting;',
  'label_terms_regSection4.1':
    'The Registration Section can be used to register for participation for a specific General Meeting.',
  'label_terms_regSection4.2':
    'In order to complete the Registration, the User will have to ("Signing Procedure"):',
  'label_terms_regSection4.3':
    'The User must truthfully enter the requested data in the specific fields within the Registration Section of the Website. The data submitted by the User in the Registration Section will, upon receipt of an alert email from ABN AMRO, be verified by the Intermediary indicated by the User in the Registration Section. The Intermediary will inform ABN AMRO about any incorrect submitted data. The Intermediary will subsequently inform the User about any incorrect submitted data and the User may adjust the incorrect data until the end of the term during which the User can use the Registration Section for a specific General Meeting. The Intermediary will verify the adjusted data and, if all data are correct, ABN AMRO will notify the User thereof. If the User fails to adjust any incorrect data before the end of the term during which the User can use the Registration Section for a specific General Meeting, the Registration for participation cannot be completed and will be deemed to not have taken place.',
  'label_terms_regSection4.21':
    'provide the Unique Identification Number that appears in the Registration Section to the Intermediary who will verify this number and the identity of the User;',
  'label_terms_regSection4.22':
    'read and reconfirm his agreement with these Terms of Use; and',
  'label_terms_regSection4.23': 'confirm his registration.',
  'label_terms_regSection4.24':
    'By completing the Signing Procedure set out above, the User acknowledges and agrees that the Registration has been validly signed by it (also in case a third party, with or without permission signed the Registration) by means of an electronic signature. The User acknowledges and agrees that the electronic signature used within the Registration Section, more particularly the Signing Procedure, shall have the equivalent legal effect of a handwritten signature.',
  'label_terms_proxySection4.4new':
    'The Proxy Holder shall enjoy the  same rights in the General Meeting as those to which the Securities Holder thus represented would be entitled, more particularly the rights to speak, ask questions and vote.',
  'label_terms_regSection4.4.1new':
    'provide the Unique Identification Number that appears in the Proxy Section to the Intermediary who will verify this number and the identity of the User;',
  'label_terms_regSection4.4.2new':
    'read and reconfirm his agreement with these Terms of Use; and',
  'label_terms_regSection4.4.3new': 'confirm his registration.',
  'label_terms_proxySection4.6':
    'The Proxy does not constitute a proxy solicitation.',
  'label_terms_proxySection4.7':
    'The Proxy submitted for a specific General Meeting will be valid for the consecutive General Meetings with the same agenda.',
  'label_terms_proxySection4.8':
    'Proxies submitted before notification of a supplemented agenda, as the case may be, remain valid for the items included on the agenda for which they were issued. The Proxy Holder will not be authorized to vote on any new items on the agenda without instructions.',
  'label_terms_generalProvisions11.5.2new':
    'A Consumer domiciled in Belgium may bring proceedings in relation these Terms of Use and/or the use of the Website before the competent court in the Netherlands, or before the competent court in Belgium. In the latter case, the applicable laws shall be the laws of Belgium.',
  'label_terms_generalProvisions11.5.3new':
    'Proceedings in relation to these Terms of Use and/or the use of the Website may be brought against a Consumer domiciled in Belgium only before the court in Belgium. In this case, the applicable laws shall be the laws of Belgium.',
  'label_terms_definitions1.5header': 'General Meeting',
  'label_terms_definitions1.6header': 'Intermediary',
  locale_tou_and: 'and',
  label_tou_securitiesHolder: 'Securities Holder',
  label_tou_termsUse: 'Terms of Use',
  label_tou_uniqueIdentificationNumber: 'Unique Identification Number',
  label_tou_user: 'User',
  label_tou_header4: 'Registration',
  'label_tou_header4.1': 'REGISTRATION BY THE SECURITIES HOLDER',
  'label_terms_regSHSection4.1.1':
    'Via the Registration Section, a Securities Holder can register for participation for a specific General Meeting.',
  'label_terms_regSHSection4.1.2':
    'In order to complete (or revoke) the Registration, the Securities Holder will have to ("SH Signing Procedure"):',
  'label_terms_regSHSection4.1.21':
    'provide the Unique Identification Number that appears in the Registration Section to the Intermediary who will verify this number, the identity and the capacity of the Securities Holder;',
  'label_terms_regSHSection4.1.22':
    'read and reconfirm his agreement with these Terms of Use; and',
  'label_terms_regSHSection4.1.23': 'confirm his registration.',
  'label_terms_regSHSection4.1.3':
    'By completing the SH Signing Procedure set out above, the Securities Holder acknowledges and agrees that the Registration (or the revocation thereof) has been validly signed by it (also in case a third party, with or without permission signed the Registration (or the revocation thereof)) by means of an electronic signature. The Securities Holder acknowledges and agrees that the electronic signature used within the Registration Section, more particularly the SH Signing Procedure, shall have the equivalent legal effect of a handwritten signature.',
  'label_terms_regSHSection4.1.4':
    'Upon completion of the Registration, the Securities Holder receives a Registration certificate that he/she must bring to the General Meeting.',
  'label_terms_regSHSection4.1.5':
    'The Securities Holder must truthfully enter the requested data in the specific fields within the Registration Section of the Website. The data submitted by the Securities Holder in the Registration Section will, upon receipt of an alert email from ABN AMRO, be verified by the Intermediary indicated by the Securities Holder in the Registration Section. The Intermediary will inform ABN AMRO about any incorrect submitted data. The Intermediary will subsequently inform the Securities Holder about any incorrect submitted data and the Securities Holder may adjust the incorrect data until the end of the term during which the Securities Holder can use the Registration Section for a specific General Meeting. The Intermediary will verify the adjusted data and, if all data are correct, ABN AMRO will notify the Securities Holder thereof. If the Securities Holder fails to adjust any incorrect data before the end of the term during which the Securities Holder can use the Registration Section for a specific General Meeting, the Registration for participation cannot be completed and will be deemed to not have taken place.',
  'label_tou_header4.2': 'REGISTRATION BY THE INTERMEDIARY',
  'label_terms_regSHSection4.2.1':
    'Via the Registration Section, an Intermediary, acting in name and on behalf of a Securities Holder, can register a Securities Holder for a specific General Meeting.',
  'label_terms_regSHSection4.2.2':
    'In order to complete (or revoke) the Registration, the Intermediary will have to ("IM Signing Procedure"):',
  'label_terms_regSHSection4.2.21':
    'verify and confirm the identity and the capacity of the Securities Holder;',
  'label_terms_regSHSection4.2.22':
    'read and reconfirm his agreement with these Terms of Use; and',
  'label_terms_regSHSection4.2.23': 'confirm the registration.',
  'label_terms_regSHSection4.2.3':
    'By completing the IM Signing Procedure set out above, the Intermediary acknowledges and agrees that the Registration (or the revocation thereof) has been validly signed by it (also in case a third party, with or without permission signed the Registration (or the revocation thereof)) by means of an electronic signature. The Intermediary acknowledges and agrees that the electronic signature used within the Registration Section, more particularly the IM Signing Procedure, shall have the equivalent legal effect of a handwritten signature.',
  'label_terms_regSHSection4.2.4':
    'Upon completion of the Registration, the Intermediary receives a Registration certificate for the Securities Holder(s) that he has registered. The Intermediary must provide this Registration certificate(s) to the Securities Holder(s), who must bring it to the General Meeting.',
  'label_terms_regSHSection4.2.5':
    'The Intermediary must truthfully enter the requested data in the specific field within the Registration Section of the Website.',
  'label_terms_regSHSection4.2.6':
    'The Intermediary who uses the Registration Section, acting in name and on behalf of a Securities Holder, shall (i) only do so on the basis and within the scope of a legally valid power of attorney, (ii) comply with any requirements under or relating to such power of attorney, and (iii) provide the documentation proving the authority to act in the name and on behalf of the Securities Holder to ABN AMRO, the Issuing Institution, the civil law notary or any other person who is entrusted with the registration and/or proxy voting procedure for a specific General Meeting at first request.',
  label_downloadPDF_voting: 'Voting instruction per registration PDF',
  label_downloadExcel_voting: 'Voting instruction per registration Excel',
  label_error_search_criteria: 'Please enter/select atleast one filter',
  label_search_share_holder_invalid_6166:
    'Show securities holder without valid 6166',
  label_accountLocked: 'User account is Locked, Please reset your password.',
  label_technicalError: 'Technical Error, Please try again later',
  label_sessionExpired: 'User Session has expired, Please Login to continue',
  label_positionWrong:
    'Position should not be decimal number, less than or equal to zero and more than 12 digits',
  label_myAccount1:
    'If you want to add, update or remove user accounts, please click',
  label_here: ' here ',
  label_myAccount2:
    'to download the registration form and fill in the users that still need access with their contact details. Be aware that users that are on longer the registration form will be disabled. Please send the signed form to as.exchange.agency@nl.abnamro.com. The user accounts will be updated within 3 working days.',
  label_emailWrongDwt:
    'Email is either not matching the format or has international characters like Äë.',
  label_error_shname: 'Enter atleast 2 characters',
  label_endDate: 'End date',
  label_to_be_authorised: 'To be authorised',
  label_selectVC: '--Select vote collector--',
  label_confirmVotes_prevGm: 'Confirmed votes of previous general meeting',
  label_no_ar_available: 'No attendance requests available',
  label_no_vi_available: 'No voting instructions available',
  label_approved_RC: 'Approved, cancellation requested',
  label_cancellation_requested: 'Cancellation requested',
  extnbtn3: 'Cancelled',
  label_noAR: 'No attendance requests available in this status',
  label_noVI: 'No voting instructions available in this status',
  label_no_confVI_available: 'No confirmed votes available',
  label_no_sharePlan_holders_available: 'No Share plan holders available',
  label_votingExists_error:
    "Voting instruction already submitted for this security class and intermediary. Please check 'My Votes / Registrations'",
  label_cancelReg_info:
    'The following registration(s) will be cancelled when the voting instruction is approved by your Intermediary',
  label_cancelVoting_info:
    'The following voting instruction will be cancelled when attendance request is approved by your Intermediary',
  label_noVCavailable: 'No vote collector available',
  label_noIntermediaryAvailable: 'No intermediary available',
  label_notVotable: 'Not votable',
  label_notVotableAgenda:
    'This general meeting does not have any votable agenda item',
  modal_header_selectLang: 'Please select language to download',
  modal_selectLang: 'Please select language',
  label_accessDate: 'Access date',
  label_file_format_xlsx: 'Please upload only .xlsx file format',
  label_error_ext_rejected:
    'Extension request is rejected, please contact the administrator.',
  label_updatePwd: 'Update password',
  modal_areYousure_changePwd: 'Are you sure you want to change the password?',
  label_update_accountDetails: 'Update account details',
  modal_areYouSure_updateDetails:
    'Are you sure you want to edit profile details?',
  label_noGMMessage: 'There are no actions for General Meetings at this moment',
  label_cancelReg_info_IN:
    'The following registrations will be automatically cancelled when the voting instruction is submitted',
  label_cancelVoting_info_IN:
    'The following registration(s) will be auto cancelled when attendance request is submitted',
  label_live_events_overview: 'Live events overview',
  label_event_overview_header:
    'Dutch dividend withholding tax(DWT) events overview',
  label_unableToDownload: 'Unable to download !',
  label_helpText1: 'Following documents are included in the zip file: ',
  label_helpText2:
    'For each shareholders that has filed a claim the 6166 or any other document which can be used as a tax residency certificate (uploaded by the intermediary)',
  label_helpText3:
    'If applicable, all IB92 or IB96 documents per claim (uploaded by the intermediary)',
  label_helpText4: 'Overview of all tax claims for the event',
  label_helpText5:
    'The xls-file which was submitted to the Dutch tax authority for QRF claims',
  label_helpText6: 'All invoices for the event',
  label_helpText7:
    'The representations and warranties by the intermediaries that have submitted the claims on behalf of the beneficial owners',
  mail_sentToIssuer_text1: 'Mail has been sent already on ',
  mail_sentToIssuer_text2: '. Please check before sending again.',
  label_no_active_user_for_issuer:
    'There are no enabled users for this Issuer. Do you want to put event to pending anyway?',
  label_proxyTitle: 'Proxy title',
  label_proxyCountry: 'Proxy country',
  label_proxyCity: 'Proxy city',
  label_noDataGMIssuerMessage: 'No General Meeting available for issuer',
  label_accessDenied: 'Access denied',
  label_globalError_1:
    'Sorry, an error occurred for the requested operation. Other operations might work, but cannot be guaranteed.',
  label_globalError_2:
    'For direct communication with technical support e-mail to corporate.broking@nl.abnamro.com with details about the action you performed and we will contact you.',
  label_error: 'Error!',
  label_noDwtComment: 'There are no comments available for this event',
  label_uploadBOFile: 'Upload BO file',
  label_uploadBOFileError:
    'Uploading a BO file in ".xlsx" is mandatory if the user type is NOMI or TRUS',
  label_checkShareholder: 'Check for shareholder',
  label_edit_IN_AR_BOFile: 'Edit attendance request',
  label_utc: 'UTC',
  label_noDataMessageExt: 'There are no extension requests available',
  label_noSHGMFound: 'There are no upcoming general meetings available',
  label_noAuditMessage: 'There are no audits available',
  label_noDwtMessage: 'There are no actions for Tax Reclaim at this moment',
  label_total_voting_instrunction: 'Total voting instruction(s)',
  label_voting_inst_per_reg: 'Voting instruction per registration',
  label_pdf: 'Pdf',
  label_excel: 'Excel',
  label_noMeetingMessage: 'There are no General Meetings available',
  label_cancelReq: 'Cancel request',
  label_reqCancellation: 'Request cancellation',
  label_dnldRegCerti: 'Download registration certificate',
  label_addIntSucessMsg: 'Intermediary request created successfully',
  label_pwdSuccessMsg: 'Password updated successfully',
  lable_accountUpdateSuccessMsg: 'User account updated successfully',
  label_noShareclassAvailable: 'No shareclass available',
  label_selectRegCertLang: 'Select registration certificate language',
  label_sendRegTicketToShareholder:
    'Send registration tickets to shareholders based on provided mail address after upload in ',
  label_contact_details_footer: 'Contact details',
  label_extn_currentDate:
    'Extend deadline till date must be greater than  current date and Time',
  label_voting_disabled: 'Voting is disabled for this meeting.',
  label_attendance_disabled: 'Attendance is disabled for this meeting.',
  checkPattern_Pwd_length: 'Password needs to consist of at least 8 characters',
  label_voteNotPossible: 'It is not possible to vote for this meeting',
  label_attendNotPossible: 'It is not possible to register for this meeting',
  label_attendNotEnabled1:
    'It is unfortunately (no longer) possible for shareholders to register for attendance of the meeting in person. You can contact',
  label_attendNotEnabled2:
    'Proxy/ voting instructions can be granted via the button ‘give voting instructions’. When virtual attendance has been made available, shareholders can register for virtual attendance via the button ‘attend meeting virtually',
  label_attendDisabled1_intermediary:
    'It is unfortunately not possible for shareholders to attend the meeting in person. Therefor create new registration and also bulk upload is not allowed. Your clients (shareholders) should contact the company ',
  label_attendDisabled2_intermediary:
    ' for approval to attend the meeting in person.',
  label_voteAttendNotAllowed:
    'You can no longer vote or register for this meeting',
  label_voteAttendNotAllowedAccessDate:
    'Vote/Attend will be enabled after shareholder access date',
  label_shareholderExists:
    "This mail address is already registered, please login or use Forget password if you don't know your password anymore.",
  label_oldPassIncorrect: 'Old password is incorrect',
  label_existLastPassList: 'This password exists in last 12 password list',
  label_passLengthIncorrect: 'Password length is incorrect',
  label_dataNotFound: 'Data not found for the given input',
  label_passNotMatch: 'Password does not match',
  label_noVCavailable_forVote:
    'This general meeting and shareclass does not have any vote collector',
  label_newVote: 'New instruction',
  label_newRegistration: 'New registration',
  label_createRTS: 'Create new RTS request',
  label_editRTS: 'Edit RTS request',
  label_rank: 'Rank',
  label_source: 'Source',
  label_sourceDate: 'Source event date',
  label_viewByCountry: 'View by country',
  label_viewBySource: 'View by source',
  label_viewDetailedReport: 'View detailed report',
  label_continue: 'Continue',
  label_conf_votes: 'Confirmed votes',
  lable_AR_proxy_error:
    'Attendance request (to attend yourself) is already available for this meeting',
  lable_AR_shares_error:
    'You cannot vote or register for this meeting as with the entered number of shares, you are exceeding the total number of shares outstanding for this Share class.',
  label_edit_extn_request: 'Edit Extension Request',
  label_authorizeAllQRFModal:
    'Are you sure you want to authorize all QRF claims?',
  label_authorizeAllPEModal:
    'Are you sure you want to authorize all PE claims?',
  label_authorizeSelectedQRFModal:
    'Are you sure you want to authorize selected QRF claims?',
  label_authorizeSelectedPEModal:
    'Are you sure you want to authorize selected PE claims?',
  label_shGMNotFound: 'Currently there are no general meetings available ',
  label_forYourSearchTerm: 'that match your search term',
  label_defaulProxyForbulkUpload:
    "Add 'Iedere vertegenwoordiger van de VEB' as proxy for all attendance requests in this upload file",
  label_request_date: 'Request date',
  label_dateandtime: 'Date and time',
  label_exclamation: '!',
  label_noDWTavailable: 'There are no DWT events available',
  label_noDataGMIssuerMessage_DW: 'No DWT events available for issuer',
  label_error_nameInvalid:
    'Name is invalid. Minimum 2 valid characters are expected',
  label_portalInfoExample:
    'This portal is still under construction and the information shown is an example',
  label_thereAreNo: ' There are no',
  label_eventsAtMoment: 'tax reclaim events at this moment',
  label_draft: 'draft',
  label_pending: 'pending',
  label_toBePublished: 'to be published',
  label_live: 'live',
  label_completed: 'completed',
  label_cancelled: 'cancelled',
  label_documentLessThan25mb: 'Document should be less than 25mb',
  label_BOFile: 'BO file',
  label_iWouldAttend: 'I would like to attend the meeting',
  label_iWouldVote: 'I would like to vote for the meeting',
  label_checkSCMsg:
    'Add check to prevent register or vote to wrong share class',
  label_sendEmailEuro: 'Send email to Euroclear',
  label_downloadEuroclearReport: 'Download Euroclear report',
  label_agentWS: 'Agent workstation',
  label_sendVIreg_toEuroclear:
    'Option to send registrations and voting instructions to Euroclear',
  label_proceed: 'Proceed',
  label_dear: 'Dear ',
  label_noShareholderDetails: 'No shareholder details found',
  label_euroclear_mesage: 'Mail has been sent already on',
  label_plsCheck_mesage: 'Please check before sending again.',
  label_reSendEmailEuro:
    'Mail has been already sent. Do you want to send it again?',
  label_subject_required: 'Subject is required.',
  label_download_intermediary_instructions: 'Download accepted instructions',
  label_emailboday_required: 'Email body is required.',
  sendVIreg_toEuroclear_Tooltip: 'This option is mandatory for Shell',
  label_nwkError_1: 'Please validate your internet connectivity and check:',
  label_nwkError_2: '-- Checking the network cables, modem, and router',
  label_nwkError_3: '-- Reconnecting to Wi-Fi',
  label_nwkError_4:
    '-- Refresh the browser (press F5) once the connectivity issue is resolved',
  label_network_error_lost: 'Internet connection lost',
  label_network_error_1:
    'The page could not be loaded as there is or was an issue with your internet connection.',
  label_network_error_2:
    'Please check your connection and refresh the browser (press F5) once the connection issue is resolved.',
  label_aegon_issuer: 'The AGM will be webcast on Aegon’s website',
  label_instruction_not_available:
    'There are no accepted instructions to download',
  vi_intermediary_disclaimer_telenet1:
    'I acknowledge that the president of the board of directors of the Company, currently IDw Consult BV, with as permanent representative Mr. Bert De Graeve, is a director of Telenet Group Holding NV. In his capacity of director, he has a potential conflict of interest as set out in article 7:143, §4 of the Belgian Code of Companies and Associations. In case of a potential conflict of interest he will only vote in execution of this proxy in accordance with the specific voting instructions set out in this proxy. In absence of a specific voting instruction, he will be supposed to have received the specific voting instruction to vote in favor. \n',
  vi_intermediary_disclaimer_telenet2: '',
  lable_numberOfShares_error: 'Invalid number of shares',
  lable_5posOnly: 'Initials can be maximum 5 positions long',
  label_votingAdvise: 'Voting advise',
  label_shareholder_link: 'https://www.abnamro.com/shareholder',
  label_intermediary_link: 'www.abnamro.com/intermediary',
  label_proceedToLogout: 'Proceed to ',
  label_asACustomerOf: 'As a customer of ',
  label_voteViaIntermediary:
    ' you can only register for the meeting via the website of ',
  label_alex_binkBank_warning:
    ' you can only vote for the meeting via the website of',
  label_reloadCancelError:
    'Please choose different meeting or a different shareclass to proceed.',
  label_teleNumber: 'Department phone Number',
  label_addresseeEmail: 'Email address',
  lable_contactDetails: 'Contact details',
  pwdChanged_successMsg_proceed:
    'Password changed successfully. Redirecting to login page...',
  pwdExpiry_msg:
    'Your password is expired, please change your password. Be aware that you cannot use the last 12 used passwords.',
  label_viewVotingInstruction: 'View voting instruction',
  label_noVIAvailable: 'No voting instruction available for ',
  label_login_failed: 'Login redirect failed ',
  label_of: 'of',
  label_pwd_requirement: 'Please check the password requirements',
  label_one_email:
    "Please ensure that only one valid email id is entered in the 'To' address field",
  lable_mobile_number: 'Mobile phone',
  label_mobile_number_reenter: 'Re-enter mobile phone number',
  lable_mobile_number_guidelines:
    'Please select the country code from the dropdown and enter the mobile phone number by following the below guidelines:',
  lable_mobile_number_numeric: '-  Mobile number must be numeric.',
  lable_mobile_number_length: '-  Maximum length must be 15.',
  label_mobile_number_numeric_error: 'Mobile number must be numeric.',
  label_phone_number_exists_error: 'Phone number already exists.',
  label_country_code_error: 'Please select the country code.',
  label_virtual_participation: 'Attend meeting virtually',
  label_virtual_participation_and_vote: 'Attend meeting and vote virtually',
  label_virtual_participation_no_voting:
    'Virtual Attendance, PROXY VOTING ONLY',
  lable_Reject_reason: 'Rejected reason',
  label_noVirtualParticipation:
    'There are no Virtual Participation Request available',
  label_footerTermOfUse: 'Footer Term of Use',
  label_attend_virtualy: 'Virtual participation',
  label_vote_sh: 'Vote',
  label_attend_sh: 'Attend',
  label_virtualPartNotAllowedAccessDate:
    'Virtual participation will be enabled after shareholder access date',
  label_register_virtualParticipation_step_counter_1: 'Shares and Account',
  label_register_virtualParticipation_step_counter_2:
    'Phone, Terms and Conditions',
  label_register_virtualParticipation_step_counter_3: ' Verification code',
  label_register_virtualParticipation: 'Register for virtual participation',
  label_register_virtualParticipation_verify_phone:
    'Verification of phone number',
  label_register_virtualParticipation_acknowledge: 'Acknowledge and Accept',
  label_register_virtualParticipation_enter_code: 'Enter code',
  label_register_virtualParticipation_success:
    'You successfully registered to join for virtual participation',
  label_register_virtualParticipation_success_message:
    'Now that you have requested to virtually participate during the meeting your intermediary will have to provide an approval.',
  label_register_virtualParticipation_please_confirm:
    'Please confirm your account number and telephone number to your intermediary',
  label_register_virtualParticipation_question: 'Question',
  label_register_virtualParticipation_question_message:
    'In case you have any further questions, please do not hesitate to contact ABN AMRO.',
  label_verfication_code_sent:
    'A new verification code was sent to your mobile phone.',
  label_verifying_code: 'Verifying OTP...',
  label_verfication_sending_new_code: 'Sending new code',
  label_otp_resend_message_part1: 'Send Again Button will be enabled in ',
  label_otp_resend_message_part2:
    ' seconds, Please Wait for One Time Pin(OTP).',
  label_send_again: 'Send Again',
  label_code: 'Code',
  label_back: 'Back',
  label_verify: 'Verify',
  label_contact_intermediary: 'Contact Intermediary',
  label_join_meeting: 'Join meeting',
  label_to_my_events: 'To my events',
  label_account: 'Account',
  label_terms_vp1:
    'I/We herby confirm that I/We have read, understood and consented the',
  label_terms_vp2:
    'as well as having saved, printed or sent myself/ourselves a copy thereof.',
  label_contact: 'Contact',
  label_helpdesk: 'Helpdesk',
  label_shares: 'Shares',
  label_SAN_error: 'Please enter valid security account number',
  label_SAN_exists_error:
    'Securities account number already exists for this meeting',
  lable_TermsAndConditions: 'Terms and Conditions',
  lable_phoneNumberVerification:
    'The phone number can not be changed once verified and must be used till meeting ends.',
  label_virtualParticipationRequests: 'Virtual participation requests',
  label_no_virtualParticipation: 'No virtual participation requests available',
  lable_mobileNumMismatch: 'Mismatch in the re-entered mobile phone number',
  label_phoneNumber_exists_meeting_error:
    'You have already registered for the meeting with different phone number, please correct or verify the registrations in my registrations section.',
  label_numeric_value_error: 'Please enter numeric value',
  label_meeting_details: 'Meeting details',
  label_join: 'Join',
  label_join_meeting_enable_hover_text:
    'Click to virtually participate during the meeting',
  label_join_meeting_disable_hover_text1: 'Meeting can be joined from',
  label_join_meeting_disable_hover_text2: 'till the mid night of',
  label_virtual_participation_leave_cbp_warning:
    'You are about to leave the ABN AMRO e-voting portal and will be navigated to the application/website for virtual participation. This application/website is not operated by ABN AMRO and therefore ABN AMRO is not responsible for its content, availability and functionality. If you have any questions or concerns about the virtual participation application/website, please contact +31 20 628 6070.',
  label_button_accept_and_proceed: 'Accept and Proceed',
  label_helpDesk_Number: '+31 20 628 6070',
  label_Important_Information: 'Important Information',
  label_cbp_email: 'corporate.broking@nl.abnamro.com',
  label_dual_factor_authentication_success:
    'You are successfully navigated to the application/website for virtual participation',
  label_authentication_generic_message:
    'Sorry, we are unable to proceed with your request. Please contact helpdesk.',
  label_2FA_OTP_fail:
    'There is a failure in code (OTP) generation/verification, please retry.',
  label_Invalid_OTP_msg:
    ' Invalid/Expired OTP, please use resend option to create new OTP',
  CBP_ERR_573: 'Securities account number already exists for this meeting',
  label_like_to_attend_virtually:
    'I would like to virtually participate during the meeting',
  label_like_to_attend_and_vote_virtually:
  'I would like to virtually participate and vote during the meeting',
  label_virtual_Meeting_Id_mandatory_msg:
    'For virtual participation, meeting id is mandatory',
  label_virtual_participation_provider_required_msg: 'A provider is required',
  label_download_registration_list: 'Download registration list',
  label_vp_registration_risk:
    'You are about to confirm that you want to virtually participate in the General Meeting.\n\nIn case you wish to proceed, please acknowledge and accept the risks which are inherently related to virtual attendance and participation in the General Meeting by checking the relevant checkbox set out below and click the “Accept and Proceed” button to gain access to the Virtual Participation Section.\n\nThe risks related to virtual attendance and participation in the General Meeting include, amongst others: any failure in virtual meeting equipment, software, network infrastructure, servers, internet or telephone connectivity, video or voice equipment whether or not caused by an information security incident (which includes any unauthorised use, hacking, a Denial of Services attack, a denial of access, any failure or intrusion resulting from the theft or unlawful interception of a password or network access code and any other cyber-attack) or caused by any type of mechanical failure such as any electrical power outage during the General Meeting as well as any other unforeseen technical difficulties or disruptions whether relating to the Website in general, the Virtual Participation Section, the General Meeting or the equipment/connectivity used by you, the Securities Holder. \n\nBy checking the boxes below, you confirm:\n',
  label_vp_registration_risk_item1:
    'that you acknowledge and accept the risks listed above and to have read and understood and agreed to our terms and conditions applicable to the registration and use of the Virtual Participation Section.',
  label_vp_registration_risk_item2:
    'that you are a person authorized to accept these risks on behalf of the Securities Holder.',
  label_vp_registration_risk_item3:
    'that you acknowledge to have read, understood, and agreed to the policy regarding the hybrid/virtual voting by shareholders declared applicable by the issuing institution which can be found here: ',
  label_vp_registration_risk_item3_no_link:
    'that you acknowledge that, if available, you have read, understood, and agreed to the policy regarding the hybrid/virtual meeting/participation and the privacy statement declared applicable by the issuing institution.',
  label_vp_registration_risk_document:
    'The Terms of Use for using the Website and the Virtual Meeting Section in particular apply and can be found here:',
  label_policy_issuing_institution: 'Policy Issuing Institution',
  label_virtual_Meeting_date_mandatory_msg: 'Enable join by date is required',
  label_Enable_join_date_error_msg:
    'Enable join by date should be greater than the shareholder access date',
  label_Enable_join_by: 'Enable join by',
  label_voting_options: 'Voting Options',
  label_record_date_required: 'Record date is required.',
  label_record_date_format:
    'Record date format should be like \n 01-April-2017\n.',
  label_record_date_before_GM:
    'Record date should be before general meeting date.',
  label_record_date_after_convocation:
    'Record date should be after convocation date.',
  label_publication_date_required: 'publication date is required.',
  label_publication_date_format:
    'publication date format should be like \n 01-April-2017\n.',
  label_publication_date_before_GM:
    'publication date should be before general meeting date.',
  label_publication_date_after_convocation:
    'publication date should be after convocation date.',
  label_gmProductType: 'GM',
  voting_instruction_allowed_tooltip: 'Instruction is not assigned to you',
  label_on_behalf_of: 'On Behalf of',
  virtual_participation_allowed_tooltip:
    'Virtual participation can only be disabled when no pending or approved virtual participation requests exist for the meeting',
  registered_share_help_text:
    "Please select the share type for you which you would like to participate. If you are a registered holder in the company's share register, please click on registered shares.",
  label_intermediaryHelpText:
    'The intermediary is the institution where you keep your securities account with',
  label_approved_registrations: 'Approved registrations',
  label_approved_securities: 'Approved securities',
  label_pending_registrations: 'Pending registrations',
  label_total_pending_registrations: 'Total pending registrations',
  label_overview_of_live_general_meeting: 'Overview of live general meeting',
  label_modal_header_send_preview: 'Send preview',
  label_modal_confirm_template_updated: 'Template updated',
  label_modal_confirm_preview_sent: 'Preview email is sent',
  label_modal_failed_sent_failed: 'Sending the preview failed',
  label_modal_preview_sending: 'Sending preview...',
  CBP_ERR_604: 'reCAPTCHA verification failed',
  CBP_ERR_227:
    "This mail address is already registered, please login or use Forget password if you don't know your password anymore.",
  CBP_ERR_572: 'Phone number already exists.',
  label_status_LIVE: 'Live',
  label_status_PENDING: 'Pending',
  label_enterNumberOfSecurities: 'Enter number of securities',
  vi_disclaimer_greenyard1:
    "In case you provide a proxy for the extraordinary general shareholders' meeting, the completed and signed proxy in original must either be sent by ordinary post to the Company at the following address:",
  vi_disclaimer_greenyard2:
    'Greenyard NV, attn. Ms. Fran Ooms, Strijbroek 10, 2860 Sint-Katelijne-Waver (Belgium), or be lodged at the extraordinary general shareholders’ meeting by the person who has been granted the proxy. If the Company has not received the original proxy before the start of the extraordinary general shareholders’ meeting then the proxy is not valid.',
  vi_disclaimer_intermediary_greenyard1:
    "In case you provide a proxy, on behalf of an end investor, for the extraordinary general shareholders' meeting, the completed and signed proxy in original must either be sent by ordinary post to the Company at the following address:",
  vi_disclaimer_intermediary_greenyard2:
    'Greenyard NV, attn. Ms. Fran Ooms, Strijbroek 10, 2860 Sint-Katelijne-Waver (Belgium), or be lodged at the extraordinary general shareholders’ meeting by the person who has been granted the proxy. If the Company has not received the original proxy before the start of the extraordinary general shareholders’ meeting then the proxy is not valid.',

  ie11_xlsx_download_warning:
    'It appears you are using IE11 as your browser. On IE11, downloading .xsls files might not work properly. If you encounter any issues, please reattempt your download in another browser (Chrome, Firefox, Safari, of Edge).',
  label_more_info: 'More info',

  label_voteConfirmationMessage: `Please enter the date on which you've submitted the votes to the general meeting, and press the 'Send' button to send out email notifications to relevant intermediaries and shareholders`,
  label_loginFormEmpty: 'E-mail address or password may not be blank',
  toolTip_doNotPublish:
    'When selected the shareclass will not be published to the market and to normal shareholders. (When all shareclasses of a meeting are selected, the entire meeting itself is invisible to the market and shareholders)',
  label_sh_different_custodian_message: `If you have voting rights with another depositary bank, please <a href="mailto:${GM_CONTACT_EMAIL}">contact us.</a>`,
  label_voteExecutionConfirmation: 'Vote Execution Confirmation',
  label_vecStarted:
    'Meeting Vote Execution Confirmation is in progress. You will be informed by e-mail when finished.',
  label_vecNoVotingInstructions: 'No voting instructions to confirm',
  label_updateInstruction: 'Update Instruction',
  label_updateInstructionConfirmation:
    'Are you sure you want to update this instruction?',
  label_cancelInstruction: 'Cancel Instruction',
  label_cancelInstructionConfirmation:
    'Are you sure you want to cancel this instruction, this action cannot be undone.',
  label_updateShareplanHolder: 'Update Shareplan Holder',
  label_updateShareplanHolderConfirmation:
    'Updating the number of shares will also update the number of shares for a potential registration for this Shareplan Holder.',
  label_updateOutstandingShares: 'Update Outstanding shares',
  label_updateOutstandingSharesConfirmation:
    'Are you sure you want to update the Total Outstanding Shares?',
  label_copyOutstandingSharesCsd: 'Copy Outstanding shares Csd',
  label_copyOutstandingSharesCsdConfirmation:
    'Are you sure you want to copy the Total Outstanding Shares via CSD to the Total Outstanding Shares?',
  label_shareholderGmCategory0: 'Meetings with voting options',
  label_shareholderGmCategory1: 'Meetings with the deadline date in the past',
  label_shareholderGmCategory2: 'Meetings with the access date in the future',
  label_right_to_speak: 'RTS'
};
